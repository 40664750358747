import React from "react";
import TimelineSection from "./TimelineSection";
import HighlightsSection from "./HighlightsSection";

const CenterStageSection = () => {
  return (
    <>
      <div className="w-2/3 ">
        <TimelineSection />
      </div>
      <div className="w-1/3">
        <HighlightsSection />
      </div>
    </>
  );
};

export default CenterStageSection;
