import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";

//Global state Access
import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

import useFetch from "../../../components/shared/useFetch";
import Loader from "../../../components/shared/Loader";

const MyAccountChild = () => {
  let navigate = useNavigate();
  const [{ errorLogApi, user }] = useAtom(gbState);
  const { data, loading, error } = useFetch(`user/${user?._id}`);
  const [postLoader, setPostLoader] = useState(false);
  const [formData, setFormData] = useState({
    first_name: data?.users?.first_name,
    last_name: data?.users?.last_name,
    mobile: data?.users?.mobile,
    email: data?.users?.email,
    password: null,
    confirm_password: null,
  });

  useEffect(() => {
    setFormData({ ...data?.users });
  }, [data]);

  const handleChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  const accountUpdate = () => {
    if (formData.password !== formData.confirm_password) {
      toast.error("Passwords do not match");
      return;
    }
    console.log("Update Button Clicked");

    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/user/update/${user?._id}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData },
    };
    setPostLoader(true);
    axios(config)
      .then(function (response) {
        toast.success("The User updated sucessfully!");
        navigate("/users");
      })
      .catch(function (err) {
        console.log(err);
        toast.error(`User cannot be updated ${err?.response?.data?.message}`);
      })
      .finally(() => {
        setPostLoader(false);
      });
  };

  return (
    <div className="flex justify-center w-full m-8">
      <Loader postLoader={postLoader} />
      <div class="p-4 w-[400px]" style={{ wordBreak: "break-word" }}>
        <div className="flex justify-end w-full mt-4">
          <button
            className="px-4 py-2 text-white bg-gray-600 rounded hover:bg-gray-900"
            onClick={() => navigate(-1)}
          >
            Back
          </button>
          &nbsp;&nbsp;
          <button
            className="px-4 py-2 text-white bg-blue-600 rounded hover:bg-blue-900"
            onClick={accountUpdate}
            disabled={postLoader ? true : false}
          >
            Update
          </button>
        </div>
        <h2 className="mb-3 text-bold">Update My Account</h2>
        <div className="mt-4 ">
          <input
            className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
            name="first_name"
            type="text"
            required
            placeholder="Enter First Name"
            autoComplete="new-password"
            onChange={handleChange}
            value={formData.first_name}
          />
        </div>
        <div className="mt-4 ">
          <input
            className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
            name="last_name"
            type="text"
            placeholder="Enter Last Name"
            autoComplete="new-password"
            onChange={handleChange}
            value={formData.last_name}
          />
        </div>
        <div className="mt-4 ">
          <input
            className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
            name="mobile"
            type="text"
            required
            placeholder="Enter Mobile"
            autoComplete="new-password"
            onChange={handleChange}
            value={formData.mobile}
          />
        </div>
        <div className="mt-4 ">
          <input
            className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
            name="email"
            type="email"
            required
            placeholder="Enter Email"
            autoComplete="new-password"
            onChange={handleChange}
            value={formData.email}
            disabled={true}
          />
        </div>
        <div className="mt-4 ">
          <input
            className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
            name="password"
            type="password"
            required
            placeholder="Enter Password"
            autoComplete="new-password"
            onChange={handleChange}
            value={formData.password}
          />
        </div>
        <div className="mt-4 ">
          <input
            className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
            name="confirm_password"
            type="password"
            required
            placeholder="Confirm Password"
            autoComplete="new-password"
            onChange={handleChange}
            value={formData.confirm_password}
          />
        </div>
      </div>
    </div>
  );
};

export default MyAccountChild;
