import React, { useState, useEffect } from "react";
import Modal from "react-modal";

Modal.setAppElement("#root");

const ImageTile = ({ images }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [largestImage, setLargestImage] = useState(null);

  const openModal = (image) => {
    setSelectedImage(image);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedImage(null);
  };

  const handleImageLoad = (event, src) => {
    const { naturalWidth, naturalHeight } = event.target;
    const resolution = naturalWidth * naturalHeight;

    setLargestImage((prevLargest) => {
      if (!prevLargest || resolution > prevLargest.resolution) {
        return { src, resolution, isLarge: naturalHeight > 400 };
      }
      return prevLargest;
    });
  };

  useEffect(() => {
    // Optionally reset the largest image when the images prop changes
    setLargestImage(null);
  }, [images]);

  if (!images || !Array.isArray(images) || images.length === 0) return null;

  const renderImage = (src, index) => {
    const isLarge = largestImage?.src === src && largestImage?.isLarge;
    return (
      <img
        key={src}
        src={`${process.env.REACT_APP_REPORTS_DOWNLOAD_URL}uploads/posts/${src}`}
        alt={`Image ${index + 1}`}
        onLoad={(event) => handleImageLoad(event, src)}
        style={{
          width: isLarge ? "auto" : "100%", // Set width to auto if the image is large to maintain aspect ratio
          maxHeight: "400px", // Ensure the maximum height is 400px
          objectFit: isLarge ? "contain" : "cover", // Use contain for large images to fit them entirely within the bounds
        }}
        crossOrigin="anonymous"
      />
    );
  };

  return (
    <>
      <div className="text-center">
        <button
          onClick={() => openModal(largestImage?.src || images[0])}
          className="inline-block"
        >
          {renderImage(largestImage?.src || images[0], 0)}
        </button>
      </div>
      {images.length > 1 && (
        <div className="grid grid-cols-4 gap-2 mt-2">
          {images
            .filter((src) => !largestImage || src !== largestImage.src)
            .map((src, index) => (
              <button
                onClick={() => openModal(src)}
                key={src}
                className="inline-block"
              >
                {renderImage(src, index)}
              </button>
            ))}
        </div>
      )}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Image Modal"
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "80%",
            maxHeight: "80%",
          },
        }}
      >
        <img
          src={`${process.env.REACT_APP_REPORTS_DOWNLOAD_URL}uploads/posts/${selectedImage}`}
          alt="Zoomed"
          style={{ width: "100%", height: "auto" }} // For modal images, allow them to use the full width and auto height
          crossOrigin="anonymous"
        />
        <button onClick={closeModal}>Close</button>
      </Modal>
    </>
  );
};

export default ImageTile;

// import React, { useState, useEffect } from "react";
// import Modal from "react-modal";

// Modal.setAppElement("#root");

// const ImageTile = ({ images }) => {
//   const [selectedImage, setSelectedImage] = useState(null);
//   const [modalIsOpen, setModalIsOpen] = useState(false);
//   const [largestImage, setLargestImage] = useState(null);

//   const openModal = (image) => {
//     setSelectedImage(image);
//     setModalIsOpen(true);
//   };

//   const closeModal = () => {
//     setModalIsOpen(false);
//     setSelectedImage(null);
//   };

//   const handleImageLoad = (event, src) => {
//     const { naturalWidth, naturalHeight } = event.target;
//     const resolution = naturalWidth * naturalHeight;

//     setLargestImage((prevLargest) => {
//       if (!prevLargest || resolution > prevLargest.resolution) {
//         return { src, resolution };
//       }
//       return prevLargest;
//     });
//   };

//   useEffect(() => {
//     // Optionally reset the largest image when the images prop changes
//     setLargestImage(null);
//   }, [images]);

//   if (!images || !Array.isArray(images) || images.length === 0) return null;

//   const renderImage = (src, index) => (
//     <img
//       key={src}
//       src={`${process.env.REACT_APP_REPORTS_DOWNLOAD_URL}uploads/posts/${src}`}
//       alt={`Image ${index + 1}`}
//       onLoad={(event) => handleImageLoad(event, src)}
//       className={
//         largestImage && largestImage.src === src
//           ? "object-contain w-full max-h-80"
//           : "w-24 h-24 object-cover"
//       }
//       crossOrigin="anonymous"
//     />
//   );

//   return (
//     <>
//       <div className="text-center">
//         {largestImage && (
//           <button
//             onClick={() => openModal(largestImage.src)}
//             className="inline-block"
//           >
//             {renderImage(largestImage.src)}
//           </button>
//         )}
//       </div>
//       <div className="flex items-center justify-center mt-2 space-x-2">
//         {images
//           .filter((src) => !largestImage || src !== largestImage.src)
//           .map((src, index) => (
//             <button
//               onClick={() => openModal(src)}
//               key={src}
//               className="inline-block"
//             >
//               {renderImage(src, index)}
//             </button>
//           ))}
//       </div>
//       <Modal
//         isOpen={modalIsOpen}
//         onRequestClose={closeModal}
//         contentLabel="Image Modal"
//         style={{
//           content: {
//             top: "50%",
//             left: "50%",
//             right: "auto",
//             bottom: "auto",
//             marginRight: "-50%",
//             transform: "translate(-50%, -50%)",
//             maxWidth: "80%",
//             maxHeight: "80%",
//           },
//         }}
//       >
//         <img
//           src={`${process.env.REACT_APP_REPORTS_DOWNLOAD_URL}uploads/posts/${selectedImage}`}
//           alt="Zoomed"
//           style={{ width: "100%", height: "auto" }}
//           crossOrigin="anonymous"
//         />
//         <button onClick={closeModal}>Close</button>
//       </Modal>
//     </>
//   );
// };

// export default ImageTile;
