import React, { useState, useEffect } from "react";
import communityImage from "../../../images/community-2.jpg";
import avatar from "../../../images/avatar-9.jpg";

const CardScrollSection = ({ communities, setViewCommunity }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const cardsPerPage = 5;
  const [cards, setCards] = useState([]);

  useEffect(() => {
    if (communities) {
      setCards(communities);
    }
  }, [communities]);

  // Scroll to top when loading a new page
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  // Calculate index of last card on the current page
  const indexOfLastCard = currentPage * cardsPerPage;
  // Calculate index of first card on the current page
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  // Get the current cards to display
  const currentCards = cards?.slice(indexOfFirstCard, indexOfLastCard);

  // Change page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="flex flex-col">
      {/* {JSON.stringify(cards)} */}
      <div className="flex-none p-2 border-gray-100 ">
        {/* Pagination */}
        {cards?.length > 5 && (
          <ul className="flex flex-row justify-end space-x-2">
            {Array.from(
              { length: Math.ceil(cards.length / cardsPerPage) },
              (_, index) => (
                <li key={index}>
                  <button
                    type="button"
                    className={`${
                      index + 1 === currentPage
                        ? "bg-blue-500 text-white"
                        : "bg-gray-200 text-gray-700"
                    } px-2 py-0 rounded-full hover:bg-blue-500  hover:text-white focus:outline-none focus:bg-blue-500 focus:text-white`}
                    onClick={() => paginate(index + 1)}
                  >
                    {index + 1}
                  </button>
                </li>
              )
            )}
          </ul>
        )}
      </div>
      <div className="flex-grow overflow-y-auto">
        <div className="flex flex-col p-4 space-y-4">
          {currentCards.map((card) => (
            <div
              key={card._id}
              className="p-4 bg-white border border-gray-100 rounded-md shadow"
            >
              <div
                className="relative mt-4 cursor-pointer group"
                onClick={() => setViewCommunity(card)}
              >
                <img
                  // src={communityImage}
                  src={
                    card?.community_cover
                      ? `${process.env.REACT_APP_REPORTS_DOWNLOAD_URL}uploads/community/${card?.community_cover}`
                      : communityImage
                  }
                  className="rounded-md h-[200px] w-[700px]"
                  crossOrigin="anonymous"
                  alt="Image"
                />

                <div className="absolute inset-0 bg-white opacity-50 duration-400 hover:bg-black"></div>
                <div className="absolute left-0 flex items-center block pl-10 transform -translate-y-1/2 top-1/2 ">
                  <img
                    src={
                      card?.community_logo
                        ? `${process.env.REACT_APP_REPORTS_DOWNLOAD_URL}uploads/community/${card?.community_logo}`
                        : avatar
                    }
                    className="rounded-full border border-white w-[60px]"
                    crossOrigin="anonymous"
                    alt="Image"
                  />
                  <div className="pl-2 font-bold text-black group group-hover:text-white">
                    <h1 className="uppercase">{card.community_name}</h1>
                  </div>
                </div>
              </div>
              <div className="flex justify-between mt-4 text-sm">
                <button
                  className="block p-2 text-black bg-gray-200 rounded-md btn-block"
                  disabled
                >
                  Subscribers: {card?.followers?.length}
                </button>
                <button
                  className="block p-2 text-black bg-gray-200 rounded-md"
                  disabled
                >
                  Likes: {card?.likes?.length}
                </button>
                <button
                  className="block p-2 text-black bg-gray-200 rounded-md"
                  disabled
                >
                  Views: {card?.views?.length}
                </button>
              </div>
              {/* <p className="mt-4 text-sm text-gray-500">{card.about}</p> */}
              <div
                className="max-w-2xl p-4 mx-auto my-8 bg-white"
                dangerouslySetInnerHTML={{
                  __html:
                    card?.about?.length > card?.about?.slice(0, 200)?.length
                      ? card?.about?.slice(0, 200) + "..."
                      : card?.about?.slice(0, 200),
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CardScrollSection;
