import React, { useEffect, useState } from "react";

const Loader = ({ postLoader }) => {
  const [showLoader, setShowLoader] = useState(postLoader);

  useEffect(() => {
    if (!postLoader) {
      const timer = setTimeout(() => setShowLoader(false), 300); // 300ms for fade out
      return () => clearTimeout(timer);
    }

    setShowLoader(true);
  }, [postLoader]);

  if (!showLoader) return null;

  return (
    <div
      className={`fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center ${
        postLoader ? "opacity-100" : "opacity-0 transition-opacity duration-300"
      }`}
    >
      <div className="flex items-center p-4 bg-white rounded-lg">
        <div className="w-8 h-8 mr-3 ease-linear border-4 border-t-4 border-gray-200 rounded-full loader"></div>
        Updating...
      </div>
    </div>
  );
};

export default Loader;
