import React from "react";
import AdminTemplate from "../../../components/AdminTemplate/AdminTemplate";

import UserAddChild from "./UserAddChild";
const UserAdd = () => {
  return (
    <AdminTemplate>
      <UserAddChild />
    </AdminTemplate>
  );
};

export default UserAdd;
