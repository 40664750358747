import React, { useState, useRef } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import ClientCaptcha from "react-client-captcha";

import CardBackground from "../../../components/MainTemplate/CardBackground";
import GeographicalComponent from "../../../components/GeographicalComponent/GeographicalComponent";

import "./style.css";

const RegisterCommunityForm = () => {
  return (
    <>
      <CardBackground>
        <RegisterComponent />
      </CardBackground>
    </>
  );
};

const RegisterComponent = () => {
  let navigate = useNavigate();
  const [captchaWidth] = useState(140);
  const [cstate, setCState] = useState({ captchaCode: "", valid: "a" });
  const setCode = (captchaCode) => {
    setCState({ ...cstate, captchaCode });
  };

  let captchaRef = useRef();
  const [formData, setFormData] = useState({
    first_name: null,
    last_name: null,
    email: null,
    password: null,
    confirm_password: null,
    community_name: null,
    mobile: null,
    city: null,
    city_other: null,
    state: null,
    state_other: null,
    country: null,
    community_type: null,
    community_owner: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const validationFields = [
    "community_type",
    "country",
    "state",
    "city",
    "password",
    "mobile",
    "email",
  ];

  const handleCommunityRegistration = () => {
    // Perform form submission or validation here
    const error = validationFields.filter((field) => {
      if (formData[field] === null) return field;
      return null;
    });

    if (error?.length) {
      toast.error(`Enter values for ${error}`, {
        className: "toast-word-wrap",
      });
      return;
    }

    if (!formData.community_name) {
      toast.error("Enter the Community Name");
      return;
    }

    if (formData?.password !== formData?.confirm_password)
      return toast.error("Passwords don't match!");

    if (cstate.valid !== cstate.captchaCode) {
      toast.error("Unable to login, Bad Captcha");
      captchaRef.current.focus();
      return;
    }

    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/community/add`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData },
    };
    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        // toast.success(response?.data?.message);
        navigate("/login");
      })
      .catch(function (error) {
        //  toast.error(JSON.stringify(error.response.data.message));
        toast.error(error?.response?.data?.message);
        //errorLogApi(error, "CreateUserChild", "/user/register");
        // console.log(error);
      });
  };

  return (
    <div
      className="w-full bg-transparent bg-opacity-60"
      style={{ wordBreak: "break-word" }}
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <i className="text-2xl text-blue-600 mdi mdi-account-group" />
          <div className="ml-3">
            <div className="text-md">Register New Community</div>
          </div>
        </div>
      </div>
      <hr className="mt-4 " />
      <div className="md:w-[600px] p-5 bg-opacity-60 ">
        <div className="mb-4">
          <label htmlFor="community_name" className="block mb-1 font-small">
            Community Name (Legal Registered name)
            <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            id="community_name"
            name="community_name"
            value={formData.community_name}
            onChange={handleChange}
            required
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <GeographicalComponent
          setFormData={setFormData}
          formData={formData}
          handleChange={handleChange}
        />
        <div className="flex mb-4">
          <div className="w-full">
            <label htmlFor="first_name" className="block mb-1 font-small">
              First Name <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              id="first_name"
              name="first_name"
              value={formData.first_name}
              onChange={handleChange}
              required
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          &nbsp;
          <div className="w-full">
            <label htmlFor="last_name" className="block mb-1 font-small">
              Last Name
            </label>
            <input
              type="text"
              id="last_name"
              name="last_name"
              value={formData.last_name}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </div>
        <div className="flex mb-4">
          <div className="w-full">
            <label htmlFor="email" className="block mb-1 font-small">
              Email <span className="text-red-500">*</span>
            </label>
            <input
              autoComplete="new-password"
              type="text"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          &nbsp;
          <div className="w-full">
            <label htmlFor="mobile" className="block mb-1 font-small">
              Mobile <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              id="mobile"
              name="mobile"
              value={formData.mobile}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </div>
        <div className="flex mb-4">
          <div className="w-full">
            <label htmlFor="password" className="block mb-1 font-small">
              Password <span className="text-red-500">*</span>
            </label>
            <input
              autoComplete="new-password"
              type="password"
              id="password"
              name="password"
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          &nbsp;
          <div className="w-full">
            <label htmlFor="confirm_password" className="block mb-1 font-small">
              Confirm Password <span className="text-red-500">*</span>
            </label>
            <input
              autoComplete="new-password"
              type="password"
              id="confirm_password"
              name="confirm_password"
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </div>
        <div className="relative mb-4">
          <div className="flex">
            <div className="w-2/3">
              <label htmlFor="captcha" className="block mb-2 font-semibold">
                Captcha
              </label>
              <input
                autoComplete="new-password"
                id="captcha"
                name="catpctafynd"
                type="text"
                className="w-full h-10 text-gray-900 bg-gray-100 border-none rounded-l-md peer focus:outline-none focus:borer-rose-600"
                placeholder="Enter Captcha"
                onChange={(e) =>
                  setCState({ ...cstate, valid: e.target.value })
                }
                style={{
                  padding: "10px",
                  paddingLeft: "20px !important",
                  fontSize: "15px",
                }}
                ref={captchaRef}
              />
            </div>
            <div className="w-1/3 mt-8">
              <ClientCaptcha
                //fontFamily={"'Times New Roman', serif"}
                captchaCode={setCode}
                width={captchaWidth}
                //   className="rounded-r-lg"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-between">
          <div className="mt-6 mb-4 text-start">
            <button
              onClick={() => navigate(-1)}
              className="px-4 py-2 text-white bg-gray-500 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600"
            >
              Back
            </button>
          </div>
          <div className="mt-6 mb-4 text-end">
            <button
              onClick={handleCommunityRegistration}
              className="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
            >
              Quick Register {">"}
            </button>
          </div>
        </div>
        {/* {JSON.stringify(formData)} */}
        {/* print form data̵ */}
        {/* <pre>{JSON.stringify(formData, null, 2)}</pre> */}
      </div>
    </div>
  );
};

export default RegisterCommunityForm;
