import React from "react";
import Select from "react-select";

import GeographicalComponent from "../../../components/GeographicalComponent/GeographicalComponent";
import ImageUploadMember from "./ImageUploadMember";
import CommunityImageUpload from "./CommunityImageUpload";
import DocumentUpload from "./DocumentUpload";
import RichTextEditor from "../../../components/MainTemplate/RichTextEditor";

const EditCommunitySection = ({
  formData,
  setFormData,
  handleSubmitUpdate,
  fetchCommunity,
}) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  function arrayToCommaSeparatedString(array) {
    // Check if the input is an array and not empty
    if (Array.isArray(array) && array.length) {
      // Join the array elements into a string, separated by commas
      return array.join(", ");
    } else {
      // Return an empty string if the input is not a valid array
      return "";
    }
  }

  const imageUploader = [
    {
      title: "President",
      field_name: "president_name",
      field_image: "president_image",
    },
    {
      title: "Vice President",
      field_name: "vice_president_name",
      field_image: "vice_president_image",
    },
    {
      title: "Secretary",
      field_name: "secretary_name",
      field_image: "secretary_image",
    },
    {
      title: "Treasurer",
      field_name: "treasurer_name",
      field_image: "treasurer_image",
    },
  ];

  const communityStatusOptions = [
    { label: "Open", value: "1" },
    { label: "Closed", value: "2" },
    { label: "Invite Only", value: "3" },
    { label: "Suspended", value: "4" },
  ];

  return (
    <form className="max-w-lg mx-auto">
      <CommunityImageUpload
        title="Community Graphics"
        memberImage={formData?.community_logo}
        memberImageFieldName="community_logo"
        memberFieldName="community_logo"
        communityCoverImage={formData?.community_cover}
        communityCoverImageFieldName="community_cover"
        handleChange={handleChange}
        formData={formData}
        setFormData={setFormData}
      />
      &nbsp;
      <div className="mb-4">
        <label htmlFor="community_name" className="block mb-1 font-small">
          Community Name <span className="text-red-500">*</span>
        </label>
        <input
          type="text"
          id="community_name"
          name="community_name"
          value={formData?.community_name}
          onChange={handleChange}
          required
          className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>
      <div className="mb-4">
        <label
          htmlFor="community_registration_no"
          className="block mb-1 font-small"
        >
          Community Registration <span className="text-red-500">*</span>
        </label>
        <input
          type="text"
          id="community_registration_no"
          name="community_registration_no"
          value={formData?.community_registration_no}
          onChange={handleChange}
          required
          className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>
      <div className="flex flex-row mb-4 justify-items-center">
        <div className="w-full">
          <label htmlFor="community_alias" className="block mb-1 font-small">
            Community Alias <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            id="community_alias"
            name="community_alias"
            value={formData?.community_alias}
            onChange={handleChange}
            required
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        &nbsp;
        <div className="w-full">
          <label htmlFor="community_alias" className="block mb-1 font-small">
            Community Status <span className="text-red-500">*</span>
          </label>
          <Select
            options={communityStatusOptions}
            id="community_status"
            name="community_status"
            value={formData?.community_status || null}
            onChange={(selected) => {
              setFormData({ ...formData, community_status: selected });
            }}
            placeholder="Select Status"
            className="text-base"
          />
        </div>
      </div>
      <div className="mb-14">
        <label htmlFor="about" className="block mb-1 font-small">
          About <span className="text-red-500">*</span>
        </label>
        <RichTextEditor formData={formData} setFormData={setFormData} />
        {/* <textarea
          type="text"
          id="about"
          name="about"
          row="5"
          value={formData?.about}
          onChange={handleChange}
          required
          className="w-full px-4 py-2 border rounded-md h-[100px] focus:outline-none focus:ring-2 focus:ring-blue-500"
        /> */}
      </div>
      <DocumentUpload
        handleChange={handleChange}
        formData={formData}
        setFormData={setFormData}
        documentField="community_registration_document"
      />
      &nbsp;
      <div className="mb-4">
        <div className="w-full">
          <label htmlFor="keywords" className="block mb-1 font-small">
            Keywords (use ',' to seperate ){" "}
            <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            id="keywords"
            name="keywords"
            value={formData?.keywords}
            onChange={handleChange}
            required
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
      </div>
      {imageUploader.map((img) => (
        <ImageUploadMember
          title={img?.title}
          memberImage={formData[img?.field_image]}
          memberImageFieldName={img?.field_image}
          memberFieldName={img?.field_name}
          handleChange={handleChange}
          formData={formData}
          setFormData={setFormData}
        />
      ))}
      {/* <ImageUploadMember
        title="President"
        memberImage={formData?.president_image}
        memberImageFieldName="president_image"
        memberFieldName="president_name"
        handleChange={handleChange}
        formData={formData}
        setFormData={setFormData}
      />
      <ImageUploadMember
        title="Vice President"
        memberImage={formData?.vice_president_image}
        memberImageFieldName="vice_president_image"
        memberFieldName="vice_president_name"
        handleChange={handleChange}
        formData={formData}
        setFormData={setFormData}
      />
      <ImageUploadMember
        title="Secretary"
        memberImage={formData?.secretary_image}
        memberImageFieldName="secretary_image"
        memberFieldName="secretary_name"
        handleChange={handleChange}
        formData={formData}
        setFormData={setFormData}
      />
      <ImageUploadMember
        title="Treasurer"
        memberImage={formData?.treasurer_image}
        memberImageFieldName="treasurer_image"
        memberFieldName="treasurer_name"
        handleChange={handleChange}
        formData={formData}
        setFormData={setFormData}
      /> */}
      &nbsp;
      <div className="flex mb-4">
        <div className="w-full">
          <label htmlFor="secondary_email" className="block mb-1 font-small">
            Secondary Email <span className="text-red-500">*</span>
          </label>
          <input
            type="email"
            id="secondary_email"
            name="secondary_email"
            value={formData?.secondary_email}
            onChange={handleChange}
            required
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        &nbsp;
        <div className="w-full">
          <label htmlFor="phone" className="block mb-1 font-small">
            Phone
          </label>
          <input
            type="text"
            id="phone"
            name="phone"
            value={formData?.phone}
            onChange={handleChange}
            required
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
      </div>
      <div className="flex mb-4">
        <div className="w-full">
          <label htmlFor="address_line_1" className="block mb-1 font-small">
            Address Line 1 <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            id="address_line_1"
            name="address_line_1"
            value={formData?.address_line_1}
            onChange={handleChange}
            required
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        &nbsp;
        <div className="w-full">
          <label htmlFor="address_line_1" className="block mb-1 font-small">
            Address Line 2 <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            id="address_line_2"
            name="address_line_2"
            value={formData?.address_line_2}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
      </div>
      <GeographicalComponent
        setFormData={setFormData}
        formData={formData}
        handleChange={handleChange}
      />
      <div className="mt-6 mb-10 text-end">
        {/* {JSON.stringify(formData)} */}
      </div>
    </form>
  );
};

export default EditCommunitySection;
