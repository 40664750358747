import React from "react";
import AdminTemplate from "../../components/AdminTemplate/AdminTemplate";

import TimelinesListChild from "./TimelinesListChild";

const TimelinesList = () => {
  return (
    <AdminTemplate>
      <TimelinesListChild />
    </AdminTemplate>
  );
};

export default TimelinesList;
