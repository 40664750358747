import React from "react";
import { useNavigate } from "react-router-dom";
import fyndLogo from "../../images/fyn-community-logo.png";

const Logo = () => {
  let navigate = useNavigate();
  return (
    <div
      className="flex items-center flex-shrink-0 p-2 mr-6 text-3xl text-black cursor-pointer font-boogaloo"
      onClick={() => navigate("/home")}
    >
      <img src={fyndLogo} width="150px" className="mx-auto rounded-lg" />
      {/* <span className="text-purple-700">FYND</span>{" "}
      <span className="text-blue-500">Community</span> */}
    </div>
  );
};

export default Logo;
