import React, { useState, useEffect, useCallback } from "react";
import Select from "react-select";
import { countriesData } from "../Data/countries";
import { states_data } from "../Data/states";
import { community_type_options } from "../../components/Data/MenuData";
import axios from "axios";

const GeographicalComponent = ({ setFormData, formData, handleChange }) => {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    setCountries(countriesData.map((c) => ({ value: c.id, label: c.name })));
  }, []);

  const fetchCities = useCallback(async (stateId) => {
    try {
      const userToken = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/community/cities/${stateId}`,
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      );
      return response.data.cities.map((city) => ({
        value: city.id,
        label: city.name,
      }));
    } catch (error) {
      console.error("Error fetching cities:", error);
      return [];
    }
  }, []);

  useEffect(() => {
    if (formData.country) {
      const filterStates = states_data
        .filter((s) => s.country_id === formData.country.value)
        .map((s) => ({ value: s.id, label: s.name }));
      setStates(filterStates);

      if (!filterStates.find((s) => s.value === formData.state?.value)) {
        setFormData((prev) => ({ ...prev, state: null, city: null }));
      }
    } else {
      setStates([]);
      setFormData((prev) => ({ ...prev, state: null, city: null }));
    }
  }, [formData.country, setFormData]);

  useEffect(() => {
    if (formData.state) {
      fetchCities(formData.state.value).then(setCities);
    } else {
      setCities([]);
    }
  }, [formData.state, fetchCities]);

  const handleSelectChange = (field) => (selectedOption) => {
    setFormData((prev) => ({ ...prev, [field]: selectedOption }));
  };

  return (
    <>
      <div className="mb-4 md:flex">
        <div className="w-full">
          <label htmlFor="community_type" className="block mb-1 font-small">
            Community Type <span className="text-red-500">*</span>
          </label>
          <Select
            options={community_type_options.sort((a, b) =>
              a.label.localeCompare(b.label)
            )}
            value={formData.community_type}
            onChange={handleSelectChange("community_type")}
            placeholder="Select Community Type"
            className="text-base"
          />
        </div>
        &nbsp;
        <div className="w-full">
          <label htmlFor="country" className="block mb-1 font-small">
            Country <span className="text-red-500">*</span>
          </label>
          <Select
            options={countries}
            value={formData.country}
            onChange={handleSelectChange("country")}
            placeholder="Select Country"
            className="text-base"
            menuPosition="fixed"
          />
        </div>
      </div>
      <div className="mb-4 md:flex">
        <div className="w-full">
          <label htmlFor="state" className="block mb-1 font-small">
            State <span className="text-red-500">*</span>
          </label>
          {states.length > 0 ? (
            <Select
              options={states}
              value={formData.state}
              onChange={handleSelectChange("state")}
              placeholder="Select State"
              className="text-base"
              isDisabled={!formData.country}
              menuPosition="fixed"
            />
          ) : (
            <input
              type="text"
              id="state_other"
              name="state_other"
              value={formData.state_other}
              onChange={handleChange}
              disabled={!formData.country}
              required
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          )}
        </div>
        &nbsp;
        <div className="w-full">
          <label htmlFor="city" className="block mb-1 font-small">
            City <span className="text-red-500">*</span>
          </label>
          <Select
            options={cities}
            value={formData.city}
            onChange={handleSelectChange("city")}
            placeholder="Select City"
            className="text-base"
            isDisabled={!formData.state}
            menuPosition="fixed"
          />
        </div>
      </div>
    </>
  );
};

export default GeographicalComponent;
