import React, { useState, useRef } from "react";
import backgroundImage from "../../images/users-background.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import jwt_decode from "jwt-decode";
import ClientCaptcha from "react-client-captcha";

//Global State using JOTAI
import { useAtom } from "jotai";
import { gbState } from "../../components/shared/GlobalState";
import SignupCard from "./SignupCard";

const SignupChild = () => {
  const bkImage = `url(${backgroundImage})`;
  let navigate = useNavigate();
  const [userStatus, setUserStatus] = useAtom(gbState);
  const [state, setState] = useState({ captchaCode: "", valid: "a" });
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  );
  const [captchaWidth, setCaptchaWidth] = useState(100);

  const setCode = (captchaCode) => {
    setState({ ...state, captchaCode });
  };

  return (
    <div className={`bg-cover bg-center h-screen`}>
      <div
        className="flex items-center justify-center h-screen min-h-screen py-6 bg-gray-100 bg-center bg-cover"
        style={{ backgroundImage: bkImage }}
      >
        <SignupCard />
      </div>
    </div>
  );
};

export default SignupChild;
