import React from "react";
import SignupChild from "./SignupChild";

const Signup = () => {
  return (
    <div>
      <SignupChild />
    </div>
  );
};

export default Signup;
