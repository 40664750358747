import React from "react";
import AdminTemplate from "../../components/AdminTemplate/AdminTemplate";
import EventsListChild from "./EventsListChild";

const EventsList = () => {
  return (
    <AdminTemplate>
      <EventsListChild />
    </AdminTemplate>
  );
};

export default EventsList;
