import React from "react";
import AdminTemplate from "../../../components/AdminTemplate/AdminTemplate";
import RoleUpdateChild from "./RoleUpdateChild";
import { useLocation } from "react-router-dom";

const RoleUpdate = () => {
  const { state } = useLocation();
  const roleRecord = state;

  return (
    <AdminTemplate>
      <RoleUpdateChild roleRecord={roleRecord} />
    </AdminTemplate>
  );
};

export default RoleUpdate;
