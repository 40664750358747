import React from "react";
import { useNavigate } from "react-router-dom";

const FeedComponent = ({ icon, feed_name, color, link }) => {
  let navigate = useNavigate();
  return (
    <div
      className="flex items-center mt-2 mb-4 hover:cursor-pointer"
      onClick={() => navigate(link)}
    >
      <div
        className={`flex items-center justify-center w-10 h-10 mr-4 ${color} rounded-full`}
      >
        <i className={`text-xl text-white mdi ${icon}`} />
      </div>
      <div className="text-sm">{feed_name}</div>
    </div>
  );
};

export default FeedComponent;
