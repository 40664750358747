import React, { useState, useEffect, useRef } from "react";
import avatar from "../../images/avatar-9.jpg";
import { menuItems } from "../Data/MenuData";
import MenuComponent from "./MenuComponent";
import { useNavigate, useLocation } from "react-router-dom";
import jwt_decode from "jwt-decode";

//Global State using JOTAI
import { useAtom } from "jotai";
import {
  gbState,
  gbEventState,
  gbEventVisibilityState,
  gbMyEventsState,
} from "../../components/shared/GlobalState";
import RightDropDownMenu from "./RightDropDownMenu";
import Logo from "./Logo";

const Topbar = ({ menuState, setMenuState }) => {
  let navigate = useNavigate();
  const location = useLocation();
  const [{ user }, setUserStatus] = useAtom(gbState);
  const [, setShowEvents] = useAtom(gbEventVisibilityState);
  const [, setEventsGlobal] = useAtom(gbEventState);
  const [, setMyEvents] = useAtom(gbMyEventsState);
  const [isHovered, setIsHovered] = useState(false);
  const searchRef = useRef();
  let hoverTimer;
  const [toggleBurgerMenu, setToggleBurgerMenu] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem("token");
    sessionStorage.removeItem("user-details");
    setUserStatus({ user: null });
    navigate("/login", { replace: true });
  };

  const handleMouseEnter = () => {
    hoverTimer = setTimeout(() => {
      setIsHovered(true);
    }, 2000);
  };

  const handleMouseLeave = () => {
    clearTimeout(hoverTimer);
    setIsHovered(false);
  };

  const handleHomeClick = () => {
    setEventsGlobal([]);
    setShowEvents(false);
    setMyEvents(false);
    if (location.pathname !== "/home") {
      navigate("/home");
    }
  };

  useEffect(() => {
    const checkToken = () => {
      const token = localStorage.getItem("token");
      if (token) {
        const decodedToken = jwt_decode(token);
        const currentTime = Date.now() / 1000;
        if (decodedToken.exp < currentTime) {
          // token has expired, do something here (e.g. log out the user)
          localStorage.removeItem("token");
          sessionStorage.removeItem("user-details");
          setUserStatus({ user: null });
          navigate("/login", { replace: true });
        } else {
          setUserStatus({ user: decodedToken });
        }
      }
    };
    checkToken();
  }, []);

  // console.log(user);
  // console.log(handleLogout);
  return (
    <header className="bg-white shadow-xl">
      <nav className="flex justify-between items-center w-[92%] mx-auto">
        <Logo />
        <div
          className={`nav-links transition duration-500 md:static ${
            toggleBurgerMenu ? "block" : "hidden md:block"
          } absolute bg-white md:min-h-fit min-h-[60vh] left-0 top-[8%] z-10 md:w-auto w-full flex items-center px-5`}
        >
          <ul className="flex md:flex-row flex-col md:items-center md:gap-[1vw] gap-1">
            {/* <li key="1000">
              <div className="relative">
                <input
                  className="left-0 w-12 h-12 px-4 py-2 transition-all duration-300 bg-gray-100 rounded-full md:mr-4 md:ml-4 outline-gray-400 hover:px-10 hover:w-64 focus:w-64 focus:outline-none"
                  type="text"
                  placeholder=""
                  ref={searchRef}
                />
                <span className="absolute inset-y-0 flex items-center pl-3 text-2xl md:left-4">
                  <i className="text-gray-600 mdi mdi-account-search"></i>
                </span>
              </div>
            </li> */}
            <li key="Home" onClick={handleHomeClick}>
              <div className="block mt-4 mr-4 text-sm text-gray-600 cursor-pointer lg:inline-block lg:mt-0 hover:text-blue-500">
                <div className="hidden md:inline">
                  <div className="flex items-center justify-center w-12 h-12 bg-gray-100 rounded-full">
                    <i className={`mdi mdi-home-account text-2xl`}></i>
                  </div>
                </div>

                <span className="inline md:hidden">
                  <div className="flex items-center">
                    <div className="flex items-center justify-center w-12 h-12 bg-gray-100 rounded-full">
                      <i className={`mdi mdi-home-account text-2xl`}></i>
                    </div>
                    <div className="ml-4">Home</div>
                  </div>
                </span>
              </div>
            </li>
            {menuItems.map((menu) => (
              <MenuComponent menu={menu} />
            ))}
          </ul>
        </div>
        <div className="flex items-center gap-6">
          <div className="flex items-center mr-4">
            <div className="hidden md:inline">
              <i className="text-2xl text-blue-600 mdi mdi-bell-outline" />
            </div>
            <div className="relative">
              <div
                className="flex items-center justify-center h-10 px-4 text-sm text-gray-600 cursor-pointer hover:text-gray-500 focus:outline-none"
                onClick={() => setMenuState(!menuState)}
                //onMouseEnter={() => setMenuState(!menuState)}
              >
                <img src={avatar} className="rounded-full w-[40px] p-1 mr-1" />
                <span className="block truncate w-27">
                  {user?.name}
                  <span className="block text-xs font-light text-gray-400">
                    {user?.user_type}
                  </span>
                </span>
              </div>
              <RightDropDownMenu
                setMenuState={setMenuState}
                menuState={menuState}
                handleLogout={handleLogout}
              />
            </div>
          </div>
          <svg
            viewBox="0 0 100 100"
            width="12"
            height="12"
            className={`z-10 cursor-pointer ${
              !toggleBurgerMenu ? "hidden" : "md:hidden"
            } mr-2`}
            onClick={() => setToggleBurgerMenu(!toggleBurgerMenu)}
          >
            <line
              x1="10"
              y1="10"
              x2="90"
              y2="90"
              stroke="black"
              stroke-width="10"
            />
            <line
              x1="90"
              y1="10"
              x2="10"
              y2="90"
              stroke="black"
              stroke-width="10"
            />
          </svg>
          <svg
            viewBox="0 0 100 80"
            width="12"
            height="12"
            className={`z-10 text-4xl cursor-pointer mr-2 ${
              toggleBurgerMenu ? "hidden" : "md:hidden"
            }`}
            onClick={() => setToggleBurgerMenu(!toggleBurgerMenu)}
          >
            <rect width="100" height="10"></rect>
            <rect y="30" width="100" height="10"></rect>
            <rect y="60" width="100" height="10"></rect>
          </svg>
        </div>
      </nav>
    </header>
  );
};

export default Topbar;
