import React from "react";
import CardBackground from "../MainTemplate/CardBackground";
import FeedComponent from "./FeedComponent";
import PageComponent from "./PageComponent";
import { useNavigate, useLocation } from "react-router-dom";
//Global State using JOTAI
import { useAtom } from "jotai";
import {
  gbState,
  gbMyEventsState,
  gbEventVisibilityState,
} from "../shared/GlobalState";
import AdminMenuComponent from "./AdminMenuComponent";

const ContextMenuSection = () => {
  let navigate = useNavigate();
  const [{ user }] = useAtom(gbState);

  const [myEvents, setMyEvents] = useAtom(gbMyEventsState);

  //console.log(user);
  const comAdmin = ["COMMUNITY_ADMIN"];

  const genMember = ["GEN_MEMBER"];

  const superAdmin = ["FULL_ACCESS"];

  return (
    <div className="overflow-y-hidden">
      {superAdmin.includes(user?.role) && (
        <CardBackground>
          <div className="flex flex-col w-full">
            <span className="text-sm text-gray-400">Admin Menu</span>
            <hr className="mb-3" />
            <AdminMenuComponent />
          </div>
        </CardBackground>
      )}
      <CardBackground>
        <div className="flex flex-col w-full">
          <span className="text-sm text-gray-400">Community Menu</span>
          <hr className="mb-3" />
          {genMember.includes(user?.role) && (
            <FeedComponent
              icon="mdi-account-group"
              feed_name="My Communities"
              link="/communities/follow"
              color="bg-slate-600"
            />
          )}
          {comAdmin.includes(user?.role) && (
            <MenuComponent
              icon="mdi-calendar-clock"
              feed_name="My Events"
              myEvents={myEvents}
              setMyEvents={setMyEvents}
              color="bg-slate-600"
            />
          )}
          {comAdmin.includes(user?.role) && (
            <FeedComponent
              icon="mdi-account-group"
              feed_name="Admin Communities"
              link="/community"
              color="bg-blue-600"
            />
          )}
          <FeedComponent
            icon="mdi-flash"
            feed_name="Popular Community"
            link=""
            color="bg-red-600"
          />
          <FeedComponent
            icon="mdi-web"
            feed_name="Search Community"
            link="/community/search"
            color="bg-green-600"
          />

          {comAdmin.includes(user?.role) && (
            <FeedComponent
              icon="mdi-square-edit-outline"
              feed_name="Edit Community"
              link="/community"
              color="bg-purple-600"
            />
          )}
        </div>
      </CardBackground>
      <CardBackground>
        <div className="w-full">
          <span className="text-sm text-gray-400 ">More Pages</span>
          <hr />
          <PageComponent
            icon="mdi-email-outline"
            page_name="Message Box"
            color="text-blue-600"
            link="/messages"
          />
          <PageComponent
            icon="mdi-sofa"
            page_name="Near Hotel"
            color="text-blue-600"
          />
          <PageComponent
            icon="mdi-google-maps"
            page_name="Latest Event"
            color="text-blue-600"
          />
          <PageComponent
            icon="mdi-television-guide"
            page_name="Live Stream"
            color="text-blue-600"
          />
        </div>
      </CardBackground>
      <CardBackground>
        <div className="w-full">
          <span className="text-sm text-gray-400">Accounts</span>
          <hr />
          <PageComponent
            icon="mdi-react"
            page_name="Settings"
            color="text-gray-500"
          />
          <PageComponent
            icon="mdi-google-analytics"
            page_name="Analytics"
            color="text-gray-500"
          />
          <PageComponent
            icon="mdi-chat"
            page_name="Chat"
            color="text-gray-500"
          />
        </div>
      </CardBackground>
    </div>
  );
};

const MenuComponent = ({ icon, feed_name, color, myEvents, setMyEvents }) => {
  let location = useLocation();
  let navigate = useNavigate();
  const [, setShowEvents] = useAtom(gbEventVisibilityState);

  const handleListMyEvents = () => {
    setMyEvents(true);
    setShowEvents(false);
    if (location.pathname !== "/home") {
      setMyEvents(true);
      navigate("/home");
    }
  };

  return (
    <div
      className="flex items-center mt-2 mb-4 hover:cursor-pointer"
      onClick={handleListMyEvents}
    >
      <div
        className={`flex items-center justify-center w-10 h-10 mr-4 ${color} rounded-full`}
      >
        <i className={`text-xl text-white mdi ${icon}`} />
      </div>
      <div className="text-sm">{feed_name}</div>
    </div>
  );
};

export default ContextMenuSection;
