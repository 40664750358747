import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Calendar, Badge } from "antd";
import dayjs from "dayjs";
import axios from "axios";
import toast from "react-hot-toast";

//Global state Access
import { useAtom } from "jotai";
import {
  gbState,
  gbEventState,
  gbMyEventsState,
} from "../../components/shared/GlobalState";

const EventCalender = () => {
  let navigate = useNavigate();
  const location = useLocation();

  const [eventsData, setEventsData] = useState();
  const [selectedEventsOnDate, setSelectedEventsOnDate] = useState(null);

  const [eventsGlobal, setEventsGlobal] = useAtom(gbEventState);
  const [, setMyEvents] = useAtom(gbMyEventsState);
  const disabledDate = (current) => {
    // Can not select days after today
    return current && current > dayjs().endOf("day");
  };

  function formatEvents(eventsArray) {
    const formattedEvents = {};

    eventsArray?.forEach((event) => {
      const startDate = event.event_start_date.split("T")[0]; // Get only the date part
      if (!formattedEvents[startDate]) {
        formattedEvents[startDate] = [];
      }

      // Assuming the mapping of event types isn't provided, we use a default 'success'
      // You might add logic here to determine type based on some event properties
      const eventFormat = {
        type: "success", // Default type, you might want to calculate based on event details
        content: event.title, // Assuming you want the title to be the content shown
      };

      formattedEvents[startDate].push(eventFormat);
    });

    return formattedEvents;
  }

  // Sample events structure
  const events = formatEvents(eventsData);

  useEffect(() => {
    // console.log("event calender function called");
    let userToken = localStorage.getItem("token");
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/events/all`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    axios(config)
      .then(function (response) {
        console.log(response?.data);
        setEventsData(response?.data?.events);
      })
      .catch(function (error) {
        toast.error(error?.response?.data?.message);
      });
  }, []);

  const refreshEventCalender = () => {
    let userToken = localStorage.getItem("token");
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/events/all`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    axios(config)
      .then(function (response) {
        // console.log(response?.data);
        setEventsData(response?.data?.events);
      })
      .catch(function (error) {
        toast.error(error?.response?.data?.message);
      });
  };

  // Example dates you want to color differently
  const specialDates = [
    dayjs().add(1, "days").format("YYYY-MM-DD"), // Tomorrow
    dayjs().add("08-04-2024").format("YYYY-MM-DD"), //
    dayjs().add("10-04-2024").format("YYYY-MM-DD"), //

    dayjs().add(3, "days").format("YYYY-MM-DD"), // 3 days from now
  ];

  const dateCellRender = (value) => {
    const formattedDate = value.format("YYYY-MM-DD");
    const dayEvents = events[formattedDate];

    // Conditionally apply a green background if there are events for this date
    const cellStyle =
      dayEvents && dayEvents.length > 0
        ? { backgroundColor: "#2E86C1", padding: "8px", height: "100%" }
        : null;

    return (
      <div style={cellStyle}>
        {dayEvents ? <span className="bg-blue-800 h-100"></span> : null}
      </div>
    );
  };

  // Checking all the events on particluar  date and listing them.
  const listEvents = (date) => {
    console.log("Selected Date:", date.format("YYYY-MM-DD"));

    function filterEventsByDate(events, date) {
      setMyEvents(false);
      // Date provided should be in the format 'YYYY-MM-DD'
      return events?.filter((event) => {
        // Extract the date part from the 'event_start_date' and compare
        return event.event_start_date.split("T")[0] === date;
      });
    }

    const ev = filterEventsByDate(eventsData, date.format("YYYY-MM-DD"));
    console.log(ev);

    // setSelectedEventsOnDate(ev);
    // Only navigate if events are present and we are not already on the summary page
    // if (ev.length > 0 && location.pathname !== "/events/summary") {
    //   navigate("/events/summary", { state: ev });
    // } else {
    if (ev.length > 0) {
      setEventsGlobal(ev);
    }

    if (location.pathname !== "/home") {
      navigate("/home");
    }
  };

  const listEventsforWholeMonth = (date) => {
    console.log("Panel Change:", date.format("YYYY-MM-DD"));
  };

  return (
    <>
      <div className="cursor-pointer" onClick={refreshEventCalender}>
        <i className="float-right mdi mdi-refresh hover:text-bold"></i>
      </div>
      <div className="w-full h-screen overflow-hidden">
        {/* Full width and height of the viewport */}{" "}
        {/* This sets the max height to the height of the viewport */}
        <Calendar
          className="overflow-hidden"
          onPanelChange={(value) => listEventsforWholeMonth(value)}
          onSelect={(value) => listEvents(value)}
          onHover-cell={(date) => console.log("hovered cell: ", date)}
          cellRender={dateCellRender}
        />
      </div>
    </>
  );
};

export default EventCalender;
