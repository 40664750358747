import React from "react";
import AdminTemplate from "../../../components/AdminTemplate/AdminTemplate";

import RoleListChild from "./RoleListChild";

const RoleList = () => {
  return (
    <AdminTemplate>
      <RoleListChild />
    </AdminTemplate>
  );
};

export default RoleList;
