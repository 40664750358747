import React, { useState } from "react";
import Topbar from "../Topbar/Topbar";

const MainTemplate = ({ children }) => {
  const [menuState, setMenuState] = useState(true);

  return (
    <div className="flex flex-col h-screen">
      <div className="sticky top-0 z-10">
        <Topbar menuState={menuState} setMenuState={setMenuState} />
      </div>
      <div class="flex-grow bg-gray-100">{children}</div>
    </div>
  );
};

export default MainTemplate;
