import React from "react";
import axios from "axios";
import toast from "react-hot-toast";
import placeHolderImage from "../../../images/placeholder_image.png";
import coverPlaceHolderImage from "../../../images/cover_image_placeholder.png";

const CommunityImageUpload = ({
  title,
  memberImage,
  memberImageFieldName,
  memberFieldName,
  communityCoverImage,
  communityCoverImageFieldName,
  handleChange,
  formData,
  setFormData,
}) => {
  const uploadImage = async (file, communityId, imageField) => {
    const formData = new FormData();
    formData.append("imageFile", file);
    let userToken = localStorage.getItem("token");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/community/upload/image/${communityId}/${imageField}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Assuming the server returns the path of the uploaded image
      setFormData((prevState) => ({
        ...prevState,
        [imageField]: response.data.image,
      }));
      toast.success(response.data.message);
    } catch (error) {
      console.error("Error uploading image:", error);
      // Handle error
    }
  };

  // Function to handle immediate file upload and displaying the image
  const handleFileSelect = async (event) => {
    const file = event.target.files[0];
    if (file) {
      // await handleFileChange(file, memberImageFieldName); // Upload the file
      uploadImage(file, formData?._id, memberImageFieldName); // Update the displayed image (this function needs to be implemented)
    }
  };

  const handleCoverFileSelect = async (event) => {
    const file = event.target.files[0];
    if (file) {
      // await handleFileChange(file, memberImageFieldName); // Upload the file
      uploadImage(file, formData?._id, communityCoverImageFieldName); // Update the displayed image (this function needs to be implemented)
    }
  };

  return (
    <fieldset className="p-2 whitespace-normal border w-100">
      <legend className="float-none w-auto p-2 fs-5">{title}</legend>
      <div className="flex mb-4">
        <div className="flex flex-row justify-between w-full">
          <div className="pl-4">
            {memberImage ? (
              <img
                src={`${process.env.REACT_APP_REPORTS_DOWNLOAD_URL}uploads/community/${memberImage}`}
                width="100px"
                crossOrigin="anonymous"
                alt="Image"
              />
            ) : (
              <img src={placeHolderImage} width="100px" alt="Placeholder" />
            )}
            <input
              type="file"
              name={memberImageFieldName}
              onChange={handleFileSelect}
              accept="image/*"
              style={{ display: "none" }}
              id={memberImageFieldName}
            />
            <button
              type="button" // Add this line
              onClick={() =>
                document.getElementById(memberImageFieldName).click()
              }
              className="px-3 py-1 mt-1 text-sm text-white bg-gray-600 rounded hover:bg-gray-900"
            >
              Logo File
            </button>
          </div>
          <div className="mr-4">
            {communityCoverImage ? (
              <img
                src={`${process.env.REACT_APP_REPORTS_DOWNLOAD_URL}uploads/community/${communityCoverImage}`}
                width="200px"
                height="100px"
                crossOrigin="anonymous"
                alt="Image"
              />
            ) : (
              <img
                src={coverPlaceHolderImage}
                width="140px"
                alt="Placeholder"
              />
            )}
            <input
              type="file"
              name={communityCoverImageFieldName}
              onChange={handleCoverFileSelect}
              accept="image/*"
              style={{ display: "none" }}
              id={communityCoverImageFieldName}
            />
            <button
              type="button" // Add this line
              onClick={() =>
                document.getElementById(communityCoverImageFieldName).click()
              }
              className="px-3 py-1 mt-1 text-sm text-white bg-gray-600 rounded hover:bg-gray-900"
            >
              Cover Image File
            </button>
          </div>
        </div>
      </div>
    </fieldset>
  );
};

export default CommunityImageUpload;
