import React from "react";
import { useNavigate } from "react-router-dom";

const AdminMenuComponent = ({ icon, feed_name, color, link }) => {
  let navigate = useNavigate();

  const menus = [
    {
      icon: "mdi-account-group",
      feed_name: "Registered Users",
      link: "/users",
      color: "bg-slate-600",
    },
    {
      icon: "mdi-account-key",
      feed_name: "User Roles",
      link: "/roles",
      color: "bg-slate-600",
    },
    {
      icon: "mdi-checkbox-multiple-marked-outline",
      feed_name: "Community Lists",
      link: "/community/list",
      color: "bg-slate-600",
    },
    {
      icon: "mdi-alarm",
      feed_name: "Timeline Lists",
      link: "/timelines",
      color: "bg-slate-600",
    },
    {
      icon: "mdi-calendar-multiple",
      feed_name: "All Events Lists",
      link: "/events-all",
      color: "bg-slate-600",
    },
    {
      icon: "mdi-account-off",
      feed_name: "Banned users Lists",
      link: "/users",
      color: "bg-slate-600",
    },
    {
      icon: "mdi-shape-outline",
      feed_name: "All Ads/Listings",
      link: "/users",
      color: "bg-slate-600",
    },
  ];

  return menus.map((m) => (
    <div
      className="flex items-center mt-2 mb-4 hover:cursor-pointer"
      onClick={() => navigate(m.link)}
    >
      <div
        className={`flex items-center justify-center w-10 h-10 mr-4 ${m.color} rounded-full`}
      >
        <i className={`text-xl text-white mdi ${m.icon}`} />
      </div>
      <div className="text-sm">{m.feed_name}</div>
    </div>
  ));
};

export default AdminMenuComponent;
