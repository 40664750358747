import React, { useState, useEffect } from "react";
import axios from "axios";

import MainTemplate from "../../components/MainTemplate/MainTemplate";
// import CenterStageSection from "./CenterStageSection";
// import TimelineSection from "./TimelineSection";
import ContextMenuSection from "../../components/ContextMenuSection/ContextMenuSection";

//Global State using JOTAI
import { useAtom } from "jotai";
import { gbState } from "../../components/shared/GlobalState";
import ChatApp from "./ChatApp";
import MessageApp from "./MessageApp";

const MessagePage = () => {
  return (
    <MainTemplate>
      <div className="flex flex-grow bg-gray-100">
        <div className="hidden w-1/5 md:block">
          {/* overflow-y-hidden */}
          <div className="sticky top-0 h-screen overflow-y-hidden ">
            <ContextMenuSection />
          </div>
        </div>
        <div className="w-full md:hidden">
          {/* <TimelineSection data={data} /> */}
        </div>

        <div className="hidden md:flex md:w-4/5">
          <div className="flex w-full p-2">
            {/* <CenterStageSection
        data={data}
        fetchTimeLineData={fetchTimeLineData}
      /> */}
            <MessageApp />
          </div>
        </div>
      </div>
      <div className="fixed bottom-0 left-0 right-0 p-4 text-center bg-gray-200 md:hidden">
        This div is visible only on small screens .
      </div>
    </MainTemplate>
  );
};

export default MessagePage;
