import React from "react";

function UserView({ currentRecord }) {
  //const recordKeys = Object.keys(currentRecord);
  currentRecord = {
    ...currentRecord,
    church_name: currentRecord?.church_id?.church_name,
  };
  const recordKeys = ["name", "email", "role", "status", "mobile"];
  return (
    <div className="w-full overflow-y-auto" style={{ wordBreak: "break-word" }}>
      {/* {JSON.stringify(currentRecord)} */}
      <table className="w-full divide-y divide-gray-200">
        <tbody className="bg-white divide-y divide-gray-200">
          {recordKeys.map((rec, index) => {
            return (
              <tr key={index}>
                <td
                  className="px-6 py-1 text-sm font-medium text-gray-900 whitespace-nowrap"
                  style={{ textTransform: "capitalize" }}
                >
                  {rec.replace("_", " ")}
                </td>
                <td className="px-6 py-1 text-sm text-gray-500 whitespace-nowrap">
                  {currentRecord[rec]}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default UserView;
