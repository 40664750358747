import React, { useState } from "react";

const CommunityCard = ({ data }) => {
  // Destructure data for easy access
  const {
    community_name,
    community_alias,
    email,
    phone,
    address_line_1,
    address_line_2,
    country,
    city,
    state,
    zipcode,
    community_type,
    about,
  } = data;
  const {
    first_name,
    last_name,
    mobile,
    email: ownerEmail,
    role,
    user_type,
    account_status,
  } = data.community_owner;

  // State to track the active tab
  const [activeTab, setActiveTab] = useState("communityDetails");

  return (
    <div className="flex flex-col gap-4 md:flex-col">
      {/* Tabs */}
      <div className="flex border-b">
        <button
          className={`py-2 px-4 ${
            activeTab === "communityDetails" ? "border-b-2 border-blue-500" : ""
          }`}
          onClick={() => setActiveTab("communityDetails")}
        >
          Community Details
        </button>
        <button
          className={`py-2 px-4 ${
            activeTab === "communityOwner" ? "border-b-2 border-blue-500" : ""
          }`}
          onClick={() => setActiveTab("communityOwner")}
        >
          Community Owner
        </button>
      </div>

      {/* Community Details Content */}
      {activeTab === "communityDetails" && (
        <div className="p-4 bg-white rounded-lg shadow-lg card">
          {/* <h2 className="mb-4 text-lg font-semibold">Community Details</h2> */}
          {/* ... Rest of the community details table ... */}
          {/* Community Details Card */}
          <div className="p-4 bg-white rounded-lg shadow-lg card">
            {/* <h2 className="mb-4 text-lg font-semibold">Community Details</h2> */}
            <table className="min-w-full">
              <tbody>
                <tr>
                  <td className="w-1/3 p-2 text-left">Community Name</td>
                  <td>{community_name}</td>
                </tr>
                <tr>
                  <td className="w-1/3 p-2">Alias</td>
                  <td>{community_alias}</td>
                </tr>
                <tr>
                  <td className="w-1/3 p-2">Email</td>
                  <td>{email}</td>
                </tr>
                <tr>
                  <td className="w-1/3 p-2">Phone</td>
                  <td>{phone}</td>
                </tr>
                <tr>
                  <td className="w-1/3 p-2">Address</td>
                  <td>{`${address_line_1} ${address_line_2}`}</td>
                </tr>
                <tr>
                  <td className="w-1/3 p-2">Country</td>
                  <td>{country}</td>
                </tr>
                <tr>
                  <td className="w-1/3 p-2">City</td>
                  <td>{city}</td>
                </tr>
                <tr>
                  <td className="w-1/3 p-2">State</td>
                  <td>{state}</td>
                </tr>
                <tr>
                  <td className="w-1/3 p-2">Zip Code</td>
                  <td>{zipcode}</td>
                </tr>
                <tr>
                  <td className="w-1/3 p-2">Type</td>
                  <td>{community_type}</td>
                </tr>
                <tr>
                  <td className="w-1/3 p-2">About</td>
                  <td>{about}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}

      {/* Community Owner Content */}
      {activeTab === "communityOwner" && (
        <div className="p-4 bg-white rounded-lg shadow-lg card">
          {/* <h2 className="mb-4 text-lg font-semibold">Community Owner</h2> */}
          {/* ... Rest of the community owner table ... */}
          {/* Community Owner Details Card */}
          <div className="p-4 bg-white rounded-lg shadow-lg card">
            <h2 className="mb-4 text-lg font-semibold">Community Owner</h2>
            <table className="min-w-full">
              <tbody>
                <tr>
                  <td>First Name</td>
                  <td>{first_name}</td>
                </tr>
                <tr>
                  <td>Last Name</td>
                  <td>{last_name}</td>
                </tr>
                <tr>
                  <td>Mobile</td>
                  <td>{mobile}</td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td>{ownerEmail}</td>
                </tr>
                <tr>
                  <td>Role</td>
                  <td>{role}</td>
                </tr>
                <tr>
                  <td>User Type</td>
                  <td>{user_type}</td>
                </tr>
                <tr>
                  <td>Account Status</td>
                  <td>{account_status ? "Active" : "Inactive"}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default CommunityCard;
