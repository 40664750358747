import React from "react";
import { useNavigate } from "react-router-dom";
import MainAdminComponent from "./MainAdminComponent";

//Global State using JOTAI
import { useAtom } from "jotai";
import { gbState } from "../../components/shared/GlobalState";

const RightDropDownMenu = ({ setMenuState, menuState, handleLogout }) => {
  let navigate = useNavigate();
  const [{ user }] = useAtom(gbState);
  return (
    <div
      className={`absolute right-0 z-10 ${
        menuState ? "hidden" : null
      } w-42 mt-4 bg-white rounded-md shadow-lg`}
      onMouseLeave={() => setMenuState(!menuState)}
    >
      <button
        className="w-full px-4 py-2 text-sm text-left text-gray-600 hover:bg-gray-100"
        onClick={() => {
          setMenuState(!menuState);
          navigate("/myaccount");
        }}
      >
        My Account
      </button>

      {/* Administrator MENU access component */}
      {user?.role === "FULL_ACCESS" && (
        <MainAdminComponent setMenuState={setMenuState} menuState={menuState} />
      )}

      {/* <ChurchAdminComponent setMenuState={setMenuState} menuState={menuState} /> */}

      <button
        className="w-full px-4 py-2 text-sm text-left text-gray-600 hover:bg-gray-100"
        onClick={() => {
          setMenuState(!menuState);
          handleLogout();
        }}
      >
        Logout
      </button>
    </div>
  );
};

export default RightDropDownMenu;
