import React from "react";
import { useNavigate } from "react-router-dom";

const PageComponent = ({ icon, page_name, color, link }) => {
  let navigate = useNavigate();

  const handleLink = () => {
    if (link) {
      navigate(link);
    }
  };

  return (
    <div
      className="flex items-center mt-4 mb-2 cursor-pointer"
      onClick={handleLink}
    >
      <i className={`mdi ${icon} mr-4 text-3xl ${color}`}></i>
      <div className="text-sm">{page_name}</div>
    </div>
  );
};

export default PageComponent;
