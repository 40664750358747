import React, { useEffect, useState } from "react";
import axios from "axios";

const AttendeeView = ({ currentRecord }) => {
  const [attendees, setAttendees] = useState();
  console.log(currentRecord);

  useEffect(() => {
    // console.log(event[0]);

    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/user/details/`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ids: currentRecord?.attendees },
    };
    if (currentRecord?.attendees?.length) {
      axios(config)
        .then(function (response) {
          // console.log(response);
          setAttendees(response.data.users);
          // console.log("Event Name recieved");
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [currentRecord?.attendees]);
  return (
    <>
      {attendees?.length ? (
        <AttendeesTable attendees={attendees} />
      ) : (
        "Loading..."
      )}
    </>
  );
};

function AttendeesTable({ attendees }) {
  return (
    <div className="max-w-4xl px-4 mx-auto mt-6 overflow-y-hidden sm:px-6 lg:px-8">
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      colSpan={3}
                      scope="col"
                      className="px-6 py-3 tracking-wider text-center text-gray-800 uppercase text-md font-lg"
                    >
                      Attendees
                    </th>
                  </tr>
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                    >
                      First Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                    >
                      Last Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                    >
                      Email
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {attendees.map((person, index) => (
                    <tr
                      key={person._id}
                      className={`${
                        index % 2 === 0
                          ? "bg-silver-darker"
                          : "bg-silver-lighter"
                      } hover:bg-gray-300`}
                    >
                      <td className="px-6 py-4 text-sm font-medium text-gray-900 whitespace-nowrap">
                        {person.first_name}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                        {person.last_name}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                        {person.email}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AttendeeView;
