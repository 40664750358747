import React, { useState, useEffect } from "react";
import Select from "react-select";
import axios from "axios";
import toast from "react-hot-toast";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // include the styles

const ReplyMail = ({ user, toggleReplyModal, currentRecord }) => {
  const [recipients, setRecipients] = useState([]);
  const [oldMail, setOldMail] = useState(currentRecord);
  const inputWidth = "230px";

  const placeHolderText = (text) => (
    <span className="text-sm text-light">{text}</span>
  );

  const [mail, setMail] = useState({
    subject: "Re: " + oldMail?.subject,
    message:
      `\n\n` +
      "---------------- \n" +
      `From: ${oldMail?.name} \n` +
      `Subject: ${oldMail?.subject} \n` +
      `Date: ${oldMail?.time}` +
      "\n\n" +
      oldMail?.message,
    recipient: oldMail?.from_user,
    from_user: user?._id,
    recipient_email: "",
  });

  useEffect(() => {
    let userToken = localStorage.getItem("token");
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/email/to-user/${user?._id}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    axios(config)
      .then(function (response) {
        console.log(response.data);
        setRecipients(response.data.recipients);
        // console.log("Event Name recieved");
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const handleChange = (e) => {
    setMail({ ...mail, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Sending Mail:", mail);

    // Add logic to send mail here or integrate with an API
    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/email/send/`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...mail, from_user: user?._id },
    };
    axios(config)
      .then(function (response) {
        console.log(response);
        toast.success(response?.data?.message);
        toggleReplyModal();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div className="max-w-2xl p-1 mx-auto bg-white rounded-md shadow-md">
      {/* {JSON.stringify(recipients?.find((r) => r.value === mail?.recipient))} */}
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label
            htmlFor="to"
            className="block mb-2 text-sm font-semibold text-gray-700"
          >
            To
          </label>
          <Select
            name="mailBox"
            onChange={(selected) => {
              setMail({
                ...mail,
                recipient: selected?.value,
                recipient_email: selected?.email, // Ensure this property exists on your 'selected' object
              });
            }}
            options={recipients}
            placeholder={placeHolderText("Select recipient")}
            className="w-full outline-none h-11"
            menuPosition="fixed"
            value={
              mail?.recipient
                ? recipients.find((r) => r.value === mail.recipient)
                : null
            }
            isDisabled={true}
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="subject"
            className="block text-sm font-semibold text-gray-700"
          >
            Subject
          </label>
          <input
            type="text"
            id="subject"
            name="subject"
            value={mail.subject}
            onChange={handleChange}
            className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            required
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="message"
            className="block text-sm font-semibold text-gray-700"
          >
            Message
          </label>
          {/* <ReactQuill
            theme="snow"
            value={mail?.message}
            onChange={handleChange}
          /> */}
          <textarea
            id="message"
            name="message"
            value={mail?.message}
            onChange={handleChange}
            rows="6"
            className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            required
          ></textarea>
        </div>
        <div className="mb-4">
          <button
            type="submit"
            className={`px-6 py-2 font-semibold text-white rounded-md ${
              recipients.find((r) => r.value === mail.recipient)
                ? "bg-blue-500 hover:bg-blue-600" // active state
                : "bg-blue-200 cursor-not-allowed" // disabled state
            }`}
            disabled={!recipients.some((r) => r.value === mail.recipient)}
          >
            Send
          </button>
        </div>
        {/* {JSON.stringify(mail)} */}
      </form>
    </div>
  );
};

export default ReplyMail;
