import React from "react";
import MainTemplate from "../../components/MainTemplate/MainTemplate";
import ContextMenuSection from "../../components/ContextMenuSection/ContextMenuSection";

const AdminDashboard = () => {
  return (
    <MainTemplate>
      <div className="flex flex-grow bg-gray-100">
        <div className="hidden w-1/5 md:block">
          {/* overflow-y-hidden */}
          <div className="sticky top-0 h-screen overflow-y-hidden ">
            <ContextMenuSection />
          </div>
        </div>
        <div className="w-full md:hidden"></div>

        <div className="hidden md:flex md:w-4/5">
          <div className="flex w-full overflow-y-auto">
            {/* <CenterStageSection
              data={data}
              fetchTimeLineData={fetchTimeLineData}
            /> */}
          </div>
        </div>
      </div>
      <div className="fixed bottom-0 left-0 right-0 p-4 text-center bg-gray-200 md:hidden">
        This div is visible only on small screens .
      </div>
    </MainTemplate>
  );
};

export default AdminDashboard;
