import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";

import CardBackground from "../../components/MainTemplate/CardBackground";
import CreatePost from "./CreatePost";
import PostComponent from "./PostComponent";
import EventComponent from "./EventComponent";
import EventListComponent from "./EventListComponent";
//Global state Access
import { useAtom } from "jotai";
import {
  gbState,
  gbEventState,
  gbEventVisibilityState,
  gbMyEventsState,
} from "../../components/shared/GlobalState";

import Modal from "../../components/Modal/Modal";
import EventView from "./EventView";
import AttendeeView from "./AttendeeView";

const TimelineSection = ({ data, fetchTimeLineData }) => {
  const [{ user }] = useAtom(gbState);
  const [eventsGlobal] = useAtom(gbEventState);
  const [eventData, setEventData] = useState(data);
  const [showEvents, setShowEvents] = useAtom(gbEventVisibilityState);
  const [myEvents, setMyEvents] = useAtom(gbMyEventsState);

  const [showModal, setShowModal] = useState(false);
  const [showAttendees, setShowAttendees] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});

  const toggleAttendees = (event) => {
    setCurrentRecord(event);
    setShowAttendees(!showAttendees);
  };

  const toggleModal = (event) => {
    setCurrentRecord(event);
    setShowModal(!showModal);
  };

  const rsvpEvent = (event) => {
    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/events/rsvp/${event?._id}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
        data,
      },
    };
    axios(config)
      .then(function (response) {
        console.log("attending");
        toast.success(response?.data?.messsage);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const allowedCreatePost = [
    "FULL_ACCESS",
    "COMMUNITY_ADMIN",
    "SUB_COMMUNITY_ADMIN",
  ];

  useEffect(() => {
    setShowEvents(false);
    setEventData(null);
    setMyEvents(false);
  }, []);

  useEffect(() => {
    console.log(eventsGlobal?.length);
    if (eventsGlobal?.length > 0) {
      setEventData(eventsGlobal);
      setShowEvents(true);
    } else {
      setShowEvents(false);
    }
  }, [eventsGlobal]);

  const cards = [
    { title: "Card 1", description: "Description 1" },
    { title: "Card 2", description: "Description 2" },
    { title: "Card 3", description: "Description 3" },
    { title: "Card 1", description: "Description 1" },
    { title: "Card 2", description: "Description 2" },
    { title: "Card 3", description: "Description 3" },
    { title: "Card 1", description: "Description 1" },
    { title: "Card 2", description: "Description 2" },
    { title: "Card 3", description: "Description 3" },
    // Add more cards as needed
  ];
  return (
    <>
      {showModal && (
        <Modal onClose={toggleModal}>
          {/* <h2 className="mb-4 text-xl font-bold">Detail</h2> */}
          <EventView currentRecord={currentRecord} />
        </Modal>
      )}
      {showAttendees && (
        <Modal onClose={toggleAttendees}>
          {/* <h2 className="mb-4 text-xl font-bold">Detail</h2> */}
          <AttendeeView currentRecord={currentRecord} />
        </Modal>
      )}
      {allowedCreatePost.includes(user?.role) && !myEvents && (
        <CardBackground>
          <CreatePost fetchTimeLineData={fetchTimeLineData} />
        </CardBackground>
      )}
      {!myEvents && (
        <CardBackground>
          <CardScroller cards={cards} />
        </CardBackground>
      )}

      {myEvents ? (
        <EventListComponent
          toggleView={toggleModal}
          rsvpEvent={rsvpEvent}
          toggleAttendees={toggleAttendees}
        />
      ) : showEvents && eventsGlobal?.length ? (
        eventData?.map((event) => (
          <div className="p-1">
            <EventComponent
              event={event}
              toggleView={toggleModal}
              rsvpEvent={rsvpEvent}
              toggleAttendees={toggleAttendees}
            />
          </div>
        ))
      ) : (
        data?.map((post) => (
          <CardBackground>
            <PostComponent post={post} />
          </CardBackground>
        ))
      )}

      {/* {showEvents
        ? eventData?.map((event) => (
            <div className="p-1">
              <EventComponent event={event} />
            </div>
          ))
        : data?.map((post) => (
            <CardBackground>
              <PostComponent post={post} />
            </CardBackground>
          ))} */}

      {/* {data?.length
        ? data?.map((post) => (
            <CardBackground>
              <PostComponent post={post} />
            </CardBackground>
          ))
        : "Loading..."} */}

      {/* <CardBackground>{JSON.stringify(data)}</CardBackground> */}
    </>
  );
};

const CardScroller = ({ cards }) => {
  const scrollerRef = useRef(null);
  const [showLeftButton, setShowLeftButton] = useState(false);
  const [showRightButton, setShowRightButton] = useState(false);

  const checkForScroll = () => {
    const { scrollLeft, scrollWidth, clientWidth } = scrollerRef.current;
    setShowLeftButton(scrollLeft > 0);
    setShowRightButton(scrollLeft < scrollWidth - clientWidth);
  };

  const scrollLeft = () => {
    scrollerRef.current.scrollBy({
      left: -200, // Adjust the scroll amount as needed
      behavior: "smooth",
    });
  };

  const scrollRight = () => {
    scrollerRef.current.scrollBy({
      left: 200, // Adjust the scroll amount as needed
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const scroller = scrollerRef.current;
    scroller.addEventListener("scroll", checkForScroll, { passive: true });
    checkForScroll(); // Initial check to determine button visibility
    return () => {
      scroller.removeEventListener("scroll", checkForScroll);
    };
  }, []);

  return (
    <div className="relative overflow-hidden h-[150px]">
      <div className="flex overflow-x-auto" ref={scrollerRef}>
        {cards.map((card, index) => (
          <div key={index} className="flex-shrink-0 mx-2 w-50 h-[150px] py-10">
            <div className="p-4 bg-white rounded shadow">
              <h3 className="mb-2 text-lg font-bold">{card.title}</h3>
              <p className="text-gray-600">{card.description}</p>
            </div>
          </div>
        ))}
      </div>
      {showLeftButton && (
        <div
          className="absolute left-0 p-2 transform -translate-y-1/2 rounded-full cursor-pointer top-1/2"
          onClick={scrollLeft}
        >
          <div className="flex items-center justify-center w-10 h-10 p-4 mr-3 bg-gray-100 rounded-full">
            <i className="mdi mdi-arrow-left" />
          </div>
        </div>
      )}
      {showRightButton && (
        <div
          className="absolute right-0 p-2 transform -translate-y-1/2 rounded-full cursor-pointer top-1/2"
          onClick={scrollRight}
        >
          <div className="flex items-center justify-center w-10 h-10 p-4 mr-3 bg-gray-100 rounded-full">
            <i className="mdi mdi-arrow-right" />
          </div>
        </div>
      )}
    </div>
  );
};

export default TimelineSection;
