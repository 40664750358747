import React, { useEffect, useState } from "react";
import axios from "axios";

const EventDetail = ({ eventData }) => {
  const [cname, setCname] = useState();

  // Format the date and time
  const formatDateTime = (dateStr, timeStr) => {
    const date = new Date(dateStr);
    return `${date.toLocaleDateString()} at ${timeStr}`;
  };

  useEffect(() => {
    // console.log(event[0]);

    let userToken = localStorage.getItem("token");
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/community/name/${eventData?.community_id}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    if (eventData?.community_id) {
      axios(config)
        .then(function (response) {
          // console.log(response);
          eventData.community_name = response?.data?.community;
          setCname(response.data.community);
          // console.log("Event Name recieved");
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [eventData?.community_id]);

  return (
    <div className="max-w-2xl p-4 mx-auto my-6 bg-white ">
      <div className="mb-4">
        <h2 className="text-2xl font-bold text-gray-800">{eventData?.title}</h2>
        <small>
          <b>Organizer:</b> {cname}
        </small>
        <p className="text-justify text-gray-600">{eventData?.description}</p>
      </div>
      <div className="mb-4">
        <p className="text-gray-700">
          <strong>Start:</strong>{" "}
          {formatDateTime(
            eventData?.event_start_date,
            eventData?.event_start_time
          )}
        </p>
        <p className="text-gray-700">
          <strong>End:</strong>{" "}
          {formatDateTime(eventData?.event_end_date, eventData?.event_end_time)}
        </p>
      </div>
      <div className="mb-4">
        <p className="text-gray-700">
          <strong>Location:</strong> {eventData?.event_location}
        </p>
      </div>
      <div className="mb-4">
        <p className="text-gray-700">
          <strong>Created by:</strong> {eventData?.contact_person}
        </p>
        <p className="text-gray-600">
          <strong>Phone:</strong> {eventData?.phone}
        </p>
        <p className="text-gray-600">
          <strong>Email:</strong> {eventData?.contact_email}
        </p>
      </div>
      {/* Conditionally render the image if it exists */}
      {eventData.image && (
        <img
          className="w-full h-auto mb-4 rounded-lg"
          src={eventData?.image}
          alt={eventData?.title}
        />
      )}
      <div className="text-sm text-gray-500">
        <p>
          Last updated on {new Date(eventData?.updatedAt).toLocaleDateString()}
        </p>
      </div>
    </div>
  );
};

export default EventDetail;
