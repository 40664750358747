import React from "react";
import MainTemplate from "../MainTemplate/MainTemplate";
import ContextMenuSection from "../ContextMenuSection/ContextMenuSection";
import CardBackground from "../MainTemplate/CardBackground";

const AdminTemplate = ({ children }) => {
  return (
    <MainTemplate>
      <div className="flex flex-grow bg-gray-100">
        <div className="hidden w-1/5 md:block">
          <div className="sticky top-0 h-screen overflow-y-hidden">
            <ContextMenuSection />
          </div>
        </div>
        <div className="w-full md:hidden">
          <div className="w-full">
            <CardBackground>{children}</CardBackground>
          </div>
        </div>
        <div className="hidden md:flex md:w-4/5">
          <div className="flex w-full overflow-y-auto">
            <div className="w-full">
              <CardBackground>{children}</CardBackground>
            </div>
          </div>
        </div>
      </div>
    </MainTemplate>
  );
};

export default AdminTemplate;
