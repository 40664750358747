import React, { useState, useRef } from "react";
import axios from "axios";
import toast from "react-hot-toast";

const CreatePost = ({ fetchTimeLineData }) => {
  const MAX_ALLOWED_IMAGES = 5;
  const [filesCount, setFilesCount] = useState(0); // new state variable to keep track of the number of files selected
  const [videoFilesCount, setVideoFilesCount] = useState(0); // new state variable to keep track of the number of files selected
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const fileInputRef = useRef(); // create a reference for the file input element
  const videoFileInputRef = useRef(); // a reference for Video file input
  const messageInput = useRef(); //Message input

  const [postData, setPostData] = useState({
    message: "",
    images: [],
  });

  const resetForm = () => {
    setPostData({ message: "", images: [], videos: [] });
    setFilesCount(0);
    setVideoFilesCount(0);
    fileInputRef.current.value = ""; // Reset file input
    videoFileInputRef.current.value = ""; // Reset video file input
    messageInput.current.value = "";
  };

  const handleImageUploadClick = () => {
    fileInputRef.current.click(); // programmatically trigger the click event of the file input when mdi-image is clicked
  };

  const handleVideoUploadClick = () => {
    videoFileInputRef.current.click();
  };

  const handleInputChange = (e) => {
    setPostData({ ...postData, message: e.target.value });
  };

  const handleFileChange = (e) => {
    setFilesCount(e.target.files.length); // update the state with the length of the 'files' array
    setPostData({ ...postData, images: e.target.files });
  };

  const handleVideoFileChange = (e) => {
    setVideoFilesCount(e.target.files.length); // update the state with the length of the 'files' array
    setPostData({ ...postData, videos: e.target.files });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const formData = new FormData();
    formData.append("message", postData.message);

    var photoLength = 0;
    for (let i = 0; i < postData?.images?.length; i++) {
      formData.append("images", postData.images[i]);
      photoLength = i;
    }

    if (postData?.videos?.length) {
      formData.append("videos", postData?.videos[0]);
    }

    //Check if message is blank
    if (!postData?.message) return toast.error("Message is required");

    // Image upload check
    if (postData?.images?.length > MAX_ALLOWED_IMAGES)
      return toast.error("You are exceeding the 5 photo limit");

    try {
      let userToken = localStorage.getItem("token");
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/timeline/add`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${userToken}`,
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(percentCompleted);
          },
        }
      );
      console.log(response.data);
      toast.success(`${response.data.message}`);
      setUploadProgress(0); // Reset progress
      resetForm();
      fetchTimeLineData();
    } catch (error) {
      console.error("Error submitting post:", error);
    }
  };
  return (
    <div className="w-full">
      <div className="flex items-center">
        <div className="flex items-center justify-center w-10 h-10 p-4 mr-3 bg-gray-100 rounded-full">
          <i className="mdi mdi-account-edit" />
        </div>
        <span className="text-sm">Create post</span>
      </div>
      <div className="w-full mt-4">
        <textarea
          name="message"
          rows={3}
          className="w-full p-2 border rounded bg-border-gray-400 focus:outline-none"
          onChange={handleInputChange}
          ref={messageInput}
        />
      </div>
      <div className="flex flex-row items-center">
        <div className="mr-2">
          <button onClick={handleImageUploadClick}>
            <i className="text-2xl text-green-500 mdi mdi-image" />
          </button>
          <input
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            multiple
            onChange={handleFileChange}
            ref={fileInputRef} // attach the reference to the file input element
          />
        </div>
        <span className="mr-4 text-sm">
          <button onClick={handleImageUploadClick}>
            Photo {filesCount !== 0 && "(" + filesCount + ")"}
          </button>
        </span>
        <div className="mr-2">
          <i className="text-2xl text-blue-500 mdi mdi-camera" />
          <input
            type="file"
            accept="video/*"
            style={{ display: "none" }}
            onChange={handleVideoFileChange}
            ref={videoFileInputRef}
          />
        </div>
        <button onClick={handleVideoUploadClick}>
          <span className="text-sm">
            Video {videoFilesCount !== 0 && "(" + videoFilesCount + ")"}
          </span>
        </button>
      </div>
      {/* Progress bar */}
      {uploadProgress > 0 && (
        <div
          style={{ width: uploadProgress + "%" }}
          className="py-1 text-xs leading-none text-center text-white bg-blue-500"
        >
          {uploadProgress}%
        </div>
      )}
      {/* Submit button */}
      <button
        type="submit"
        className="float-right px-4 py-2 mt-4 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
        onClick={handleSubmit}
      >
        Post
      </button>
    </div>
  );
};

export default CreatePost;
