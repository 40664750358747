import avatar from "../../images/avatar-9.jpg";
import ImageTile from "./ImageTile";
import VideoPlayer from "./VideoPlayer";

//Global State using JOTAI
import { useAtom } from "jotai";
import { gbState } from "../../components/shared/GlobalState";

const PostComponent = ({ post }) => {
  // const [{ getTimeSincePost }] = useAtom(gbState);

  const post_creator = post?.createdBy?.last_name
    ? post?.createdBy?.first_name + " " + post?.createdBy?.last_name
    : post?.createdBy?.first_name;

  const getTimeSincePost = (createdAt) => {
    // Parse the createdAt string into a Date object
    const createdDate = new Date(createdAt);
    // Get the current time as a Date object
    const now = new Date();

    // Calculate the difference in milliseconds
    const diffInMs = now - createdDate;
    // Convert milliseconds into days and weeks
    const days = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);

    // Same day: hours or minutes
    if (days < 1) {
      const hours = Math.floor(diffInMs / (1000 * 60 * 60));
      if (hours >= 1) {
        return `${hours} hour${hours > 1 ? "s" : ""} ago`;
      } else {
        const minutes = Math.floor(diffInMs / (1000 * 60));
        return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
      }
    }
    // Yesterday
    else if (days === 1) {
      return "Yesterday";
    }
    // 2-6 days ago
    else if (days < 7) {
      return `${days} days ago`;
    }
    // 1 week ago
    else if (weeks === 1) {
      return "1 week ago";
    }
    // 2-4 weeks ago
    else if (weeks <= 4) {
      return `${weeks} weeks ago`;
    }
    // 1 month ago or more
    else if (months >= 1) {
      return `${months} month${months > 1 ? "s" : ""} ago`;
    }
  };
  return (
    <div className="w-full">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <img src={avatar} className="rounded-full w-[60px] p-1 mr-1" />
          <div className="">
            <div className="text-sm">{post_creator}</div>
            <div className="text-sm text-gray-400">
              {getTimeSincePost(post?.createdAt)}
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center w-10 h-10 p-4 bg-gray-100 rounded-full">
          <i className="mdi mdi-dots-horizontal" />
        </div>
      </div>
      <p className="mt-3 mr-10 text-sm text-gray-400">{post?.message}</p>

      <div className="mt-4">
        {post?.photo && <ImageTile images={post?.photo} />}
        {post.video[0] && <VideoPlayer video={post.video[0]} />}
        {/* <img src={postImage} className="rounded-md" alt="" /> */}
      </div>
      <div className="flex items-center justify-between mt-4">
        <div className="">
          <i className="mr-2 mdi mdi-thumb-up-outline" />
          <i className="mdi mdi-heart-outline" />{" "}
          <span className="mr-3 text-sm">2.8k Like</span>
          <i className="text-xl mdi mdi-chat-outline" />
          <span className="text-sm">22 Comments</span>
        </div>
        <div className="flex items-center">
          <i className="mr-3 text-xl mdi mdi-share-variant-outline" />
          <span className="text-sm">Share</span>
        </div>
      </div>
      {/* {JSON.stringify(post)} */}
    </div>
  );
};

export default PostComponent;
