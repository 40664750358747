import React from "react";
import TimelineSection from "./TimelineSection";
import HighlightsSection from "./HighlightsSection";

const CenterStageSection = ({ data, fetchTimeLineData }) => {
  return (
    <>
      {/* <div className="w-full">
        <TimelineSection data={data} fetchTimeLineData={fetchTimeLineData} />
      </div> */}
      <div className="w-2/3">
        <TimelineSection data={data} fetchTimeLineData={fetchTimeLineData} />
      </div>
      <div className="w-1/3">
        <HighlightsSection />
      </div>
    </>
  );
};

export default CenterStageSection;
