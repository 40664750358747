import React, { useEffect, useState } from "react";
import communityImage from "../../../images/community-2.jpg";
import avatar from "../../../images/avatar-9.jpg";
import axios from "axios";
import toast from "react-hot-toast";
import placeHolderImage from "../../../images/placeholder_image.png";
import Modal from "../../../components/Modal/Modal";

const ViewCommunitySection = ({ viewCommunity, setViewCommunity }) => {
  const [card, setCard] = useState();
  const [comlikes, setComlikes] = useState();
  const [modalImage, setModalImage] = useState(false);
  const [modalData, setModalData] = useState();
  //const [follow]
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setCard(viewCommunity);
  }, [viewCommunity]);

  const toggleImageModal = (imageData, name, title) => {
    if (!imageData) return toast.error("No Image Specified");
    // setModal(!modal);
    setModalImage(!modalImage);
    setModalData({ image: imageData, name, title });
  };

  useEffect(() => {
    const registerView = () => {
      let userToken = localStorage.getItem("token");
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/community/views`,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        data: { community_id: viewCommunity?._id },
      };
      axios(config)
        .then(function (response) {
          //console.log(JSON.stringify(response?.data));
        })
        .catch(function (error) {
          toast.error(error?.response?.data?.message);
        });
    };
    if (card?._id) {
      registerView();
      setComlikes(card?.likes?.length);
    }
  }, [card]);

  const likeCommunity = () => {
    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/community/like`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { community_id: card?._id },
    };

    axios(config)
      .then(function (response) {
        toast.success(response?.data?.likes);

        if (!response?.data?.likes) {
          setViewCommunity({ ...viewCommunity, likes: 0 });
        } else {
          setViewCommunity({ ...viewCommunity, likes: response?.data?.likes });
        }

        //console.log(JSON.stringify(response?.data));
      })
      .catch(function (error) {
        toast.error(error?.response?.data?.message);
      });
  };

  const likeCommunity_v2 = () => {
    const userToken = localStorage.getItem("token");

    if (!userToken) {
      toast.error("User not authenticated");
      return;
    }

    const communityId = card?._id;

    if (!communityId) {
      toast.error("Community ID is not available");
      return;
    }

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/community/like`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { community_id: communityId },
    };

    axios(config)
      .then((response) => {
        const likes = response?.data?.likes ?? 0;
        toast.success(`Likes: ${likes}`);
        setViewCommunity((prev) => ({ ...prev, likes }));
      })
      .catch((error) => {
        const errorMessage =
          error?.response?.data?.message || "An error occurred";
        toast.error(errorMessage);
      });
  };

  const followCommunity = () => {
    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/community/follow`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { community_id: card?._id },
    };
    axios(config)
      .then(function (response) {
        toast.success(response?.data?.likes);

        if (!response?.data?.likes) {
          setViewCommunity({ ...viewCommunity, followers: 0 });
        } else {
          setViewCommunity({ ...viewCommunity, likes: response?.data?.follow });
        }

        //console.log(JSON.stringify(response?.data));
      })
      .catch(function (error) {
        toast.error(error?.response?.data?.message);
      });
  };

  const com_details = [
    { label: "Community Name", value: card?.community_name },
    { label: "Community Alias", value: card?.community_alias },
    { label: "Community Type", value: card?.community_type?.label },
    // { label: "Keyword", value: card?.keywords.toString() },
    { label: "President", value: card?.president_name },
    { label: "Vice President", value: card?.vice_president_name },
    { label: "Secretary", value: card?.secretary_name },
    { label: "Treasurer", value: card?.treasurer_name },
    { label: "Email", value: card?.secondary_email },
    { label: "Phone", value: card?.phone },
    {
      label: "Address",
      value: card?.address_line_2
        ? card?.address_line_1 + " " + card?.address_line_2
        : card?.address_line_1,
    },
    { label: "Country", value: card?.country?.label },
    { label: "City", value: card?.city?.label },
    { label: "State", value: card?.state?.label },
  ];

  return (
    <div key={card?._id} className="">
      {modalImage && (
        <Modal onClose={toggleImageModal}>
          {modalData ? (
            <div className="mt-2">
              <div className="">
                <img
                  src={`${process.env.REACT_APP_REPORTS_DOWNLOAD_URL}uploads/community/${modalData?.image}`}
                  className="object-cover rounded-lg"
                  crossOrigin="anonymous"
                  alt="President"
                />
              </div>
              <div className="">
                <h2 className="text-2xl font-bold">{modalData?.title}</h2>
              </div>
              <div className="">
                <h2 className="text-xl font-bold">{modalData?.name}</h2>
              </div>
            </div>
          ) : (
            "No Image specified"
          )}
        </Modal>
      )}
      <div
        className="relative mt-4 cursor-pointer group"
        onClick={() => setViewCommunity(card)}
      >
        <img
          // src={communityImage}
          src={
            card?.community_cover
              ? `${process.env.REACT_APP_REPORTS_DOWNLOAD_URL}uploads/community/${card?.community_cover}`
              : communityImage
          }
          className="rounded-md h-[200px] w-[700px]"
          crossOrigin="anonymous"
          alt="Image"
        />
        <div className="absolute inset-0 bg-white rounded-md opacity-50 duration-400 hover:bg-black"></div>
        <div className="absolute left-0 flex items-center pl-10 transform -translate-y-1/2 top-1/2">
          <div className="flex items-center w-full">
            <img
              src={
                card?.community_logo
                  ? `${process.env.REACT_APP_REPORTS_DOWNLOAD_URL}uploads/community/${card?.community_logo}`
                  : avatar
              }
              className="rounded-full border border-white w-[60px]"
              crossOrigin="anonymous"
              alt="Image"
            />
            <div className="pl-2 font-bold text-black group group-hover:text-white">
              <h1 className="uppercase">{card?.community_name}</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-between mt-4 text-sm">
        <button
          onClick={followCommunity}
          className="block p-2 text-white bg-blue-500 rounded-md cursor-pointer hover:bg-blue-800"
        >
          Subscribe: {card?.followers?.length}
        </button>
        <button
          className="block p-2 text-white bg-blue-500 rounded-md cursor-pointer hover:bg-blue-800"
          onClick={() => likeCommunity()}
        >
          <i className="mdi mdi-heart" />
          &nbsp; Likes: {comlikes}
        </button>
        <button
          className="block p-2 text-black bg-gray-200 rounded-md"
          disabled
        >
          Views: {card?.views?.length}
        </button>
      </div>
      <div className="mt-4 overflow-hidden border border-gray-200 shadow sm:rounded-lg">
        <div className="px-4 py-2 bg-gray-100 sm:px-6">
          <h3 class="text-md leading-6 font-medium text-gray-900">About</h3>
        </div>
        {/* <p className="px-5 mt-4 mb-4 text-sm text-gray-500">{card?.about}</p> */}
        <div
          className="max-w-2xl p-4 mx-auto my-8 bg-white"
          dangerouslySetInnerHTML={{ __html: card?.about }}
        />
      </div>
      <div className="mt-4 overflow-hidden border border-gray-200 shadow sm:rounded-lg">
        <div className="px-4 py-2 bg-gray-50 sm:px-6">
          <h3 class="text-md leading-6 font-medium text-gray-900">
            Member Details
          </h3>
        </div>
        <div className="flex flex-row m-4 justify-evenly">
          <div
            className="text-center cursor-pointer"
            onClick={() =>
              toggleImageModal(
                card?.president_image,
                card?.president_name,
                "President"
              )
            }
          >
            {card?.president_image ? (
              <img
                src={`${process.env.REACT_APP_REPORTS_DOWNLOAD_URL}uploads/community/${card?.president_image}`}
                className="object-cover w-24 h-24"
                crossOrigin="anonymous"
                alt="President"
              />
            ) : (
              <img
                src={placeHolderImage}
                className="object-cover w-24 h-24"
                alt="Placeholder"
              />
            )}
            <p className="text-sm font-semibold">{card?.president_name}</p>
            <span className="text-xs text-gray-600">President</span>
          </div>
          <div
            className="text-center cursor-pointer"
            onClick={() =>
              toggleImageModal(
                card?.vice_president_image,
                card?.vice_president_name,
                "Vice President"
              )
            }
          >
            {card?.vice_president_image ? (
              <img
                src={`${process.env.REACT_APP_REPORTS_DOWNLOAD_URL}uploads/community/${card?.vice_president_image}`}
                className="object-cover w-24 h-24"
                crossOrigin="anonymous"
                alt="Vice President"
              />
            ) : (
              <img
                src={placeHolderImage}
                className="object-cover w-24 h-24"
                alt="Placeholder"
              />
            )}
            <p className="text-sm font-semibold">{card?.vice_president_name}</p>
            <span className="text-xs text-gray-600">Vice President</span>
          </div>
          <div
            className="text-center cursor-pointer"
            onClick={() =>
              toggleImageModal(
                card?.secretary_image,
                card?.secretary_name,
                "Secretary"
              )
            }
          >
            {card?.secretary_image ? (
              <img
                src={`${process.env.REACT_APP_REPORTS_DOWNLOAD_URL}uploads/community/${card?.secretary_image}`}
                className="object-cover w-24 h-24"
                crossOrigin="anonymous"
                alt="Secretary"
              />
            ) : (
              <img
                src={placeHolderImage}
                className="object-cover w-24 h-24"
                alt="Placeholder"
              />
            )}
            <p className="text-sm font-semibold">{card?.secretary_name}</p>
            <span className="text-xs text-gray-600">Secretary</span>
          </div>
          <div
            className="text-center cursor-pointer"
            onClick={() =>
              toggleImageModal(
                card?.treasurer_image,
                card?.treasurer_name,
                "Treasurer"
              )
            }
          >
            {card?.treasurer_image ? (
              <img
                src={`${process.env.REACT_APP_REPORTS_DOWNLOAD_URL}uploads/community/${card?.treasurer_image}`}
                className="object-cover w-24 h-24"
                crossOrigin="anonymous"
                alt="Treasurer"
              />
            ) : (
              <img
                src={placeHolderImage}
                className="object-cover w-24 h-24"
                alt="Placeholder"
              />
            )}
            <p className="text-sm font-semibold">{card?.treasurer_name}</p>
            <span className="text-xs text-gray-600">Treasurer</span>
          </div>
        </div>
      </div>
      <div className="mt-4 overflow-hidden border border-gray-200 shadow sm:rounded-lg">
        <div className="px-4 py-2 bg-gray-50 sm:px-6">
          <h3 class="text-md leading-6 font-medium text-gray-900">
            Community Details
          </h3>
        </div>
        {/* {JSON.stringify(com_details)} */}
        <div className="bg-white border border-gray-200">
          <div className="px-4 py-5 sm:px-6">
            <div className="grid grid-cols-2">
              {com_details?.map((d, index) => (
                <React.Fragment key={index}>
                  <div
                    className={`px-6 py-1 ${
                      index % 2 === 0 ? "bg-gray-100" : "bg-white"
                    }`}
                  >
                    <div className="mt-1 text-sm font-medium leading-5 tracking-wider text-gray-800 uppercase">
                      {d?.label}{" "}
                      {/* Modify this line according to the structure of d.label */}
                    </div>
                  </div>
                  <div
                    className={`px-6 py-1 ${
                      index % 2 === 0 ? "bg-gray-100" : "bg-white"
                    }`}
                  >
                    <div className="mt-1 text-sm leading-5 text-gray-600">
                      {d?.value}{" "}
                      {/* Modify this line according to the structure of d.value */}
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* {JSON.stringify(card)} */}
    </div>
  );
};

export default ViewCommunitySection;
