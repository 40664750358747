import ProtectedRoutes from "./components/Routes/ProtectedRoutes";
import { Toaster } from "react-hot-toast";
import "@mdi/font/css/materialdesignicons.min.css";
import "react-confirm-alert/src/react-confirm-alert.css"; // Make sure this is imported to use the default styles

const App = () => {
  return (
    <div>
      <Toaster
        position="top-right"
        toastOptions={{
          style: {
            fontSize: "12px",
            zIndex: 9999, // Adjust the z-index as needed
          },
        }}
      />
      <ProtectedRoutes />
    </div>
  );
};

export default App;
