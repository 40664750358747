import React from "react";
import AdminTemplate from "../../../components/AdminTemplate/AdminTemplate";
import MyAccountChild from "./MyAccountChild";

const MyAccount = () => {
  return (
    <AdminTemplate>
      <MyAccountChild />
    </AdminTemplate>
  );
};

export default MyAccount;
