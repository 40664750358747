import React from "react";
import avatar from "../../../images/avatar-9.jpg";
import groupImage from "../../../images/e-2.jpg";
import CardBackground from "../../../components/MainTemplate/CardBackground";

const HighlightsSection = () => {
  return (
    <div>
      <CardBackground>
        <div className="flex flex-col justify-center w-full">
          <div className="flex justify-between">
            <h2 className="text-sm text-gray-400">Friend Requests</h2>
            <div className="text-sm text-blue-500">See All</div>
          </div>
          <hr />
          <FriendRequestComponent />
          <FriendRequestComponent />
          <FriendRequestComponent />
        </div>
      </CardBackground>
      <CardBackground>
        <div className="flex flex-col justify-center w-full">
          <div className="flex justify-between">
            <h2 className="text-sm text-gray-400">Confirm Friend</h2>
            <div className="text-sm text-blue-500">See All</div>
          </div>
          <hr />
          <ConfirmFriend />
          <ConfirmFriend />
          <ConfirmFriend />
        </div>
      </CardBackground>
      <CardBackground>
        <div className="flex flex-col justify-center w-full">
          <div className="flex justify-between">
            <h2 className="text-sm text-gray-400">Suggest Group</h2>
            <div className="text-sm text-blue-500">See All</div>
          </div>
          <hr />
          <div className="mt-4">
            <img src={groupImage} className="rounded-md" />
          </div>
        </div>
      </CardBackground>
    </div>
  );
};

const FriendRequestComponent = () => {
  return (
    <div className="mt-2">
      <div className="flex items-center">
        <img src={avatar} className="rounded-full w-[60px] p-1 mr-1" />
        <div className="">
          <div className="text-sm">Anthony Daugloi</div>
          <div className="text-sm text-gray-400">12 mutual friends</div>
        </div>
      </div>
      <div className="flex justify-between mt-3">
        <div className="">
          <button className="block w-[95px] h-[35px] px-2 py-2 text-sm text-white bg-blue-500 rounded-md">
            Confirm
          </button>
        </div>
        <div className="">
          <button className="block w-[95px] h-[35px] px-2 py-2 text-sm text-black bg-gray-300 rounded-md">
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

const ConfirmFriend = () => {
  return (
    <div className="p-3 mt-2 bg-gray-100 rounded-md">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <img src={avatar} className="rounded-full w-[60px] p-1 mr-1" />
          <div className="">
            <div className="text-sm">Anthony Daugloi</div>
            <div className="text-sm text-gray-400">12 mutual friends</div>
          </div>
        </div>
        <div className="flex items-center justify-center w-10 h-10 p-4 bg-white rounded-full">
          <i className="text-sm mdi mdi-arrow-right" />
        </div>
      </div>
    </div>
  );
};

export default HighlightsSection;
