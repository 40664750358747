import React, { useEffect, useState } from "react";
import Icon from "@mdi/react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  mdiCalendarClock,
  mdiMapMarker,
  mdiClockOutline,
  mdiTextBox,
  mdiPhoneClassic,
  mdiAccountArrowLeft,
  mdiEmailCheck,
  mdiHandExtended,
} from "@mdi/js";

//Global state Access
import { useAtom } from "jotai";
import { gbState } from "../../components/shared/GlobalState";

const EventComponent = ({ event, toggleView, rsvpEvent, toggleAttendees }) => {
  const [{ user }] = useAtom(gbState);
  const [cname, setCname] = useState();
  let navigate = useNavigate();

  useEffect(() => {
    // console.log(event[0]);

    let userToken = localStorage.getItem("token");
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/community/name/${event?.community_id}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    if (event?.community_id) {
      axios(config)
        .then(function (response) {
          // console.log(response);
          event.community_name = response?.data?.community;
          setCname(response.data.community);
          // console.log("Event Name recieved");
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [event?.community_id]);

  // Parse the ISO string as a date
  const startDate = new Date(event.event_start_date);

  // Format functions
  const formatDate = (date) => `${date.getDate()}`;
  const formatMonth = (date) =>
    date.toLocaleString("default", { month: "short" }).toUpperCase();
  const formatDay = (date) =>
    date.toLocaleString("default", { weekday: "long" }).toUpperCase();
  const formatTime = (time) => time; // Add your time formatting logic here

  const handleAttendees = (event) => {
    if (event?.attendees?.length) {
      toggleAttendees(event);
    }
  };

  return (
    <div className="flex w-full overflow-hidden bg-white rounded-lg shadow-md">
      <div className="flex flex-col items-center justify-between p-5 text-white bg-purple-600">
        <div className="flex flex-col items-center justify-center">
          <span className="text-6xl">{formatDate(startDate)}</span>
          <span className="text-lg">{formatDay(startDate)}</span>
          <span className="text-lg">{formatMonth(startDate)}</span>
        </div>
        <div style={{ fontSize: "0.8rem", textAlign: "center" }}>
          <div className="">views ({event?.event_views?.length})</div>
          <div
            className="cursor-pointer"
            onClick={() => handleAttendees(event)}
          >
            attendees ({event?.attendees?.length})
          </div>
        </div>
      </div>

      <div className="flex-grow p-4">
        <div className="mb-2 ">
          <h2 className="text-2xl font-bold">{event.title}</h2>
          <small>
            <b>Organizer:</b> {cname}
          </small>
        </div>

        <div className="flex items-center mb-4">
          <Icon path={mdiClockOutline} size={1} className="text-gray-700" />
          <span className="ml-2 text-gray-700">
            Time: {formatTime(event?.event_start_time)} to{" "}
            {formatTime(event?.event_end_time)}
          </span>
        </div>
        <div className="flex items-center mb-4">
          <Icon path={mdiTextBox} size={1} className="text-gray-700" />
          <span className="ml-2 text-gray-700">
            {event?.description?.slice(0, 60)}...
          </span>
        </div>
        <div className="flex items-center">
          <Icon path={mdiMapMarker} size={1} className="text-gray-700" />
          <span className="ml-2 text-gray-700">
            {event?.event_location}
          </span>{" "}
          {/* Replace with event.location if available */}
        </div>
        <div className="flex items-center">
          <Icon path={mdiMapMarker} size={1} className="text-gray-700" />
          <span className="ml-2 text-gray-700">{event?.event_type}</span>
          {/* Replace with event.location if available */}
        </div>
        <br />
        <div className="flex items-center">
          <Icon path={mdiHandExtended} size={1} className="text-gray-700" />{" "}
          &nbsp;&nbsp;Sponsorship Status
          {event?.sponsorship === "Open" ? (
            <span className="px-2 py-1 ml-2 text-xs text-white bg-green-400 rounded-lg text-bold">
              OPEN
            </span>
          ) : (
            <span className="px-2 py-1 ml-2 text-xs text-white bg-red-400 rounded-lg text-bold">
              CLOSED
            </span>
          )}
        </div>
        <br />
        <hr />
        <br />
        <div className="flex items-center">
          <Icon path={mdiAccountArrowLeft} size={1} className="text-gray-700" />
          <span className="ml-2 text-gray-700">{event?.contact_person}</span>
          {/* Replace with event.location if available */}
        </div>
        <div className="flex items-center">
          <Icon path={mdiPhoneClassic} size={1} className="text-gray-700" />
          <span className="ml-2 text-gray-700">{event?.phone}</span>
          {/* Replace with event.location if available */}
        </div>
        <div className="flex items-center">
          <Icon path={mdiEmailCheck} size={1} className="text-gray-700" />
          <span className="ml-2 text-gray-700">{event?.contact_email}</span>
          {/* Replace with event.location if available */}
        </div>

        <div className="flex items-center justify-end w-full">
          {/* View Event Details */}
          {event?.createdBy?._id === user?._id && (
            <button
              className="float-right px-4 py-2 mt-4 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
              onClick={() =>
                navigate("/communities/events/update", { state: event })
              }
            >
              <i className="mdi mdi-calendar-edit"></i>
            </button>
          )}
          {event?.createdBy?._id !== user?._id && (
            <button
              className="float-right px-4 py-2 mt-4 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
              onClick={() => rsvpEvent(event)}
            >
              RSVP
            </button>
          )}
          &nbsp;
          <button
            className="float-right px-4 py-2 mt-4 text-white bg-purple-500 rounded-md hover:bg-purple-600 focus:outline-none focus:bg-purple-600"
            onClick={() => toggleView(event)}
          >
            View
          </button>
        </div>
        {/* {JSON.stringify(event)} */}
      </div>
    </div>
  );
};

export default EventComponent;
