import React from "react";
import TimelineSection from "./TimelineSection";
import HighlightsSection from "./HighlightsSection";

//Global state Access
import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";
// import JsonFormatter from "../../../components/shared/JsonFormatter";

const CenterStageSection = () => {
  const [{ user }] = useAtom(gbState);
  return (
    <>
      {/* <div className="w-full">
        <TimelineSection />
      </div> */}
      <div className="w-2/3 ">
        <TimelineSection />
      </div>
      <div className="w-1/3">
        <HighlightsSection />
      </div>
    </>
  );
};

export default CenterStageSection;
