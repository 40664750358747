import React, { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import { DataGrid } from "@material-ui/data-grid";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";

//Global state Access
import { useAtom } from "jotai";
import { gbState } from "../../components/shared/GlobalState";

import Modal from "../../components/Modal/Modal";

const TimelinesListChild = () => {
  let navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});
  const [data, setData] = useState([]);
  const [{ errorLogApi }] = useAtom(gbState);
  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const getDataFromServer = () => {
    let userToken = localStorage.getItem("token");

    let urlLink = `${process.env.REACT_APP_API_URL}/timeline/`;

    const config = {
      method: "GET",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data.users));
        const oldData = response.data;
        console.log(oldData);
        const updatedData = oldData.map((data) => {
          return {
            ...data,
            id: data._id,
            message: data.message,
            post_type: data?.post_type,
          };
        });

        setData(updatedData);
      })
      .catch(function (err) {
        console.log("error api call ", err);
      });
  };

  useEffect(() => {
    const getDataFromServer = () => {
      let userToken = localStorage.getItem("token");

      let urlLink = `${process.env.REACT_APP_API_URL}/timeline/`;

      const config = {
        method: "GET",
        url: urlLink,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };
      axios(config)
        .then(function (response) {
          console.log(response.data);
          const oldData = response.data;
          const updatedData = oldData?.map((data) => {
            return {
              ...data,
              id: data._id,
              message: data.message,
              post_type: data?.post_type,
              role: data?.createdBy.role,
            };
          });

          setData(updatedData);
        })
        .catch(function (err) {
          console.log("error api call ", err);
        });
    };
    getDataFromServer();
  }, []);

  const handleDelete = (row) => {
    confirmAlert({
      title: "",
      message: "Are you sure to delete this User ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteRecord(row),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  const deleteRecord = (row) => {
    let userToken = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
    };
    axios
      .delete(`${process.env.REACT_APP_API_URL}/roles/delete/${row.id}`, config)
      .then((response) => {
        //alert(JSON.stringify(response));
        getDataFromServer();
      })
      .catch((err) => {
        errorLogApi(err, "UsersListChild", "/users");
      });
  };

  const columns = [
    {
      field: "Action",
      headerName: "Action",
      width: 120,
      sortable: false,
      hideable: false,
      renderCell: (params) => {
        return (
          <div className="flex flex-row">
            <Tooltip title="View details">
              <div
                onClick={() => {
                  toggleModal();
                  setCurrentRecord(params.row);
                }}
              >
                <i
                  className="cursor-pointer mdi mdi-eye-circle-outline"
                  style={{ fontSize: "15px", color: "black" }}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Delete">
              <div
                onClick={() => {
                  handleDelete(params.row);
                }}
              >
                <i
                  className="mdi mdi-trash-can-outline"
                  style={{ fontSize: "15px", color: "red", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Edit">
              <div
                onClick={() => navigate("/role/update", { state: params.row })}
              >
                <i
                  className="mdi mdi-square-edit-outline"
                  style={{ fontSize: "15px", color: "grey", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "message",
      headerName: "Message",
      width: 300,
    },
    {
      field: "role",
      headerName: "Role ID",
      width: 300,
    },
  ];

  const handleAddRole = () => {
    navigate("/roles/add");
  };

  return (
    <div className="w-full m-8">
      <div className="flex justify-end w-full">
        <button
          className="px-4 py-2 text-white bg-gray-600 rounded hover:bg-gray-900"
          onClick={() => navigate("/role/add")}
          // disabled
        >
          Add Roles
        </button>
      </div>
      <h2>Roles List</h2>
      {showModal && (
        <Modal onClose={toggleModal}>
          <h2 className="mb-4 text-xl font-bold">Role Detail</h2>
          {/* <RoleView currentRecord={currentRecord} /> */}
        </Modal>
      )}
      <div className="mt-4">
        <DataGrid
          rows={data}
          disableSelectionOnClick
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          displayTitle="Users"
          checkboxSelection
          style={{ height: "40vh" }}
        />
      </div>
    </div>
  );
};

export default TimelinesListChild;
