import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import CardBackground from "../../../components/MainTemplate/CardBackground";
// import postImage from "../../../images/t-10.jpg";
// import communityImage from "../../../images/community-2.jpg";
// import avatar from "../../../images/avatar-9.jpg";

//Global State using JOTAI
import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";
import CardScrollSection from "./CardScrollSection";
import ViewCommunitySection from "./ViewCommunitySection";
import EditCommunitySection from "./EditCommunitySection";

const TimelineSection = () => {
  return (
    <>
      <CardBackground>
        <MyCommunityComponent />
      </CardBackground>
    </>
  );
};

const MyCommunityComponent = () => {
  const [{ user }] = useAtom(gbState);
  const [communityList, setCommunityList] = useState([]);
  const [viewCommunity, setViewCommunity] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState(null);

  let navigate = useNavigate();
  const formImageData = new FormData();

  const fetchCommunity = () => {
    let userToken = localStorage.getItem("token");
    const config = {
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/community/${user._id}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    axios(config)
      .then(function (response) {
        const communities = response?.data?.communities;
        setCommunityList(communities);
      })
      .catch(function (error) {
        toast.error(error?.response?.data?.message);
      });
  };

  useEffect(() => {
    let userToken = localStorage.getItem("token");
    const config = {
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/community/${user?._id}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    if (user?._id) {
      axios(config)
        .then(function (response) {
          const communities = response?.data?.communities;
          setCommunityList(communities);
          setFormData(communities[0]);
        })
        .catch(function (error) {
          toast.error(error?.response?.data?.message);
        });
    }
  }, []);

  const setCommunityToFormData = () => {
    setFormData(viewCommunity);
    setEditMode(!editMode);
  };

  const handleSubmitUpdate = (e) => {
    console.log("Executed");
    //e.preventDefault();
    // Perform form submission or validation here
    if (!formData.community_name) {
      toast.error("Enter the Community Name");
      return;
    }

    let userToken = localStorage.getItem("token");
    const config = {
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/community/update`,
      headers: {
        Authorization: `Bearer ${userToken}`,
        //"Content-Type": "multipart/form-data", // Important when sending FormData
      },
      data: {
        ...formData,
      },
    };
    axios(config)
      .then(function (response) {
        toast.success(response?.data?.message);
        console.log("Update Done");
        //navigate("/community");
        setViewCommunity(formData);
        setEditMode(false);
        fetchCommunity();
      })
      .catch(function (error) {
        toast.error(error?.response?.data?.message);
      });
  };

  return (
    <div className="w-full" style={{ wordBreak: "break-word" }}>
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <i className="text-2xl text-blue-600 mdi mdi-account-group" />
          <div className="ml-3 ">
            <div className="text-md">
              My Communities ({communityList?.length})
            </div>
          </div>
        </div>
        {viewCommunity && (
          <div className="flex items-center">
            <div className="flex items-center justify-center w-8 h-8 bg-blue-200 rounded-full cursor-pointer">
              {!editMode && (
                <i
                  className="mdi mdi-account-edit"
                  onClick={setCommunityToFormData}
                />
              )}
              {editMode && (
                <i
                  className="mdi mdi-content-save"
                  onClick={handleSubmitUpdate}
                />
              )}
            </div>{" "}
            &nbsp;
            {!editMode ? (
              <button
                className="block w-8 h-8 text-black bg-gray-200 rounded-full text-small"
                onClick={() => {
                  setViewCommunity(null);
                  fetchCommunity();
                }}
              >
                X
              </button>
            ) : (
              <button
                className="block w-8 h-8 text-black bg-gray-200 rounded-full text-small"
                onClick={() => {
                  setFormData(null);
                  setEditMode(false);
                }}
              >
                X
              </button>
            )}
          </div>
        )}
      </div>
      <hr className="mt-4 mb-4" />
      <div className="w-full mt-4 ">
        {/* {JSON.stringify(communityList)} */}
        {editMode ? (
          <EditCommunitySection
            formData={formData}
            setFormData={setFormData}
            handleSubmitUpdate={handleSubmitUpdate}
            fetchCommunity={fetchCommunity}
          />
        ) : (
          <>
            {viewCommunity?.length > 0 ? (
              <ViewCommunitySection
                viewCommunity={viewCommunity}
                setViewCommunity={setViewCommunity}
              />
            ) : (
              <CardScrollSection
                communities={communityList}
                setViewCommunity={setViewCommunity}
              />
            )}
          </>
        )}
      </div>
      {/* {JSON.stringify(communityList)} */}
    </div>
  );
};

export default TimelineSection;
