import React from "react";
import AdminTemplate from "../../../components/AdminTemplate/AdminTemplate";

import UsersListChild from "./UsersListChild";

const UsersList = () => {
  return (
    <AdminTemplate>
      <UsersListChild />
    </AdminTemplate>
  );
};

export default UsersList;
