import { Outlet, Navigate } from "react-router-dom";
import { useAtom } from "jotai";
import { gbState } from "../../components/shared/GlobalState";
import jwt_decode from "jwt-decode";

const PrivateRoutes = (props) => {
  const [{ user }] = useAtom(gbState);
  const token = localStorage.getItem("token");

  // Validate token and decode user details
  let currentUserRole = null;
  if (token) {
    try {
      const usrDetails = jwt_decode(token);
      currentUserRole = usrDetails?.role;
    } catch (error) {
      console.error("Invalid token:", error);
      // If token is invalid, clear it and redirect to login
      localStorage.removeItem("token");
      return <Navigate to="/login" replace />;
    }
  } else {
    // If no token, redirect to login
    return <Navigate to="/login" replace />;
  }

  // Check if user has the required role
  if (props.roleRequired && !props.roleRequired.includes(currentUserRole)) {
    return <Navigate to="/error403" replace />;
  }

  return <Outlet />;
};

PrivateRoutes.defaultProps = {
  roleRequired: [
    "FULL_ACCESS",
    "COUNTRY_ADMIN",
    "REGIONAL_ADMIN",
    "COMMUNITY_ADMIN",
    "SUB_COMMUNITY_ADMIN",
    "GEN_MEMBER",
  ],
};

export default PrivateRoutes;
