import React, { useState, useEffect } from "react";
import Select from "react-select";
import axios from "axios";
import toast from "react-hot-toast";
import ReplyIcon from "@mui/icons-material/Reply";

//Global state Access
import { useAtom } from "jotai";
import { gbState } from "../../components/shared/GlobalState";
import ComposeMail from "./ComposeMail";
import ReplyMail from "./ReplyMail";
import Modal from "../../components/Modal/Modal";
import EventDetail from "./EventDetail";
import EventComponent from "./EventComponent";

const MessageApp = () => {
  const [{ user }] = useAtom(gbState);
  const [showModal, setShowModal] = useState(false);
  const [showReply, setShowReply] = useState(false);

  // Assuming the header height is 64px
  const [headerHeight, setHeaderHeight] = useState(100); // Default height
  const inputWidth = "230px";
  const [mailBox, setMailBox] = useState("inbox");

  const [currentRecord, setCurrentRecord] = useState();
  const [mails, setMails] = useState();

  const placeHolderText = (text) => (
    <span className="text-sm text-light">{text}</span>
  );

  const mailBoxOptions = [
    { value: "inbox", label: "Inbox" },
    { value: "sent", label: "Sent" },
  ];

  const toggleModal = (event) => {
    setShowModal(!showModal);
  };

  const toggleReplyModal = (event) => {
    setShowReply(!showReply);
  };

  useEffect(() => {
    setCurrentRecord(null);
    let userToken = localStorage.getItem("token");
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/email/${mailBox}/${user?._id}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    axios(config)
      .then(function (response) {
        console.log(response.data);
        setMails(response.data.emails);
        // console.log("Event Name recieved");
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [mailBox]);

  useEffect(() => {
    const handleResize = () => {
      // Adjust based on actual measurements or breakpoints
      if (window.innerWidth < 768) {
        setHeaderHeight(56); // Smaller header on smaller screens
      } else {
        setHeaderHeight(100); // Larger header on larger screens
      }
    };

    // Set up event listener
    window.addEventListener("resize", handleResize);
    handleResize(); // Set initial size

    // Clean up
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Sample data for demonstration

  const getTimeSincePost = (createdAt) => {
    // Parse the createdAt string into a Date object
    const createdDate = new Date(createdAt);
    // Get the current time as a Date object
    const now = new Date();

    // Calculate the difference in milliseconds
    const diffInMs = now - createdDate;
    // Convert milliseconds into days and weeks
    const days = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);

    // Same day: hours or minutes
    if (days < 1) {
      const hours = Math.floor(diffInMs / (1000 * 60 * 60));
      if (hours >= 1) {
        return `${hours} hour${hours > 1 ? "s" : ""} ago`;
      } else {
        const minutes = Math.floor(diffInMs / (1000 * 60));
        return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
      }
    }
    // Yesterday
    else if (days === 1) {
      return "Yesterday";
    }
    // 2-6 days ago
    else if (days < 7) {
      return `${days} days ago`;
    }
    // 1 week ago
    else if (weeks === 1) {
      return "1 week ago";
    }
    // 2-4 weeks ago
    else if (weeks <= 4) {
      return `${weeks} weeks ago`;
    }
    // 1 month ago or more
    else if (months >= 1) {
      return `${months} month${months > 1 ? "s" : ""} ago`;
    }
  };

  const users = mails?.map((m) => ({
    name: `${
      m.from_user?.first_name
        ? m.from_user?.first_name + " " + m.from_user?.last_name
        : m?.subject
    } `,
    handle: m?._id,
    from_user: m?.from_user?._id,
    email: m.from_user?.email,
    lastMessage: m?.subject,
    subject: m?.subject,
    message: m?.message,
    event_id: m?.event_id,
    time: getTimeSincePost(m?.createdAt),
  }));
  const users1 = [
    {
      name: "Phoenix Baker",
      handle: "@phoenix",
      lastMessage:
        "Hey Olivia, Katherine sent me over the latest doc. I just have a quick question about the...",
      time: "5min ago",
    },
    {
      name: "Katherine Moss",
      handle: "@kathy",
      lastMessage:
        "You: Sure thing, I'll have a look today. They're looking great!",
      time: "20min ago",
    },
    {
      name: "Mollie Hall",
      handle: "@mollie",
      lastMessage:
        "I've just published the site again. Looks like it fixed it. How weird! I'll keep an eye on it...",
      time: "1hr ago",
    },
    {
      name: "Phoenix Baker",
      handle: "@phoenix",
      lastMessage:
        "Hey Olivia, Katherine sent me over the latest doc. I just have a quick question about the...",
      time: "5min ago",
    },
    {
      name: "Katherine Moss",
      handle: "@kathy",
      lastMessage:
        "You: Sure thing, I'll have a look today. They're looking great!",
      time: "20min ago",
    },
    {
      name: "Mollie Hall",
      handle: "@mollie",
      lastMessage:
        "I've just published the site again. Looks like it fixed it. How weird! I'll keep an eye on it...",
      time: "1hr ago",
    },
    {
      name: "Phoenix Baker",
      handle: "@phoenix",
      lastMessage:
        "Hey Olivia, Katherine sent me over the latest doc. I just have a quick question about the...",
      time: "5min ago",
    },
    {
      name: "Katherine Moss",
      handle: "@kathy",
      lastMessage:
        "You: Sure thing, I'll have a look today. They're looking great!",
      time: "20min ago",
    },
    {
      name: "Mollie Hall",
      handle: "@mollie",
      lastMessage:
        "I've just published the site again. Looks like it fixed it. How weird! I'll keep an eye on it...",
      time: "1hr ago",
    },
    {
      name: "Phoenix Baker",
      handle: "@phoenix",
      lastMessage:
        "Hey Olivia, Katherine sent me over the latest doc. I just have a quick question about the...",
      time: "5min ago",
    },
    {
      name: "Katherine Moss",
      handle: "@kathy",
      lastMessage:
        "You: Sure thing, I'll have a look today. They're looking great!",
      time: "20min ago",
    },
    {
      name: "Mollie Hall",
      handle: "@mollie",
      lastMessage:
        "I've just published the site again. Looks like it fixed it. How weird! I'll keep an eye on it...",
      time: "1hr ago",
    },
    {
      name: "Phoenix Baker",
      handle: "@phoenix",
      lastMessage:
        "Hey Olivia, Katherine sent me over the latest doc. I just have a quick question about the...",
      time: "5min ago",
    },
    {
      name: "Katherine Moss",
      handle: "@kathy",
      lastMessage:
        "You: Sure thing, I'll have a look today. They're looking great!",
      time: "20min ago",
    },
    {
      name: "Mollie Hall",
      handle: "@mollie",
      lastMessage:
        "I've just published the site again. Looks like it fixed it. How weird! I'll keep an eye on it...",
      time: "1hr ago",
    },
    {
      name: "Phoenix Baker",
      handle: "@phoenix",
      lastMessage:
        "Hey Olivia, Katherine sent me over the latest doc. I just have a quick question about the...",
      time: "5min ago",
    },
    {
      name: "Katherine Moss",
      handle: "@kathy",
      lastMessage:
        "You: Sure thing, I'll have a look today. They're looking great!",
      time: "20min ago",
    },
    {
      name: "Mollie Hall",
      handle: "@mollie",
      lastMessage:
        "I've just published the site again. Looks like it fixed it. How weird! I'll keep an eye on it...",
      time: "1hr ago",
    },
  ];

  const messages = [
    {
      fromMe: false,
      text: "Hey Olivia, can you please review the latest design when you can?",
      time: "2:20pm",
    },
    {
      fromMe: true,
      text: "Sure thing, I’ll have a look today. They're looking great!",
      time: "Just now",
    },
    {
      fromMe: false,
      text: "Hey Olivia, can you please review the latest design when you can?",
      time: "2:20pm",
    },
    {
      fromMe: true,
      text: "Sure thing, I’ll have a look today. They're looking great!",
      time: "Just now",
    },
    {
      fromMe: false,
      text: "Hey Olivia, can you please review the latest design when you can?",
      time: "2:20pm",
    },
    {
      fromMe: true,
      text: "Sure thing, I’ll have a look today. They're looking great!",
      time: "Just now",
    },
    {
      fromMe: false,
      text: "Hey Olivia, can you please review the latest design when you can?",
      time: "2:20pm",
    },
    {
      fromMe: true,
      text: "Sure thing, I’ll have a look today. They're looking great!",
      time: "Just now",
    },
    {
      fromMe: false,
      text: "Hey Olivia, can you please review the latest design when you can?",
      time: "2:20pm",
    },
    {
      fromMe: true,
      text: "Sure thing, I’ll have a look today. They're looking great!",
      time: "Just now",
    },
    {
      fromMe: false,
      text: "Hey Olivia, can you please review the latest design when you can?",
      time: "2:20pm",
    },
    {
      fromMe: true,
      text: "Sure thing, I’ll have a look today. They're looking great!",
      time: "Just now",
    },
    {
      fromMe: false,
      text: "Hey Olivia, can you please review the latest design when you can?",
      time: "2:20pm",
    },
    {
      fromMe: true,
      text: "Sure thing, I’ll have a look today. They're looking great!",
      time: "Just now",
    },
    {
      fromMe: false,
      text: "Hey Olivia, can you please review the latest design when you can?",
      time: "2:20pm",
    },
    {
      fromMe: true,
      text: "Sure thing, I’ll have a look today. They're looking great!",
      time: "Just now",
    },
    {
      fromMe: false,
      text: "Hey Olivia, can you please review the latest design when you can?",
      time: "2:20pm",
    },
    {
      fromMe: true,
      text: "Sure thing, I’ll have a look today. They're looking great!",
      time: "Just now",
    },
  ];

  return (
    <div
      style={{ height: `calc(100vh - ${headerHeight}px)` }}
      className="flex bg-gray-100 w-[100%]"
    >
      {showModal && (
        <Modal onClose={toggleModal}>
          <h2 className="mb-4 text-xl font-bold">Compose Email</h2>
          <ComposeMail user={user} toggleModal={toggleModal} />
        </Modal>
      )}

      {showReply && (
        <Modal onClose={toggleReplyModal}>
          <h2 className="mb-4 text-xl font-bold">Reply Email</h2>
          <ReplyMail
            user={user}
            toggleReplyModal={toggleReplyModal}
            currentRecord={currentRecord}
          />
        </Modal>
      )}
      {/* Left sidebar */}
      <div className="w-1/4 overflow-y-auto bg-white">
        {/* Sticky Header */}
        <div
          className="flex flex-row p-4 bg-white shadow"
          style={{ position: "sticky", top: 0, zIndex: 10 }}
        >
          <i className="mr-2 text-2xl mdi mdi-email"></i>
          {/* <h2 className="text-xl font-bold">Inbox</h2> */}
          <Select
            name="mailBox"
            onChange={(selected, dropdown) => {
              setMailBox(selected?.value);
            }}
            options={mailBoxOptions}
            placeholder={placeHolderText("Select Type")}
            className={`w-[280px] outline-none h-11 `}
            style={{ zIndex: 15 }}
            // menuPortalTarget={document.body}
            menuPosition={"fixed"}
            value={mailBox && mailBoxOptions?.find((e) => e.value === mailBox)}
          />
        </div>
        {/* List of users/messages */}
        {users?.map((user, index) => (
          <div
            key={index}
            className="p-4 cursor-pointer hover:bg-gray-50"
            onClick={() => setCurrentRecord(user)}
          >
            <p className="font-semibold">{user.name}</p>
            <p className="text-sm text-gray-500">{user.lastMessage}</p>
            <p className="text-xs text-gray-400">{user.time}</p>
          </div>
        ))}
      </div>
      {/* Chat area */}
      <div className="flex flex-col flex-1 ml-1">
        <div className="flex flex-row justify-between p-5 bg-white shadow">
          <div className="flex flex-row">
            <i className="mr-2 text-2xl mdi mdi-email-open-outline"></i>
            <h2 className="text-xl font-bold">Messages</h2>
          </div>

          <div
            className="flex items-center justify-end cursor-pointer"
            onClick={toggleModal}
          >
            <i className="text-2xl mdi mdi-pencil-box-outline"></i>
          </div>
        </div>
        <div className="flex-1 p-2 overflow-y-auto">
          {currentRecord ? (
            <EmailMessageComponent
              msg={currentRecord}
              toggleReplyModal={toggleReplyModal}
            />
          ) : (
            <div className="flex flex-col w-full p-4 overflow-hidden bg-white rounded-lg shadow-md">
              Select the messages from left pane...
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

function EmailMessageComponent({ msg, toggleReplyModal }) {
  return (
    <>
      <div className="flex flex-col w-full p-4 overflow-hidden bg-white rounded-lg shadow-md">
        <div className="w-full px-4 pt-4">
          <div
            className="flex float-right cursor-pointer"
            style={{ color: "blue" }}
            onClick={() => toggleReplyModal()}
          >
            <ReplyIcon />
          </div>
          <h3 className="text-lg font-semibold text-gray-800">
            Subject: {msg?.subject}
          </h3>
          <p className="text-sm text-gray-600 ">From: {msg?.email}</p>
          <p className="text-sm text-gray-600 ">To: {msg?.recipient_email}</p>
          <p className={`mb-1 text-xs text-gray-600`} align="left">
            Date: {msg?.time}
          </p>
          <hr />
        </div>
        {/* Email Body - the actual message content */}
        <div className="w-full px-4 py-2">
          <p className="text-gray-800">{msg?.message}</p>
        </div>
      </div>

      <div className="w-full py-2">
        {/* <p className="text-gray-800">{msg?.event_id}</p> */}
        {msg?.event_id && <EventComponent event_id={msg.event_id} />}
      </div>
    </>
  );
}

export default MessageApp;
