import React, { useEffect, useState, useRef } from "react";
import Tooltip from "@mui/material/Tooltip";
import { DataGrid } from "@material-ui/data-grid";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";

//Global state Access
import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

import Modal from "../../../components/Modal/Modal";
import RoleView from "./RoleView";
import RoleAddChild from "../Create/RoleAddChild";
import RoleUpdateChild from "../Update/RoleUpdateChild";

const RoleListChild = () => {
  let navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showAddRoleModal, setShowAddRoleModal] = useState(false);
  const [showUpdateRoleModal, setShowUpdateRoleModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});
  const [data, setData] = useState([]);
  const [unfilteredData, setUnfilteredData] = useState([]);
  const searchRef = useRef();
  const [searchTerm, setSearchTerm] = useState("");

  const [{ errorLogApi }] = useAtom(gbState);
  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const viewCurrentRecord = (rec) => {
    setCurrentRecord(rec);
    toggleModal();
  };

  const updateCurrentRecord = (rec) => {
    setCurrentRecord(rec);
    setShowUpdateRoleModal(!showUpdateRoleModal);
  };

  const toggleAddRoleModal = () => {
    setShowAddRoleModal(!showAddRoleModal);
  };

  const toggleUpdateRoleModal = () => {
    setShowUpdateRoleModal(!showUpdateRoleModal);
  };

  const getDataFromServer = () => {
    let userToken = localStorage.getItem("token");

    let urlLink = `${process.env.REACT_APP_API_URL}/roles/`;

    const config = {
      method: "GET",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data.users));
        const oldData = response.data.roles;
        const updatedData = oldData.map((data) => {
          return {
            ...data,
            id: data._id,
            role_name: data.role_name,
          };
        });

        setData(updatedData);
      })
      .catch(function (err) {
        console.log("error api call ", err);
      });
  };

  useEffect(() => {
    const getDataFromServer = () => {
      let userToken = localStorage.getItem("token");

      let urlLink = `${process.env.REACT_APP_API_URL}/roles/`;

      const config = {
        method: "GET",
        url: urlLink,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };
      axios(config)
        .then(function (response) {
          console.log(response.data.roles);
          const oldData = response.data.roles;
          const updatedData = oldData.map((data) => {
            return {
              ...data,
              id: data._id,
              role_name: data.role_name,
            };
          });

          setData(updatedData);
        })
        .catch(function (err) {
          console.log("error api call ", err);
        });
    };
    getDataFromServer();
  }, []);

  const handleDelete = (row) => {
    confirmAlert({
      title: "",
      message: "Are you sure to delete this User ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteRecord(row),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  const deleteRecord = (row) => {
    let userToken = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
    };
    axios
      .delete(`${process.env.REACT_APP_API_URL}/roles/delete/${row.id}`, config)
      .then((response) => {
        //alert(JSON.stringify(response));
        getDataFromServer();
      })
      .catch((err) => {
        errorLogApi(err, "UsersListChild", "/users");
      });
  };

  const columns = [
    {
      field: "Action",
      headerName: "Action",
      width: 120,
      sortable: false,
      hideable: false,
      renderCell: (params) => {
        return (
          <div className="flex flex-row">
            <Tooltip title="View details">
              <div
                onClick={() => {
                  toggleModal();
                  setCurrentRecord(params.row);
                }}
              >
                <i
                  className="cursor-pointer mdi mdi-eye-circle-outline"
                  style={{ fontSize: "15px", color: "black" }}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Delete">
              <div
                onClick={() => {
                  handleDelete(params.row);
                }}
              >
                <i
                  className="mdi mdi-trash-can-outline"
                  style={{ fontSize: "15px", color: "red", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Edit">
              <div onClick={() => updateCurrentRecord(params.row)}>
                <i
                  className="mdi mdi-square-edit-outline"
                  style={{ fontSize: "15px", color: "grey", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "role_name",
      headerName: "Role Name",
      width: 300,
    },
    {
      field: "access_level",
      headerName: "Role ID",
      width: 300,
    },
  ];

  const handleAddRole = () => {
    navigate("/roles/add");
  };

  const handleSearch = (e) => {
    console.log("Search Value...", e);
    setSearchTerm(e);
    if (e) {
      setData(
        unfilteredData?.filter((item) => {
          return (
            item?.role_name?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.access_level?.toLowerCase().includes(e?.toLowerCase())
          );
        })
      );
    } else {
      getDataFromServer();
      setData(unfilteredData);
    }
  };

  const clearSearch = () => {
    searchRef.current.value = null;
    setSearchTerm("");
    getDataFromServer();
  };

  return (
    <div className="w-full m-8">
      <div className="flex justify-end w-full">
        <button
          className="px-4 py-2 text-white bg-gray-600 rounded hover:bg-gray-900"
          onClick={toggleAddRoleModal}
          // disabled
        >
          Add Roles
        </button>
      </div>
      <h2>Roles List</h2>
      {showModal && (
        <Modal onClose={toggleModal}>
          <h2 className="mb-4 text-xl font-bold">Role Detail</h2>
          <RoleView currentRecord={currentRecord} />
        </Modal>
      )}
      {showAddRoleModal && (
        <Modal onClose={toggleAddRoleModal}>
          <h2 className="mb-4 text-xl font-bold">Add Role</h2>
          <RoleAddChild
            getDataFromServer={getDataFromServer}
            setShowAddRoleModal={setShowAddRoleModal}
          />
        </Modal>
      )}
      {showUpdateRoleModal && (
        <Modal onClose={toggleUpdateRoleModal}>
          <h2 className="mb-4 text-xl font-bold">Add Role</h2>
          <RoleUpdateChild
            getDataFromServer={getDataFromServer}
            setShowUpdateRoleModal={setShowUpdateRoleModal}
            roleRecord={currentRecord}
          />
        </Modal>
      )}
      <div className="w-full mt-3">
        <div className="relative w-full mt-3">
          <input
            className="w-full p-3 pr-10 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
            name="search"
            type="text"
            ref={searchRef}
            placeholder="Enter search term"
            autoComplete="new-password"
            value={searchTerm}
            onChange={(e) => handleSearch(e.target.value)}
          />
          {searchTerm && (
            <button
              className="absolute transform -translate-y-1/2 top-1/2 right-3"
              onClick={clearSearch}
            >
              <svg
                className="w-4 h-4 text-gray-500"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>
          )}
        </div>
      </div>
      <div className="mt-4">
        <DataGrid
          rows={data}
          disableSelectionOnClick
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          displayTitle="Users"
          checkboxSelection
          style={{ height: "40vh" }}
        />
      </div>
    </div>
  );
};

export default RoleListChild;
