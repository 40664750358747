import React from "react";
import axios from "axios";
import toast from "react-hot-toast";

const DocumentUpload = ({
  formData,
  setFormData,
  handleChange,
  documentField,
}) => {
  // Function to handle immediate file upload and displaying the image
  const handleFileSelect = async (event) => {
    const file = event.target.files[0];
    if (file) {
      uploadDocument(file, formData?._id, documentField); // Update the displayed image (this function needs to be implemented)
    }
  };

  const uploadDocument = async (file, communityId, docField) => {
    const formData = new FormData();
    formData.append("docField", file);
    let userToken = localStorage.getItem("token");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/community/upload/document/${communityId}/${docField}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Assuming the server returns the path of the uploaded image
      setFormData((prevState) => ({
        ...prevState,
        [docField]: response.data.doc,
      }));
      toast.success(response.data.message);
    } catch (error) {
      console.error("Error uploading image:", error);
      // Handle error
    }
  };

  const downloadFile = () => {
    if (!formData[documentField]) return toast.error("No File exist");

    const fileUrl = `${process.env.REACT_APP_REPORTS_DOWNLOAD_URL}uploads/community/${formData[documentField]}`;

    // Create a new anchor element dynamically
    const anchor = document.createElement("a");
    anchor.href = fileUrl;
    anchor.target = "_blank"; // Opens the link in a new tab or window

    // Append the anchor to the body, trigger click, and remove it again
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  return (
    <div className="flex flex-row items-center p-4 bg-gray-100">
      <div className="w-full">
        <label
          htmlFor="community_registration_no"
          className="block mb-1 font-small"
        >
          Document Upload <span className="text-red-500">*</span>
        </label>
        <input
          type="file"
          id={documentField}
          name={documentField}
          onChange={handleFileSelect}
          accept="pdf/*"
          className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>
      <div className="flex justify-center w-full align-middle">
        <button
          onClick={downloadFile}
          className="px-3 py-1 text-sm text-white bg-gray-600 rounded hover:bg-gray-900"
          disabled={!downloadFile}
        >
          Download File
        </button>
      </div>
    </div>
  );
};

export default DocumentUpload;
