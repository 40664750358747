import React from "react";
import ReactPlayer from "react-player";

const VideoPlayer = ({ video }) => {
  return (
    <div className="w-full h-auto overflow-hidden relative pb-[56.25%]">
      <ReactPlayer
        url={`${process.env.REACT_APP_REPORTS_DOWNLOAD_URL}uploads/posts/${video}`}
        width="100%"
        height="100%"
        controls={true}
        muted={true}
        className="absolute top-0 left-0"
        crossOrigin="anonymous"
        config={{
          http: {
            headers: {
              "Cross-Origin-Opener-Policy": "same-origin",
              "Cross-Origin-Embedder-Policy": "require-corp",
            },
          },
        }}
      />
    </div>
  );
};

export default VideoPlayer;
