import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the styles

const RichTextEditor = ({ formData, setFormData }) => {
  const [value, setValue] = useState(formData?.about);

  const handleChange = (content) => {
    setValue(content);
    setFormData({ ...formData, about: content });
  };

  return (
    <div className="max-w-2xl p-2 mx-auto my-1 bg-white">
      <ReactQuill
        value={value}
        onChange={handleChange}
        theme="snow"
        className="h-64"
      />
    </div>
  );
};

export default RichTextEditor;
