import React, { useState } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import Select from "react-select";
import { useNavigate } from "react-router-dom";

import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

const RoleUpdateChild = ({
  roleRecord,
  setShowUpdateRoleModal,
  getDataFromServer,
}) => {
  let navigate = useNavigate();

  //Error Logging Service & Santize Input
  const [{ errorLogApi, sanitizeInput }] = useAtom(gbState);

  const [formData, setFormData] = useState({
    role_name: roleRecord?.role_name,
    access_level: roleRecord?.access_level,
  });

  const permissionOptions = [
    { value: "FULL_ACCESS", label: "Administrator" },
    { value: "CHURCH_ADMIN", label: "Church Admin" },
    { value: "CHURCH_MEMBERS", label: "Members" },
    { value: "EMPLOYEES", label: "Employees" },
    { value: "SUB_MEMBERS", label: "Sub Members" },
  ];

  const handleChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  const dropDownChange = (selected, dropdown) => {
    //console.log("this is selected", JSON.stringify(dropdown.name));
    setFormData({
      ...formData,
      [dropdown.name]: selected.value,
    });
  };

  const handleRoleRegistration = (e) => {
    e.preventDefault();

    if (!formData.access_level) {
      toast.error("Select Access Level");
      return;
    }

    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/roles/update/${roleRecord?._id}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        getDataFromServer();
        toast.success("Roles Update Successfully.");
        setShowUpdateRoleModal(false);
      })
      .catch(function (error) {
        //  toast.error(JSON.stringify(error.response.data.message));
        toast.error(JSON.stringify(error.response.data));
        errorLogApi(error, "CreateRoleChild", "/roles/update");
        // console.log(error);
      });
  };

  return (
    <div className="flex justify-center w-full">
      <div class="p-4 w-[400px]">
        {/* <h2 className="mb-3 text-bold">Update Role</h2> */}
        {/* {JSON.stringify(formData)} */}
        <form onSubmit={(e) => handleRoleRegistration(e)} autoComplete="off">
          <div className="mt-0 ">
            <label htmlFor="role_name">Role name</label>
            <input
              className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
              name="role_name"
              type="text"
              required
              placeholder="Enter Role Name"
              autoComplete="new-password"
              onChange={handleChange}
              value={formData?.role_name && formData?.role_name}
            />
          </div>
          <div className="mt-4 ">
            <label htmlFor="access_level">Access Level</label>
            <input
              className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
              name="access_level"
              type="text"
              required
              placeholder="Enter ACCESS LEVEL"
              autoComplete="new-password"
              onChange={handleChange}
              value={formData?.access_level && formData?.access_level}
              // disabled={true}
            />
          </div>
          {/* <div className="mt-4">
            <label htmlFor="access_level">Access Level</label>
            <Select
              name="access_level"
              onChange={dropDownChange}
              className="newPatientSelect"
              options={permissionOptions}
            />
          </div> */}
          <div className="flex justify-end w-full mt-4">
            <button
              className="px-4 py-2 text-white bg-gray-600 rounded hover:bg-gray-900"
              type="submit"
            >
              Update Role
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RoleUpdateChild;
