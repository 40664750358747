export const menuItems = [
  // { name: "Home", icon: "mdi-home-account", link: "/home" },
  { name: "Trends", icon: "mdi-trending-up", link: "#" },
  { name: "Video", icon: "mdi-video-outline", link: "#" },
  { name: "MyAccount", icon: "mdi-account", link: "#" },
  { name: "Shopping", icon: "mdi-shopping-outline", link: "#" },
];

export const community_type_options = [
  { value: 1, label: "LGBTQ+" },
  { value: 2, label: "Religious" },
  { value: 3, label: "Educational" },
  { value: 4, label: "Environmental" },
  { value: 5, label: "Cultural" },
  { value: 6, label: "Health and Wellness" },
  { value: 7, label: "Human Rights" },
  { value: 8, label: "Animal Welfare" },
  { value: 9, label: "Arts and Music" },
  { value: 10, label: "Sports and Recreation" },
  { value: 11, label: "Technology and Innovation" },
  { value: 12, label: "Entrepreneurship" },
  { value: 13, label: "Women's Empowerment" },
  { value: 14, label: "Youth Development" },
  { value: 15, label: "Senior Citizens" },
  { value: 16, label: "Disability Support" },
  { value: 17, label: "Veterans" },
  { value: 18, label: "Mental Health" },
  { value: 19, label: "Poverty Alleviation" },
  { value: 20, label: "Disaster Relief" },
];
