import React from "react";
import axios from "axios";
import toast from "react-hot-toast";
import placeHolderImage from "../../../images/placeholder_image.png";

const ImageUploadMember = ({
  title,
  memberImage,
  memberImageFieldName,
  memberFieldName,
  handleChange,
  formData,
  setFormData,
  // uploadImage, // New function to upload image
}) => {
  const uploadImage = async (file, communityId, imageField) => {
    console.log("uploadImage called");
    const formData = new FormData();
    formData.append("imageFile", file);
    let userToken = localStorage.getItem("token");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/community/upload/image/${communityId}/${imageField}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      // Assuming the server returns the path of the uploaded image
      // setFormData({ ...formData, [imageField]: response.data.image });
      setFormData((prevState) => ({
        ...prevState,
        [imageField]: response.data.image,
      }));
      toast.success(response.data.message);
      // console.log(formData);
    } catch (error) {
      console.error("Error uploading image:", error);
      // Handle error
    }
  };

  // Function to handle immediate file upload and displaying the image
  const handleFileSelect = async (event) => {
    const file = event.target.files[0];
    if (file) {
      // await handleFileChange(file, memberImageFieldName); // Upload the file
      await uploadImage(file, formData?._id, memberImageFieldName); // Update the displayed image (this function needs to be implemented)
    }
  };

  //console.log(memberImageFieldName);

  return (
    <fieldset className="p-2 whitespace-normal border w-100">
      <legend className="float-none w-auto p-2 fs-5">{title}</legend>
      <div className="flex mb-4">
        <div className="flex flex-row justify-between w-full">
          <div className="pl-4">
            {memberImage ? (
              <img
                src={`${process.env.REACT_APP_REPORTS_DOWNLOAD_URL}uploads/community/${memberImage}`}
                width="100px"
                crossOrigin="anonymous"
                alt="Image"
              />
            ) : (
              <img src={placeHolderImage} width="100px" alt="Placeholder" />
            )}
            <input
              type="file"
              name={memberImageFieldName}
              onChange={handleFileSelect}
              accept="image/*"
              style={{ display: "none" }}
              id={memberImageFieldName}
            />
            <button
              type="button" // Add this line
              onClick={() =>
                document.getElementById(memberImageFieldName).click()
              }
              className="px-3 py-1 mt-1 text-sm text-white bg-gray-600 rounded hover:bg-gray-900"
            >
              Choose File
            </button>
          </div>
          <div className="mr-4">
            <label htmlFor={memberFieldName} className="block mb-1 font-small">
              {title} Name <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              id={memberFieldName}
              name={memberFieldName}
              value={formData[memberFieldName]}
              onChange={handleChange}
              required
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </div>
      </div>
    </fieldset>
  );
};

export default ImageUploadMember;
