import React, { useEffect, useState } from "react";
import communityImage from "../../../images/community-2.jpg";
import avatar from "../../../images/avatar-9.jpg";
import placeHolderImage from "../../../images/placeholder_image.png";

import Modal from "../../../components/Modal/Modal";
import toast from "react-hot-toast";

const ViewCommunitySection = ({ viewCommunity, setViewCommunity }) => {
  const [card, setCard] = useState();
  const [modal, setModal] = useState(false);
  const [modalImage, setModalImage] = useState(false);
  const [modalData, setModalData] = useState();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setCard(viewCommunity);
  }, [viewCommunity]);

  const toggleImageModal = (imageData, name, title) => {
    if (!imageData) return toast.error("No Image Specified");
    // setModal(!modal);
    setModalImage(!modalImage);
    setModalData({ image: imageData, name, title });
  };

  const com_details = [
    { label: "Community Name", value: card?.community_name },
    { label: "Community Alias", value: card?.community_alias },
    { label: "Community Type", value: card?.community_type?.label },
    // { label: "Keyword", value: card?.keywords.toString() },
    { label: "President", value: card?.president_name },
    { label: "Vice President", value: card?.vice_president_name },
    { label: "Secretary", value: card?.secretary_name },
    { label: "Treasurer", value: card?.treasurer_name },
    { label: "Email", value: card?.secondary_email },
    { label: "Phone", value: card?.phone },
    {
      label: "Address",
      value: card?.address_line_2
        ? card?.address_line_1 + " " + card?.address_line_2
        : card?.address_line_1,
    },
    { label: "Country", value: card?.country?.label },
    { label: "City", value: card?.city?.label },
    { label: "State", value: card?.state?.label },
  ];

  //return <div className="">{JSON.stringify(com_details)}</div>;

  return (
    <div key={card?._id} className="">
      {modalImage && (
        <Modal onClose={toggleImageModal}>
          {modalData ? (
            <div className="mt-2">
              <div className="">
                <img
                  src={`${process.env.REACT_APP_REPORTS_DOWNLOAD_URL}uploads/community/${modalData?.image}`}
                  className="object-cover rounded-lg"
                  crossOrigin="anonymous"
                  alt="President"
                />
              </div>
              <div className="">
                <h2 className="text-2xl font-bold">{modalData?.title}</h2>
              </div>
              <div className="">
                <h2 className="text-xl font-bold">{modalData?.name}</h2>
              </div>
            </div>
          ) : (
            "No Image specified"
          )}
        </Modal>
      )}
      <div className="mt-4 ml-4 mr-4 overflow-hidden border border-gray-200 shadow sm:rounded-lg">
        <div className="px-4 py-2 bg-gray-100 sm:px-6">
          <h3 class="text-md leading-6 font-medium text-gray-900">About</h3>
        </div>
        {/* <p className="px-5 mt-4 mb-4 text-sm text-gray-500">{card?.about}</p> */}
        <div
          className="max-w-2xl p-4 mx-auto my-8 bg-white"
          dangerouslySetInnerHTML={{ __html: card?.about }}
        />
      </div>
      <div className="mt-4 ml-4 mr-4 overflow-hidden border border-gray-200 shadow sm:rounded-lg">
        <div className="px-4 py-2 bg-gray-50 sm:px-6">
          <h3 class="text-md leading-6 font-medium text-gray-900">
            Member Details
          </h3>
        </div>
        <div className="flex flex-row m-4 justify-evenly">
          <div
            className="text-center cursor-pointer"
            onClick={() =>
              toggleImageModal(
                card?.president_image,
                card?.president_name,
                "President"
              )
            }
          >
            {card?.president_image ? (
              <img
                src={`${process.env.REACT_APP_REPORTS_DOWNLOAD_URL}uploads/community/${card?.president_image}`}
                className="object-cover w-24 h-24"
                crossOrigin="anonymous"
                alt="President"
              />
            ) : (
              <img
                src={placeHolderImage}
                className="object-cover w-24 h-24"
                alt="Placeholder"
              />
            )}
            <p className="text-sm font-semibold">{card?.president_name}</p>
            <span className="text-xs text-gray-600">President</span>
          </div>

          <div
            className="text-center cursor-pointer"
            onClick={() =>
              toggleImageModal(
                card?.vice_president_image,
                card?.vice_president_name,
                "Vice President"
              )
            }
          >
            {card?.vice_president_image ? (
              <img
                src={`${process.env.REACT_APP_REPORTS_DOWNLOAD_URL}uploads/community/${card?.vice_president_image}`}
                className="object-cover w-24 h-24"
                crossOrigin="anonymous"
                alt="Vice President"
              />
            ) : (
              <img
                src={placeHolderImage}
                className="object-cover w-24 h-24"
                alt="Placeholder"
              />
            )}
            <p className="text-sm font-semibold">{card?.vice_president_name}</p>
            <span className="text-xs text-gray-600">Vice President</span>
          </div>
          <div
            className="text-center cursor-pointer"
            onClick={() =>
              toggleImageModal(
                card?.secretary_image,
                card?.secretary_name,
                "Secretary"
              )
            }
          >
            {card?.secretary_image ? (
              <img
                src={`${process.env.REACT_APP_REPORTS_DOWNLOAD_URL}uploads/community/${card?.secretary_image}`}
                className="object-cover w-24 h-24"
                crossOrigin="anonymous"
                alt="Secretary"
              />
            ) : (
              <img
                src={placeHolderImage}
                className="object-cover w-24 h-24"
                alt="Placeholder"
              />
            )}
            <p className="text-sm font-semibold">{card?.secretary_name}</p>
            <span className="text-xs text-gray-600">Secretary</span>
          </div>
          <div
            className="text-center cursor-pointer"
            onClick={() =>
              toggleImageModal(
                card?.treasurer_image,
                card?.treasurer_name,
                "Treasurer"
              )
            }
          >
            {card?.treasurer_image ? (
              <img
                src={`${process.env.REACT_APP_REPORTS_DOWNLOAD_URL}uploads/community/${card?.treasurer_image}`}
                className="object-cover w-24 h-24"
                crossOrigin="anonymous"
                alt="Treasurer"
              />
            ) : (
              <img
                src={placeHolderImage}
                className="object-cover w-24 h-24"
                alt="Placeholder"
              />
            )}
            <p className="text-sm font-semibold">{card?.treasurer_name}</p>
            <span className="text-xs text-gray-600">Treasurer</span>
          </div>
        </div>
      </div>

      <div className="mt-4 ml-4 mr-4 overflow-hidden border border-gray-200 shadow sm:rounded-lg">
        <div className="px-4 py-2 bg-gray-50 sm:px-6">
          <h3 class="text-md leading-6 font-medium text-gray-900">
            Community Details
          </h3>
        </div>
        <div className="bg-white border border-gray-200">
          <div className="px-4 py-5 sm:px-6">
            <div className="grid grid-cols-2 ">
              {com_details?.map((d, index) => (
                <>
                  <div
                    className={`px-6 py-1 ${
                      index % 2 === 0 ? "bg-gray-100" : "bg-white"
                    }`}
                  >
                    <div className="mt-1 text-sm font-medium leading-5 tracking-wider text-gray-800 uppercase">
                      {d?.label}
                    </div>
                  </div>
                  <div
                    className={`px-6 py-1 ${
                      index % 2 === 0 ? "bg-gray-100" : "bg-white"
                    }`}
                  >
                    <div className="mt-1 text-sm leading-5 text-gray-600">
                      {d?.value}
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* {JSON.stringify(card)} */}
    </div>
  );
};

export default ViewCommunitySection;
