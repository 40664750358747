import React, { useState } from "react";
import CardBackground from "../../../../components/MainTemplate/CardBackground";
import { useNavigate } from "react-router-dom";
import { DatePicker, TimePicker } from "antd";
import Icon from "@mdi/react";
import dayjs from "dayjs";
import Select from "react-select";
import axios from "axios";
import toast from "react-hot-toast";
import { confirmAlert } from "react-confirm-alert";
import {
  mdiCalendarClock,
  mdiMapMarker,
  mdiClockOutline,
  mdiTextBox,
} from "@mdi/js";

//Global state Access
import { useAtom } from "jotai";
import { gbState } from "../../../../components/shared/GlobalState";

const AddEventComponent = () => {
  let navigate = useNavigate();
  const [{ errorLogApi, user }] = useAtom(gbState);
  const inputWidth = "230px";
  const [formData, setFormData] = useState({
    title: null,
    description: null,
    day_event: null,
    event_start_date: null,
    event_end_date: null,
    event_start_time: null,
    event_end_time: null,
    online_event_link: null,
    event_type: "Physical",
    event_visibility: null,
    event_location: null,
    contact_person: null,
    phone: null,
    contact_email: null,
    sponsorship: "Open",
    total_number_tickets: null,
    image: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const eventTypeOptions = [
    { value: "Physical", label: "Physical Event" },
    { value: "Online", label: "Virtual Event" },
  ];

  const sponsorshipOptions = [
    { value: "Open", label: "Open" },
    { value: "Closed", label: "Closed" },
  ];

  const placeHolderText = (text) => (
    <span className="text-sm text-light">{text}</span>
  );

  function formatError(errors) {
    return errors.map((error) => {
      // Replace all underscores with spaces
      let formattedError = error.replace(/_/g, " ");

      // Capitalize the first character of the first word
      formattedError =
        formattedError.charAt(0).toUpperCase() + formattedError.slice(1);

      return formattedError;
    });
  }

  const validationFields = [
    "title",
    "description",
    "event_start_date",
    "event_end_date",
    "event_start_time",
    "event_end_time",
    "event_location",
    "event_type",
    "event_visibility",
  ];

  const handleEventSubmit = () => {
    // Perform form submission or validation here
    const error = validationFields.filter((field) => {
      if (formData[field] === null) return field;
      return null;
    });

    if (error?.length) {
      toast.error(`Enter values for ${formatError(error)}`, {
        className: "toast-word-wrap",
      });
      return;
    }

    confirmAlert({
      title: "",
      message: "Are you sure to save this Event ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => saveEventRecord(),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });

    const saveEventRecord = () => {
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/events/add`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        data: {
          ...formData,
        },
      };
      axios(config)
        .then(function (response) {
          toast.success(`Event added successfully!`);
          console.log(response);
          navigate("/home");
        })
        .catch(function (error) {
          toast.error(`Something went wrong ${JSON.stringify(error)}`);
        });
    };
  };

  const handleEventClear = () => {
    setFormData({
      title: "",
      description: "",
      day_event: null,
      event_start_date: null,
      event_end_date: null,
      event_start_time: null,
      event_end_time: null,
      online_event_link: null,
      event_type: "Physical",
      total_number_tickets: null,
      event_location: null,
      contact_person: null,
      phone: null,
      contact_email: null,
      sponsorship: "Open",
      image: null,
    });
  };

  const eventVisibilityOptions = [
    { value: 1, label: "Open to Public" },
    { value: 2, label: "Open to Public & admins" },
    { value: 3, label: "Open to Subscribers" },
    { value: 4, label: "Open to Subscribers & Admins" },
  ];

  return (
    <div className="w-full mt-1 overflow-hidden bg-white border border-white shadow sm:rounded-lg">
      <div className="px-4 py-2 bg-gray-100 sm:px-6">
        <h3 class="text-md leading-6 font-medium text-gray-900">
          <i className="mdi mdi-calendar-clock"></i> Add Events
        </h3>
      </div>
      <div className="m-4 bg-white">
        <div className="px-4 py-5 sm:px-6">
          <div className="w-full">
            <label htmlFor="keywords" className="block mb-1 font-small">
              Event Title
              <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              id="title"
              name="title"
              onChange={handleChange}
              required
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={formData.title && formData.title}
            />
          </div>
        </div>
        <div className="flex flex-col px-4 py-5 md:flex-row sm:px-6">
          <div className="flex w-full mr-1 md:flex-row">
            <div className="w-full mr-1">
              <label htmlFor="keywords" className="block mb-1 font-small">
                Event Start Date
                <span className="text-red-500">*</span>
              </label>
              <DatePicker
                className="h-[38px] w-full"
                name="event_start_date"
                onChange={(date, dateString) => {
                  setFormData({
                    ...formData,
                    event_start_date: dateString,
                    event_end_date: dateString,
                    event_start_time: "08:00 AM",
                    event_end_time: "05:00 PM",
                  });
                }}
                disabledDate={(current) => {
                  let customDate = dayjs().format("YYYY-MM-DD");
                  return current && current < dayjs(customDate, "YYYY-MM-DD");
                }}
                showToday={true}
              />
            </div>
            <div className="w-full ml-1 sm:py-4 md:py-0 md:ml-1">
              <label htmlFor="keywords" className="block mb-1 font-small">
                Event Start Time
                <span className="text-red-500">*</span>
              </label>
              <TimePicker
                className="h-[38px] w-full"
                name="event_start_time"
                onChange={(time, timeString) => {
                  setFormData({
                    ...formData,
                    event_start_time: timeString,
                  });
                }}
                format="hh:mm A"
                value={
                  formData?.event_start_time &&
                  dayjs(formData?.event_start_time, "hh:mm A")
                }
              />
            </div>
          </div>
          <div className="flex flex-row w-full mt-4 ml-1 md:mt-0">
            <div className="w-full">
              <label htmlFor="keywords" className="block mb-1 font-small">
                Event End Date
                {/* <span className="text-red-500">*</span> */}
              </label>
              <DatePicker
                className="h-[38px] w-full"
                name="event_end_date"
                onChange={(date, dateString) => {
                  setFormData({
                    ...formData,
                    event_end_date: dateString,
                  });
                }}
                disabledDate={(current) => {
                  //   let customDate = dayjs().format("YYYY-MM-DD");
                  let customDate = dayjs(
                    formData?.event_start_date,
                    "YYYY-MM-DD"
                  );
                  return current && current < customDate;
                }}
                showToday={true}
                value={
                  formData?.event_end_date &&
                  dayjs(formData?.event_end_date, "YYYY-MM-DD")
                }
              />
            </div>
            <div className="w-full ml-2">
              <label htmlFor="keywords" className="block mb-1 font-small">
                Event End Time
                {/* <span className="text-red-500">*</span> */}
              </label>
              <TimePicker
                className="h-[38px] w-full"
                name="event_end_time"
                onChange={(time, timeString) => {
                  setFormData({
                    ...formData,
                    event_end_time: timeString,
                  });
                }}
                format="hh:mm A"
                value={
                  formData?.event_end_time &&
                  dayjs(formData?.event_end_time, "hh:mm A")
                }
              />
            </div>
          </div>
        </div>
        <div className="px-4 py-5 sm:px-6">
          <div className="w-full">
            <label htmlFor="keywords" className="block mb-1 font-small">
              Event Detail
              <span className="text-red-500">*</span>
            </label>
            <textarea
              type="text"
              id="description"
              rows={5}
              name="description"
              value={formData.description}
              onChange={handleChange}
              required
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </div>
        <div className="flex flex-col px-4 py-3 sm:px-6 md:flex-row">
          <div className="w-full">
            <label htmlFor="keywords" className="block mb-1 font-small">
              Event Type
              <span className="text-red-500">*</span>
            </label>
            <Select
              name="event_type"
              onChange={(selected, dropdown) => {
                setFormData({
                  ...formData,
                  event_type: selected.value,
                });
              }}
              options={eventTypeOptions}
              placeholder={placeHolderText("Select Type")}
              className={`w-[${inputWidth}] outline-none h-11 `}
              menuPortalTarget={document.body}
              menuPosition={"fixed"}
              value={
                formData?.event_type &&
                eventTypeOptions.find((e) => e.value === formData?.event_type)
              }
            />
          </div>
          <div className="w-full mt-4 md:ml-2 md:mt-0">
            <label htmlFor="keywords" className="block mb-1 font-small">
              Event Link
              {/* <span className="text-red-500">*</span> */}
            </label>
            <input
              type="text"
              id="online_event_link"
              name="online_event_link"
              placeholder="Event URL (optional)"
              value={formData.online_event_link}
              onChange={handleChange}
              disabled={formData?.event_type === "Online" ? false : true}
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </div>

        <div className="px-4 py-5 sm:px-6">
          <div className="w-full">
            <label htmlFor="keywords" className="flex flex-row mb-1 font-small">
              <Icon path={mdiMapMarker} size={1} className="text-gray-700" />
              Event Location
              <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              id="title"
              name="event_location"
              onChange={handleChange}
              disabled={formData?.event_type !== "Physical"}
              required
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={formData.event_location && formData.event_location}
            />
          </div>
        </div>

        <div className="flex flex-col px-4 py-2 sm:px-6 md:flex-row">
          <div className="w-full">
            <label htmlFor="keywords" className="block mb-1 font-small">
              Event Visibility
              <span className="text-red-500">*</span>
            </label>
            <Select
              name="event_visibility"
              onChange={(selected, dropdown) => {
                setFormData({
                  ...formData,
                  event_visibility: selected,
                });
              }}
              options={eventVisibilityOptions}
              placeholder={placeHolderText("Select Type")}
              className={`w-[${inputWidth}] outline-none h-11 `}
              menuPortalTarget={document.body}
              menuPosition={"fixed"}
            />
          </div>
          <div className="w-full mt-4 md:ml-2 md:mt-0">
            <label htmlFor="keywords" className="block mb-1 font-small">
              Number of Tickets
              {/* <span className="text-red-500">*</span> */}
            </label>
            <input
              type="number"
              id="total_number_tickets"
              name="total_number_tickets"
              placeholder="Number of tickets"
              value={formData.total_number_tickets}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </div>
        <div className="flex flex-col px-4 py-2 sm:px-6 md:flex-row">
          <div className="w-full">
            <label htmlFor="keywords" className="block mb-1 font-small">
              Contact Person
              <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              id="contact_person"
              name="contact_person"
              placeholder="Contact Person"
              value={formData.contact_person}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="w-full mt-4 md:ml-2 md:mt-0">
            <label htmlFor="keywords" className="block mb-1 font-small">
              Enter Phone
              {/* <span className="text-red-500">*</span> */}
            </label>
            <input
              type="text"
              id="phone"
              name="phone"
              placeholder="Phone"
              value={formData.phone}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </div>
        <div className="flex flex-col px-4 py-2 sm:px-6 md:flex-row">
          <div className="w-full">
            <label htmlFor="keywords" className="block mb-1 font-small">
              Sponsorship Status
              <span className="text-red-500">*</span>
            </label>
            <Select
              name="sponsorship"
              onChange={(selected, dropdown) => {
                setFormData({
                  ...formData,
                  sponsorship: selected.value,
                });
              }}
              options={sponsorshipOptions}
              placeholder={placeHolderText("Select Type")}
              className={`w-[${inputWidth}] outline-none h-11 `}
              menuPortalTarget={document.body}
              menuPosition={"fixed"}
            />
          </div>
          <div className="w-full mt-4 md:ml-2 md:mt-0">
            <label htmlFor="keywords" className="block mb-1 font-small">
              Enter Email
              {/* <span className="text-red-500">*</span> */}
            </label>
            <input
              type="text"
              id="contact_email"
              name="contact_email"
              placeholder="Email"
              value={formData.contact_email}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </div>
      </div>

      <div className="mt-6 mb-10 mr-10 text-end">
        <button
          onClick={handleEventClear}
          className="px-4 py-2 mr-2 text-white bg-green-500 rounded-md hover:bg-green-600 focus:outline-none focus:bg-green-600"
        >
          Clear All
        </button>
        <button
          onClick={handleEventSubmit}
          className="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
        >
          <i className="mdi mdi-calendar-plus"></i> Add Event
        </button>
      </div>
    </div>
  );
};

export default AddEventComponent;
