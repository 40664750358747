import React, { useState, useRef, useEffect } from "react";
import backgroundImage from "../../images/social-background.png";
import fyndLogo from "../../images/fyn-community-logo.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import jwt_decode from "jwt-decode";
import ClientCaptcha from "react-client-captcha";
import { useAtom } from "jotai";
import {
  gbState,
  gbEventState,
  gbEventVisibilityState,
  gbMyEventsState,
} from "../../components/shared/GlobalState";

import Modal from "../../components/Modal/Modal";
import ForgotPasswordModal from "./ForgotPasswordModal";
const LoginComponent = () => {
  const bkImage = `url(${backgroundImage})`;
  let navigate = useNavigate();
  const [userStatus, setUserStatus] = useAtom(gbState);
  const [fpShowModal, setFPShowModal] = useState(false);
  const [, setEventsGlobal] = useAtom(gbEventState);
  const [, setShowEvents] = useAtom(gbEventVisibilityState);
  const [, setMyEvents] = useAtom(gbMyEventsState);
  const [state, setState] = useState({ captchaCode: "", valid: "a" });
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  );
  const [captchaWidth, setCaptchaWidth] = useState(80);

  const setCode = (captchaCode) => {
    setState({ ...state, captchaCode });
  };

  let usernameRef = useRef();
  let passwordRef = useRef();
  let captchaRef = useRef();

  const toggleFPModal = () => {
    setFPShowModal(!fpShowModal);
  };

  const checkLogin = async (email, password) => {
    const credentials = { email, password };
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/user/login`,
      credentials
    );

    return response;
  };

  const userRoles = [
    "GEN_MEMBER",
    "COUNTRY_ADMIN",
    "REGIONAL_ADMIN",
    "COMMUNITY_ADMIN",
    "SUB_COMMUNITY_ADMIN",
  ];

  const adminRole = ["FULL_ACCESS"];

  const handleLoginSubmit = async (e) => {
    if (!usernameRef.current.value) {
      toast.error("Unable to login, Enter Email ID");
      captchaRef.current.focus();
      return;
    }

    if (state.valid !== state.captchaCode) {
      toast.error("Unable to login, Bad Captcha");
      captchaRef.current.focus();
      return;
    }

    if (usernameRef.current.value && passwordRef.current.value) {
      try {
        const response = await checkLogin(
          usernameRef.current.value,
          passwordRef.current.value
        );
        localStorage.setItem("token", response.data.authToken);

        setInterval(checkToken, 1000 * 60 * 10);
        const user = jwt_decode(response.data.authToken);
        setUserStatus({
          ...userStatus,
          user: jwt_decode(response.data.authToken),
          handleLogout: () => {
            localStorage.removeItem("token");
            sessionStorage.removeItem("user-details");
            setUserStatus({ ...userStatus, user: null });
            navigate("/login", { replace: true });
          },
        });
        if (userRoles.includes(user.role)) {
          setEventsGlobal([]);
          setShowEvents(false);
          setMyEvents(false);
          navigate("/home", { replace: true });
        } else if (adminRole.includes(user.role)) {
          setEventsGlobal([]);
          setShowEvents(false);
          setMyEvents(false);
          navigate("/admin-dashboard", { replace: true });
        }

        toast.success("Logged in successfully");
      } catch (error) {
        toast.error("Unable to login, Bad username or password");
      }
    }
  };

  const checkToken = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwt_decode(token);
      const currentTime = Date.now() / 1000;
      if (decodedToken.exp < currentTime) {
        localStorage.removeItem("token");
        sessionStorage.removeItem("user-details");
        setUserStatus({ ...userStatus, user: null });
        navigate("/login", { replace: true });
      }
    }
  };

  // useEffect(() => {
  //   const loadScript = (src) => {
  //     return new Promise((resolve) => {
  //       const script = document.createElement("script");
  //       script.src = src;
  //       script.onload = resolve;
  //       document.body.appendChild(script);
  //     });
  //   };

  //   const setVanta = () => {
  //     if (window.VANTA) {
  //       window.VANTA.NET({
  //         el: ".vanta-bg",
  //         mouseControls: true,
  //         touchControls: true,
  //         gyroControls: false,
  //         minHeight: 200.0,
  //         minWidth: 200.0,
  //         scale: 1.0,
  //         scaleMobile: 1.0,
  //       });
  //     }
  //   };

  //   loadScript(
  //     "https://cdnjs.cloudflare.com/ajax/libs/three.js/r121/three.min.js"
  //   )
  //     .then(() =>
  //       loadScript(
  //         "https://cdn.jsdelivr.net/npm/vanta@latest/dist/vanta.net.min.js"
  //       )
  //     )
  //     .then(() => {
  //       setVanta();
  //     });

  //   window.addEventListener("resize", setVanta);

  //   return () => {
  //     window.removeEventListener("resize", setVanta);
  //   };
  // }, []);

  return (
    <div
      className="flex items-center justify-center min-h-screen bg-center bg-cover vanta-bg"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      {fpShowModal && (
        <Modal onClose={toggleFPModal}>
          <ForgotPasswordModal setShowModalForgotPass={setFPShowModal} />
        </Modal>
      )}
      <div className="w-full max-w-sm bg-white rounded-lg shadow-lg p-7 bg-colo">
        {/* <h2 className="mb-6 text-3xl font-bold text-center text-gray-800">
          Login
        </h2> */}
        <div className="flex items-center justify-center w-full mb-4">
          <img src={fyndLogo} width="250px" className="mx-auto rounded-lg" />
        </div>
        <div>
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block mb-2 text-sm font-bold text-gray-700"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600"
              placeholder="Enter your email"
              autoComplete="new-password"
              required
              ref={usernameRef}
            />
          </div>
          <div className="mb-6">
            <label
              htmlFor="password"
              className="block mb-2 text-sm font-bold text-gray-700"
            >
              Password
            </label>
            <input
              type="password"
              id="password"
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600"
              placeholder="******************"
              autoComplete="new-password"
              ref={passwordRef}
              required
            />
          </div>
          <div className="flex flex-row mb-6">
            <div className="w-2/3">
              <input
                autoComplete="new-password"
                id="captcha"
                name="catpctafynd"
                required
                type="text"
                className="w-full h-10 p-3 text-gray-900 bg-gray-100 border-none peer focus:outline-none"
                placeholder=" Enter Captcha"
                onChange={(e) => setState({ ...state, valid: e.target.value })}
                onKeyPress={(e) => e.key === "Enter" && handleLoginSubmit(e)}
                ref={captchaRef}
              />
            </div>
            <div>
              <ClientCaptcha captchaCode={setCode} width={captchaWidth} />
            </div>
          </div>
          <button
            onClick={handleLoginSubmit}
            className="w-full px-4 py-2 font-bold text-white transition duration-300 bg-purple-600 rounded-md hover:bg-purple-700"
          >
            Sign In
          </button>
        </div>
        <div className="mt-4 text-center">
          <a
            onClick={toggleFPModal}
            className="text-sm text-purple-600 cursor-pointer hover:underline"
          >
            Forgot password?
          </a>
        </div>
        <div className="flex flex-row justify-between mt-6">
          <div className="text-center">
            <p className="text-sm text-gray-600">
              <a
                onClick={() => navigate("/community/register")}
                className="text-purple-600 cursor-pointer hover:underline"
              >
                Community Registration?
              </a>
            </p>
          </div>
          <div className="text-center">
            <p className="text-sm text-gray-600">
              <a
                onClick={() => navigate("/signup")}
                className="text-purple-600 cursor-pointer hover:underline"
              >
                New User?
              </a>
            </p>
          </div>
        </div>
        <div className="text-[0.5rem] w-full text-center pt-2">
          Build 12202041011.1
        </div>
      </div>
    </div>
  );
};

export default LoginComponent;
