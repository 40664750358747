import React from "react";
import backgroundImage from "../../../images/community-registration.png";

import RegisterCommunityForm from "./RegisterCommunityForm";

const RegisterCommunity = () => {
  const bkImage = `url(${backgroundImage})`;

  return (
    <div className={`bg-cover bg-center h-screen`}>
      <div
        className="flex items-center justify-center h-screen min-h-screen py-6 bg-gray-100 bg-center bg-cover"
        style={{ backgroundImage: bkImage }}
      >
        <RegisterCommunityForm />
      </div>
    </div>
  );
};

export default RegisterCommunity;
