import React from "react";

const RoleView = ({ currentRecord }) => {
  return (
    <div className="w-full overflow-y-auto">
      <table className="w-full divide-y divide-gray-200">
        <tbody className="bg-white divide-y divide-gray-200">
          <tr key={currentRecord.role_name}>
            <td className="px-6 py-1 text-sm font-medium text-gray-900 whitespace-nowrap">
              {currentRecord.role_name}
            </td>
            <td className="px-6 py-1 text-sm text-gray-500 whitespace-nowrap">
              {currentRecord.access_level}
            </td>
          </tr>
          <tr key="createdAt">
            <td className="px-6 py-1 text-sm font-medium text-gray-900 whitespace-nowrap">
              CreatedAt
            </td>
            <td className="px-6 py-1 text-sm text-gray-500 whitespace-nowrap">
              {currentRecord.createdAt}
            </td>
          </tr>
          <tr key={currentRecord._id}>
            <td className="px-6 py-1 text-sm font-medium text-gray-900 whitespace-nowrap">
              id
            </td>
            <td className="px-6 py-1 text-sm text-gray-500 whitespace-nowrap">
              {currentRecord.id}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default RoleView;
