import React from "react";
import { NavLink } from "react-router-dom";

const MenuComponent = ({ menu }) => {
  return (
    <li key={menu?.name}>
      <NavLink
        to={menu?.link}
        className="block mt-4 mr-4 text-sm text-gray-600 cursor-pointer lg:inline-block lg:mt-0 hover:text-blue-500"
      >
        <div className="hidden md:inline">
          <div className="flex items-center justify-center w-12 h-12 bg-gray-100 rounded-full">
            <i className={`mdi ${menu?.icon} text-2xl`}></i>
          </div>
        </div>

        <span className="inline md:hidden">
          <div className="flex items-center">
            <div className="flex items-center justify-center w-12 h-12 bg-gray-100 rounded-full">
              <i className={`mdi ${menu?.icon} text-2xl`}></i>
            </div>
            <div className="ml-4">{menu?.name}</div>
          </div>
        </span>
      </NavLink>
    </li>
  );
};

export default MenuComponent;
