import React from "react";
import AdminTemplate from "../../../components/AdminTemplate/AdminTemplate";

import UserUpdateChild from "./UserUpdateChild";

const UserUpdate = () => {
  return (
    <AdminTemplate>
      <UserUpdateChild />
    </AdminTemplate>
  );
};

export default UserUpdate;
