import React from "react";
import AdminTemplate from "../../../components/AdminTemplate/AdminTemplate";
import RoleAddChild from "./RoleAddChild";

const RoleAdd = () => {
  return (
    <AdminTemplate>
      <RoleAddChild />
    </AdminTemplate>
  );
};

export default RoleAdd;
