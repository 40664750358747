import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import Select from "react-select";
import { useNavigate } from "react-router-dom";

import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

const UserUpdateChild = ({
  getDataFromServer,
  setShowUpdateUserModal,
  currentRecord,
}) => {
  let navigate = useNavigate();
  const inputWidth = "230px";
  const style = {
    control: (base) => ({
      ...base,
      border: "1px solid lightgray",
      // This line disable the blue border
      boxShadow: "none",
    }),
  };
  //Error Logging Service & Santize Input
  const [{ errorLogApi, user }] = useAtom(gbState);
  const [rolesOption, setRolesOption] = useState();
  const [formData, setFormData] = useState({
    first_name: currentRecord?.first_name,
    last_name: currentRecord?.last_name,
    mobile: currentRecord?.mobile,
    email: currentRecord?.email,
    password: null,
    confirm_password: null,
    role: currentRecord?.role,
    user_type: currentRecord?.user_type,
    account_status: currentRecord?._account_status,
    createdBy: user?._id,
  });

  const placeHolderText = (text) => (
    <span className="text-sm text-light">{text}</span>
  );

  useEffect(() => {
    let userToken = localStorage.getItem("token");
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/roles/`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      // data: { ...formData },
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        const roles = response.data.roles;
        setRolesOption(
          roles.map((role) => ({
            value: role.access_level,
            label: role.role_name,
          }))
        );
      })
      .catch(function (error) {
        toast.error(JSON.stringify(error.response.data));
        errorLogApi(error, "RolesList", "/roles");
      });
  }, []);

  const handleChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  const handleUpdateUserRegistration = (e) => {
    e.preventDefault();
    if (formData.password) {
      if (formData.password !== formData.confirm_password) {
        toast.error("Passwords do not match");
        return;
      }
    }

    if (!formData?.role) return toast.error("Please select a role");

    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/user/update/${currentRecord?._id}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        getDataFromServer();
        toast.success("User Created Successfully.");
        setShowUpdateUserModal(false);
        // navigate("/users");
      })
      .catch(function (error) {
        //  toast.error(JSON.stringify(error.response.data.message));
        toast.error(JSON.stringify(error.response.data));
        errorLogApi(error, "CreateUserChild", "/user/register");
        // console.log(error);
      });
  };

  return (
    <div className="flex justify-center w-full">
      <div class="p-0 w-[400px]" style={{ wordBreak: "break-word" }}>
        {/* {JSON.stringify(formData)} */}
        <form
          onSubmit={(e) => handleUpdateUserRegistration(e)}
          autoComplete="off"
        >
          <div className="flex flex-row w-full">
            <div className="w-full m-1">
              <input
                className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                name="first_name"
                type="text"
                required
                placeholder="Enter First Name"
                autoComplete="new-password"
                onChange={handleChange}
                value={formData.first_name}
              />
            </div>
            <div className="w-full m-1">
              <input
                className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                name="last_name"
                type="text"
                placeholder="Enter Last Name"
                autoComplete="new-password"
                onChange={handleChange}
                value={formData.last_name}
              />
            </div>
          </div>

          <div className="m-1 overflow-x-auto outline-none">
            <Select
              name="role"
              onChange={(selected, dropdown) => {
                setFormData({
                  ...formData,
                  role: selected.value,
                  user_type: selected.label,
                });
              }}
              options={rolesOption}
              placeholder={placeHolderText("Select Role")}
              className={`w-[${inputWidth}] outline-none h-11 mt-1`}
              // menuPortalTarget={document.body}
              menuPosition={"fixed"}
              //styles={style}
              value={
                formData?.role &&
                rolesOption?.find((rol) => rol.value === formData.role)
              }
            />
          </div>
          <div className="m-1 mt-3 ">
            <input
              className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
              name="mobile"
              type="text"
              required
              placeholder="Enter Mobile"
              autoComplete="new-password"
              onChange={handleChange}
              value={formData.mobile}
            />
          </div>
          <div className="m-1 mt-3">
            <input
              className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
              name="email"
              type="email"
              required
              placeholder="Enter Email"
              autoComplete="new-password"
              onChange={handleChange}
              value={formData.email}
            />
          </div>
          <div className="flex flex-row mt-3">
            <div className="w-full m-1">
              <input
                className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                name="password"
                type="password"
                placeholder="Enter Password"
                autoComplete="new-password"
                onChange={handleChange}
                value={formData.password}
              />
            </div>
            <div className="w-full m-1 ">
              <input
                className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                name="confirm_password"
                type="password"
                placeholder="Confirm Password"
                autoComplete="new-password"
                onChange={handleChange}
                value={formData.confirm_password}
              />
            </div>
          </div>
          <div className="flex justify-end w-full mt-2">
            <button
              className="px-4 py-2 mb-2 text-white bg-blue-600 rounded hover:bg-blue-900"
              type="submit"
            >
              Update
            </button>
          </div>
        </form>
        {/* {JSON.stringify(formData)} */}
      </div>
    </div>
  );
};

export default UserUpdateChild;
