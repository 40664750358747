import React, { useState, useRef } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import CardScrollSection from "./CardScrollSection";
import ViewCommunitySection from "./ViewCommunitySection";
import CardBackground from "../../../components/MainTemplate/CardBackground";

const TimelineSection = () => {
  return (
    <>
      <CardBackground>
        <SearchCommunity />
      </CardBackground>
    </>
  );
};

const SearchCommunity = () => {
  let navigate = useNavigate();
  const [results, setResults] = useState();
  const [viewCommunity, setViewCommunity] = useState(null);
  const searchRef = useRef();

  const searchCommunities = () => {
    const search = searchRef.current.value;
    if (!search) {
      toast.error("Please enter search phrase");
      return;
    }

    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/community/search`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { query: search },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response?.data));
        setViewCommunity(null);
        setResults(response?.data?.communities);
      })
      .catch(function (error) {
        //  toast.error(JSON.stringify(error.response.data.message));
        toast.error(error?.response?.data?.message);
        //errorLogApi(error, "CreateUserChild", "/user/register");
        // console.log(error);
      });
  };

  return (
    <div className="w-full" style={{ wordBreak: "break-word" }}>
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <i className="text-2xl text-blue-600 mdi mdi-account-search" />
          <div className="ml-3">
            <div className="text-md">
              Search Communities {results && `(${results?.length})`}
            </div>
          </div>
        </div>
        <div className="flex items-center">
          {viewCommunity && (
            <div className="flex items-center justify-center w-8 h-8 bg-blue-200 rounded-full cursor-pointer">
              <button
                className="block w-8 h-8 text-black bg-gray-200 rounded-full text-small"
                onClick={() => {
                  setViewCommunity(null);
                }}
              >
                X
              </button>
            </div>
          )}
        </div>
      </div>
      <hr className="mt-4 mb-4" />
      <div className="mt-8">
        <div className="items-center mb-10">
          <div className="flex w-full">
            <div className="relative w-5/6">
              <input
                type="text"
                id="community_name"
                name="community_name"
                placeholder="Search Community by name or keywords"
                required
                ref={searchRef}
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:none focus:ring-blue-500"
              />
              <div className="absolute transform -translate-y-1/2 right-3 top-1/2">
                {searchRef?.current?.value && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5 text-gray-400 cursor-pointer"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    onClick={() => {
                      // Clear the input field logic here
                      searchRef.current.value = "";
                    }}
                  >
                    <path
                      fillRule="evenodd"
                      d="M15.354 4.646a.5.5 0 01.708.708L10.707 10l5.355 5.354a.5.5 0 11-.708.708L10 10.707l-5.354 5.355a.5.5 0 01-.708-.708L9.293 10 3.939 4.646a.5.5 0 11.708-.708L10 9.293l5.354-5.355z"
                      clipRule="evenodd"
                    />
                  </svg>
                )}
              </div>
            </div>
            &nbsp;
            <div className="w-1/6">
              <button
                onClick={searchCommunities}
                className="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
              >
                Search
              </button>
            </div>
          </div>
          {/* {JSON.stringify(results)} */}
          <div className="mt-4">
            {viewCommunity ? (
              <ViewCommunitySection
                viewCommunity={viewCommunity}
                setViewCommunity={setViewCommunity}
              />
            ) : (
              results && (
                <CardScrollSection
                  communities={results}
                  setViewCommunity={setViewCommunity}
                />
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimelineSection;
