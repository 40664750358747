import React, { useState, useEffect } from "react";
import axios from "axios";

import MainTemplate from "../../components/MainTemplate/MainTemplate";
import CenterStageSection from "./CenterStageSection";
import TimelineSection from "./TimelineSection";
import ContextMenuSection from "../../components/ContextMenuSection/ContextMenuSection";

//Global State using JOTAI
import { useAtom } from "jotai";
import { gbState } from "../../components/shared/GlobalState";

const HomePage = () => {
  const [{ user }] = useAtom(gbState);
  const [data, setData] = useState();

  //console.log(user);

  const fetchTimeLineData = () => {
    let userToken = localStorage.getItem("token");
    let urlLink = `${process.env.REACT_APP_API_URL}/timeline/community/${user?.community_id}`;
    const config = {
      method: "GET",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    axios(config)
      .then((response) => {
        const timelinesData = response.data.timelines;
        setData(timelinesData);
        console.log(timelinesData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    console.log("useEffect to load timelines called");

    const fetchTimeLineDataForCommunityAdmin = () => {
      let userToken = localStorage.getItem("token");
      let urlLink = `${process.env.REACT_APP_API_URL}/timeline/community/${user?.community_id}`;
      const config = {
        method: "GET",
        url: urlLink,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };
      console.log(user);
      axios(config)
        .then((response) => {
          const timelinesData = response.data.timelines;
          setData(timelinesData);
          console.log(timelinesData);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const fetchTimeLineDataForGenMember = () => {
      console.log("Timeline called for general member");
      let userToken = localStorage.getItem("token");
      // Assuming user?.following is an array of community IDs the user follows

      let urlLink = `${process.env.REACT_APP_API_URL}/timeline/community/timelines`;

      const config = {
        method: "post", // Changed to 'get' to match the updated backend route
        url: urlLink,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        data: { community_ids: user?.following },
      };

      axios(config)
        .then((response) => {
          const timelinesData = response.data.timelines;
          setData(timelinesData); // Assuming setData is a state setter from useState
          // console.log(timelinesData); // Optionally, log the fetched timelines data to the console for debugging
        })
        .catch((error) => {
          console.error(error); // Use console.error for error logging
        });
    };

    if (user?.community_id) {
      fetchTimeLineDataForCommunityAdmin();
    } else if (user?.role === "GEN_MEMBER") {
      fetchTimeLineDataForGenMember();
    }
  }, []);

  return (
    <MainTemplate>
      <div className="flex flex-grow bg-gray-100">
        <div className="hidden w-1/5 md:block">
          {/* overflow-y-hidden */}
          <div className="sticky top-0 h-screen overflow-y-hidden ">
            <ContextMenuSection />
          </div>
        </div>
        <div className="w-full md:hidden">
          <TimelineSection data={data} />
        </div>

        <div className="hidden md:flex md:w-4/5">
          <div className="flex w-full overflow-y-auto">
            <CenterStageSection
              data={data}
              fetchTimeLineData={fetchTimeLineData}
            />
          </div>
        </div>
      </div>
      <div className="fixed bottom-0 left-0 right-0 p-4 text-center bg-gray-200 md:hidden">
        This div is visible only on small screens .
      </div>
    </MainTemplate>
  );
};

export default HomePage;
