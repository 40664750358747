import React from "react";
import HighlightsSection from "./HighlightsSection";
import UpdateEventComponent from "./UpdateEventComponent";

const CenterStageSection = ({ selectedEvent }) => {
  return (
    <>
      {/* <div className="w-full">
        <TimelineSection data={data} fetchTimeLineData={fetchTimeLineData} />
      </div> */}
      <div className="w-2/3">
        <UpdateEventComponent selectedEvent={selectedEvent} />
      </div>
      <div className="w-1/3">
        <HighlightsSection />
      </div>
    </>
  );
};

export default CenterStageSection;
