import React from "react";
import { Routes, Route } from "react-router-dom";

import PrivateRoutes from "../../components/Auth/PrivateRoutes";
import HomePage from "../../pages/Home/HomePage";
import LoginPage from "../../pages/Login/LoginPage";
import Unauthorised from "../../pages/404/Unauthorised";
import Page403 from "../../pages/403/Page403";
import Page404 from "../../pages/404/Page404";
import Signup from "../../pages/Signup/Signup";
import RegisterCommunity from "../../pages/Community/RegisterCommunity/RegisterCommunity";
import MyCommunities from "../../pages/Community/MyCommunities/MyCommunities";
import SearchCommunity from "../../pages/Community/SearchCommunity/SearchCommunity";
import UsersList from "../../pages/Users/List/UsersList";
import MyAccount from "../../pages/Users/List/MyAccount";
import RoleList from "../../pages/Role/List/RoleList";
import UserAdd from "../../pages/Users/Create/UserAdd";
import UserUpdate from "../../pages/Users/Update/UserUpdate";
import CommunityList from "../../pages/Community/List/CommunityList";
import RoleAdd from "../../pages/Role/Create/RoleAdd";
import RoleUpdate from "../../pages/Role/Update/RoleUpdate";
import FollowedCommunities from "../../pages/Community/FollowerCommunities/FollowedCommunities";
import AddCommunityEvent from "../../pages/Community/CommunityEvents/Create/AddCommunityEvent";
import UpdateCommunityEvent from "../../pages/Community/CommunityEvents/Update/UpdateCommunityEvent";
import MessagePage from "../../pages/Messages/MessagePage";
import AdminDashboard from "../../pages/AdminDashboard/AdminDashboard";
import TimelinesList from "../../pages/Timelines/TimelinesList";
import EventsList from "../../pages/Events/EventsList";

const ProtectedRoutes = () => {
  return (
    <Routes>
      <Route
        path="*"
        name="Page not found"
        element={
          <Unauthorised mesg="404 error, Path not resolved / page not found." />
        }
      />
      <Route path="/login" name="Login" element={<LoginPage />} />
      <Route path="/" name="Login" element={<LoginPage />} />
      <Route path="/signup" name="Sign Up" element={<Signup />} />
      <Route
        path="/community/register"
        name="Register Community"
        element={<RegisterCommunity />}
      />
      <Route
        path="/error404"
        name="Error: Page not found"
        element={<Unauthorised />}
      />
      <Route element={<PrivateRoutes />}>
        <Route
          path="*"
          name="Page not found"
          element={
            <Unauthorised mesg="404 error, Path not resolved / page not found." />
          }
        />
        <Route
          path="/error404"
          name="Error: Page not found"
          element={<Page404 />}
        />
        <Route
          path="/error403"
          name="Error: Permission denied"
          element={<Page403 />}
        />
        <Route path="/home" name="Home" element={<HomePage />} />
        {/* <Route
          path="/community/register"
          name="Register Community"
          element={<RegisterCommunity />}
        /> */}
        <Route
          path="/community"
          name="My Communities"
          element={<MyCommunities />}
        />
        <Route
          path="/community/search"
          name="Search Communities"
          element={<SearchCommunity />}
        />
        <Route
          path="/communities/follow"
          name="Followed Communities"
          element={<FollowedCommunities />}
        />
        <Route
          path="/communities/events/add"
          name="Add Community Events"
          element={<AddCommunityEvent />}
        />
        <Route
          path="/communities/events/update"
          name="Add Community Events"
          element={<UpdateCommunityEvent />}
        />
        <Route path="/users" name="Users List" element={<UsersList />} />
        <Route path="/myaccount" name="My Account" element={<MyAccount />} />
        <Route path="/roles" name="Roles" element={<RoleList />} />
        <Route path="/role/add" name="Add Role" element={<RoleAdd />} />
        <Route
          path="/role/update"
          name="Update Role"
          element={<RoleUpdate />}
        />
        <Route
          path="/community/list"
          name="Community List"
          element={<CommunityList />}
        />
        {/* Messages */}
        <Route path="/messages" name="Messages" element={<MessagePage />} />
        <Route path="/user/add" name="User Add" element={<UserAdd />} />
        <Route path="/user/update" name="User Edit" element={<UserUpdate />} />
        {/* <Route element={<PrivateRoutes roleRequired={["FULL_ACCESS"]} />}>
          <Route path="/home" name="Home" element={<HomePage />} />
        </Route> */}
      </Route>
      <Route
        element={
          <PrivateRoutes
            roleRequired={[
              "FULL_ACCESS",
              "COMMUNITY_ADMIN",
              "SUB_COMMUNITY_ADMIN",
              "REGIONAL_ADMIN",
            ]}
          />
        }
      >
        <Route
          path="/admin-dashboard"
          name="Admin Dashboard"
          element={<AdminDashboard />}
        />
        <Route
          path="/timelines"
          name="Timelines Dashboard"
          element={<TimelinesList />}
        />
        <Route
          path="/events-all"
          name="Events Dashboard"
          element={<EventsList />}
        />
        {/* routes for community admin */}
      </Route>
    </Routes>
  );
};

export default ProtectedRoutes;
