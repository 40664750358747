import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// import { Calendar, Badge } from "antd";
// import dayjs from "dayjs";
import axios from "axios";
import toast from "react-hot-toast";

import { useAtom } from "jotai";
import { gbState } from "../../components/shared/GlobalState";

import EventComponent from "./EventComponent";

const EventListComponent = ({ toggleView, rsvpEvent, toggleAttendees }) => {
  let navigate = useNavigate();
  const location = useLocation();

  const [{ user }] = useAtom(gbState);
  const [eventsData, setEventsData] = useState();

  useEffect(() => {
    // console.log("event calender function called");
    let userToken = localStorage.getItem("token");
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/events/admin-events/${user?._id}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    axios(config)
      .then(function (response) {
        // console.log(response?.data);
        setEventsData(response?.data?.events);
      })
      .catch(function (error) {
        toast.error(error?.response?.data?.message);
      });
  }, []);

  return (
    <div>
      {eventsData?.map((event) => (
        <div className="p-1">
          <EventComponent
            event={event}
            toggleView={toggleView}
            rsvpEvent={rsvpEvent}
            toggleAttendees={toggleAttendees}
          />
        </div>
      ))}
    </div>
  );
};

export default EventListComponent;
