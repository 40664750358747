import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import ClientCaptcha from "react-client-captcha";

const SignupCard = () => {
  let navigate = useNavigate();
  const [formData, setFormData] = useState({
    first_name: null,
    last_name: null,
    mobile: null,
    email: null,
    password: null,
    confirm_password: null,
    role: "GEN_MEMBER",
    user_type: "Member",
    account_status: true,
  });
  const [captchaWidth, setCaptchaWidth] = useState(140);
  const [state, setState] = useState({ captchaCode: "", valid: "a" });
  const setCode = (captchaCode) => {
    setState({ ...state, captchaCode });
  };
  let captchaRef = useRef();

  const handleChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  const handleUserRegistration = (e) => {
    e.preventDefault();

    if (state.valid !== state.captchaCode) {
      toast.error("Unable to Signup, Bad Captcha");
      captchaRef.current.focus();
      return;
    }

    if (!formData.password) {
      toast.error("Please enter password");
      return;
    }
    if (formData.password !== formData.confirm_password) {
      toast.error("Passwords do not match");
      return;
    }

    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/user/register`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        toast.success("User Created Successfully.");
        navigate("/login");
      })
      .catch(function (error) {
        //  toast.error(JSON.stringify(error.response.data.message));
        toast.error(JSON.stringify(error.response.data));
        //errorLogApi(error, "CreateUserChild", "/user/register");
        // console.log(error);
      });
  };

  return (
    <div className="flex items-center justify-center h-screen bg-transparent">
      <div className="md:w-[600px] md:h-[630px] p-8 bg-white rounded-lg shadow-lg bg-opacity-60">
        <h2 className="mb-4 text-2xl font-bold">Sign Up</h2>
        <form onSubmit={handleUserRegistration}>
          <div className="flex flex-wrap mb-4">
            <div className="w-full md:w-1/2 md:pr-2">
              <label htmlFor="first_name" className="block mb-2 font-semibold">
                First Name
              </label>
              <input
                type="text"
                name="first_name"
                onChange={handleChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-md outline-none focus:outline-none focus:ring focus:ring-blue-300"
                required
              />
            </div>
            <div className="w-full mt-4 md:w-1/2 md:pl-2 md:mt-0">
              <label htmlFor="last_name" className="block mb-2 font-semibold">
                Last Name
              </label>
              <input
                type="text"
                name="last_name"
                onChange={handleChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                required
              />
            </div>
          </div>
          <div className="mb-4">
            <label htmlFor="mobile" className="block mb-2 font-semibold">
              Mobile Number
            </label>
            <input
              type="text"
              name="mobile"
              onChange={handleChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-300"
              autoComplete="new-password"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="block mb-2 font-semibold">
              Email
            </label>
            <input
              type="email"
              name="email"
              onChange={handleChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-300"
              autoComplete="new-password"
              required
            />
          </div>
          <div className="flex flex-wrap mb-4">
            <div className="w-full md:w-1/2 md:pr-2">
              <label htmlFor="password" className="block mb-2 font-semibold">
                Password
              </label>
              <input
                type="password"
                name="password"
                onChange={handleChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                required
                autoComplete="new-password"
              />
            </div>
            <div className="w-full mt-4 md:w-1/2 md:pl-2 md:mt-0">
              <label
                htmlFor="confirm_password"
                className="block mb-2 font-semibold"
              >
                Confirm Password
              </label>
              <input
                type="password"
                name="confirm_password"
                onChange={handleChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                required
                autoComplete="new-password"
              />
            </div>
          </div>
          <div className="relative mb-4">
            <div className="flex">
              <div className="w-2/3">
                <label htmlFor="captcha" className="block mb-2 font-semibold">
                  Captcha
                </label>
                <input
                  autoComplete="new-password"
                  id="captcha"
                  name="catpctafynd"
                  type="text"
                  className="w-full h-10 text-gray-900 bg-gray-100 border-none rounded-l-md peer focus:outline-none focus:borer-rose-600"
                  placeholder="Enter Captcha"
                  onChange={(e) =>
                    setState({ ...state, valid: e.target.value })
                  }
                  style={{
                    padding: "10px",
                    paddingLeft: "20px !important",
                    fontSize: "15px",
                  }}
                  ref={captchaRef}
                />
              </div>
              <div className="w-1/3 mt-8">
                {" "}
                <ClientCaptcha
                  //fontFamily={"'Times New Roman', serif"}
                  captchaCode={setCode}
                  width={captchaWidth}
                  //   className="rounded-r-lg"
                />
              </div>
            </div>
          </div>
          <div className="relative mt-7">
            <div className="flex justify-between">
              <div className="mr-auto">
                <button
                  onClick={() => navigate("/login")}
                  className="block w-20 h-10 px-4 py-2 text-sm text-white bg-blue-500 rounded-md"
                >
                  Login
                </button>
              </div>
              <div className="ml-auto">
                <button
                  type="submit"
                  className="block w-24 h-10 px-2 py-2 text-sm text-white bg-purple-500 rounded-md"
                >
                  Sign Up
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignupCard;
