import React, { useEffect } from "react";
import EventDetail from "./EventDetail";
import axios from "axios";
const EventView = ({ currentRecord }) => {
  useEffect(() => {
    // console.log("event calender function called");
    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/events/view/${currentRecord?._id}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    axios(config)
      .then(function (response) {
        console.log("The view captured");
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  return (
    <div>
      <EventDetail eventData={currentRecord} />
      {/* {JSON.stringify(currentRecord)} */}
    </div>
  );
};

export default EventView;
