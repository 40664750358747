import React, { useEffect, useState, useRef } from "react";
import Tooltip from "@mui/material/Tooltip";
import { DataGrid } from "@material-ui/data-grid";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";

//Global state Access
import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

import Modal from "../../../components/Modal/Modal";
import UserView from "./UserView";
import UserAddChild from "../Create/UserAddChild";
import UserUpdateChild from "../Update/UserUpdateChild";

const UsersListChild = () => {
  let navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showUpdateUserModal, setShowUpdateUserModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});
  const [data, setData] = useState([]);
  const [unfilteredData, setUnfilteredData] = useState([]);
  const searchRef = useRef();
  const [searchTerm, setSearchTerm] = useState("");

  const [{ errorLogApi, placeHolderText, user }] = useAtom(gbState);

  const toggleModal = (rec) => {
    setShowModal(!showModal);
  };

  const viewCurrentRecord = (rec) => {
    setCurrentRecord(rec);
    toggleModal();
  };

  const updateCurrentRecord = (rec) => {
    setCurrentRecord(rec);
    setShowUpdateUserModal(!showUpdateUserModal);
  };

  const toggleAddUserModal = () => {
    setShowAddUserModal(!showAddUserModal);
  };

  const toggleUpdateUserModal = () => {
    setShowUpdateUserModal(!showUpdateUserModal);
  };

  const handleDelete = (row) => {
    confirmAlert({
      title: "",
      message: "Are you sure to delete this User ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteRecord(row),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  const deleteRecord = (row) => {
    let userToken = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
    };
    axios
      .delete(`${process.env.REACT_APP_API_URL}/user/delete/${row._id}`, config)
      .then((response) => {
        //alert(JSON.stringify(response));
        getDataFromServer();
      })
      .catch((err) => {
        errorLogApi(err, "UsersListChild", "/users");
      });
  };

  const getDataFromServer = () => {
    let userToken = localStorage.getItem("token");

    let urlLink = `${process.env.REACT_APP_API_URL}/user/`;

    const config = {
      method: "GET",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data.users));
        const oldData = response.data.users;
        const updatedData = oldData.map((data) => {
          return {
            ...data,
            id: data._id,
            name: `${data.first_name} ${data.last_name ? data.last_name : ""}`,
            email: data.email,
            role: data.role,
            status: data.user_type,
          };
        });

        setData(updatedData);
        setUnfilteredData(updatedData);
      })
      .catch(function (err) {
        console.log("error api call ", err.response.data.message);
      });
  };

  useEffect(() => {
    const getDataFromServer = () => {
      let userToken = localStorage.getItem("token");

      let urlLink = `${process.env.REACT_APP_API_URL}/user/`;

      const config = {
        method: "GET",
        url: urlLink,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };
      axios(config)
        .then(function (response) {
          //console.log(JSON.stringify(response.data.users));
          const oldData = response.data.users;
          const updatedData = oldData.map((data) => {
            return {
              ...data,
              id: data._id,
              name: `${data.first_name} ${
                data.last_name ? data.last_name : ""
              }`,
              email: data.email,
              role: data.role,
              status: data.user_type,
            };
          });

          setData(updatedData);
          setUnfilteredData(updatedData);
        })
        .catch(function (err) {
          console.log("error api call ", err.response.data.message);
        });
    };
    getDataFromServer();
  }, []);

  const columns = [
    {
      field: "Action",
      headerName: "Action",
      sortable: false,
      hideable: false,
      disableColumnMenu: true,
      width: 80,
      align: "center",
      renderCell: (params) => {
        return (
          <div className="flex flex-row">
            <Tooltip title="View  details">
              <div onClick={() => viewCurrentRecord(params.row)}>
                <i
                  className="cursor-pointer mdi mdi-eye-circle-outline"
                  style={{ fontSize: "15px", color: "black" }}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Edit">
              <div onClick={() => updateCurrentRecord(params.row)}>
                <i
                  className="mdi mdi-square-edit-outline"
                  style={{ fontSize: "15px", color: "grey", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Delete">
              <div
                onClick={() => {
                  handleDelete(params.row);
                }}
              >
                <i
                  className="mdi mdi-trash-can-outline"
                  style={{ fontSize: "15px", color: "red", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      width: 140,
    },
    {
      field: "email",
      headerName: "Email",
      width: 230,
    },
    {
      field: "user_type",
      headerName: "Role",
      width: 140,
    },
    {
      field: "mobile",
      headerName: "Mobile",
      width: 140,
    },
  ];

  const handleSearch = (e) => {
    console.log("Search Value...", e);
    setSearchTerm(e);
    if (e) {
      setData(
        unfilteredData?.filter((item) => {
          return (
            item?.name?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.email?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.user_type?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.mobile?.toLowerCase().includes(e?.toLowerCase())
          );
        })
      );
    } else {
      getDataFromServer();
      setData(unfilteredData);
    }
  };

  const clearSearch = () => {
    searchRef.current.value = null;
    setSearchTerm("");
    getDataFromServer();
  };

  return (
    <div className="w-full m-8">
      <h3 className="mb-2 text-lg font-bold">Users</h3>
      <div className="flex justify-end w-full">
        <button
          className="px-4 py-2 text-white bg-gray-600 rounded hover:bg-gray-900"
          onClick={toggleAddUserModal}
        >
          Add User
        </button>
      </div>
      <div className="patientListSearch"></div>
      {showModal && (
        <Modal onClose={toggleModal}>
          <h2 className="mb-4 text-xl font-bold">User Detail</h2>
          <UserView currentRecord={currentRecord} />
        </Modal>
      )}
      {showAddUserModal && (
        <Modal onClose={toggleAddUserModal}>
          <h2 className="mb-4 text-xl font-bold">Add User</h2>
          <UserAddChild
            getDataFromServer={getDataFromServer}
            setShowAddUserModal={setShowAddUserModal}
          />
        </Modal>
      )}
      {showUpdateUserModal && (
        <Modal onClose={toggleUpdateUserModal}>
          <h2 className="mb-4 text-xl font-bold">Update User</h2>
          <UserUpdateChild
            getDataFromServer={getDataFromServer}
            setShowUpdateUserModal={setShowUpdateUserModal}
            currentRecord={currentRecord}
          />
        </Modal>
      )}
      <div className="w-full mt-3">
        <div className="relative w-full mt-3">
          <input
            className="w-full p-3 pr-10 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
            name="search"
            type="text"
            ref={searchRef}
            placeholder="Enter search term"
            autoComplete="new-password"
            value={searchTerm}
            onChange={(e) => handleSearch(e.target.value)}
          />
          {searchTerm && (
            <button
              className="absolute transform -translate-y-1/2 top-1/2 right-3"
              onClick={clearSearch}
            >
              <svg
                className="w-4 h-4 text-gray-500"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>
          )}
        </div>
      </div>
      <div className="w-full mt-4">
        <DataGrid
          rows={data}
          disableSelectionOnClick
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          displayTitle="Users"
          checkboxSelection
          style={{ height: "40vh" }}
        />
      </div>
    </div>
  );
};

export default UsersListChild;
