import React from "react";

function Modal({ onClose, children }) {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-75 ">
      <div className="w-1/2 max-h-screen p-6 bg-white rounded-lg">
        <SVGCrossIcon onClose={onClose} />
        <p className="max-h-screen mb-4 overflow-y-auto break-words">
          {children}
        </p>
      </div>
    </div>
  );
}

const SVGCrossIcon = ({ onClose }) => {
  return (
    <div className="flex justify-end w-full">
      <svg
        viewBox="0 0 100 100"
        width="12"
        height="12"
        className="cursor-pointer z-51"
        onClick={onClose}
      >
        <line
          x1="10"
          y1="10"
          x2="90"
          y2="90"
          stroke="black"
          stroke-width="10"
        />
        <line
          x1="90"
          y1="10"
          x2="10"
          y2="90"
          stroke="black"
          stroke-width="10"
        />
      </svg>
    </div>
  );
};

export default Modal;
