import React from "react";
import AdminTemplate from "../../../components/AdminTemplate/AdminTemplate";
import CommunityListChild from "./CommunityListChild";

const CommunityList = () => {
  return (
    <AdminTemplate>
      <CommunityListChild />
    </AdminTemplate>
  );
};

export default CommunityList;
